import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function Metaverse() {
    return (
        <>
            <Helmet>
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                <title>The First-Ever Multichain Multiverse | Blockchain Land</title>
                <meta name="title" content="The First-Ever Multichain Multiverse | Blockchain Land" />
                <meta name="description" content="Blockchain Land is the first ever Multichain Metaverse Platform which allows businesses to grow not only globally and but also across blockchains. Be part of this Metaverse." />
                <meta name="keywords" content="Virtual experience, Virtual universe, Metaverse land buy, metaverse platforms" />
                <link rel="canonical" href="https://blockchain.land/metaverse" title="Metaverse" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blockchain.land/metaverse" />
                <meta property="og:title" content="The First-Ever Multichain Multiverse | Blockchain.land" />
                <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blockchain.land/metaverse" />
                <meta property="twitter:title" content="The First-Ever Multichain Multiverse | Blockchain.land" />
                <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>
            {/* header strat */}
            <Header />
            {/* header end */}

            {/* section metaverse start */}
            <section className="section-airdrop bg_metaverse mt--100 pt--400 airdrop-height newairdrop">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-9">
                            <div className="text-center text-sm-start">
                                <h1 className="font--70 font-200 white-text line--80 mb--20">
                                    The First-Ever
                                    <br className="d-lg-block d-none" />
                                    Multichain Multiverse!
                                </h1>
                                <p className="font-18 font-100 white-text line-25 mb--40">
                                    We are presenting the universe where users of all platforms enjoy a unified cross-chain experience with endless opportunities.</p>
                                {/* <a href="https://sweepwidget.com/view/57177-er5dnqtl" target="_blank" className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Join Airdrop</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section metaverse end */}

            {/* section Digital Global start */}
            <section className="section-global pb-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 order-lg-1 order-2">
                            <img className="w-100" src="assets/image/metaverse/parcel.png" alt="Blockchain Land Metaverse Parcel" />
                        </div>
                        <div className="col-lg-6 col-md-12 mb-lg-0 mb-5 order-lg-2 order-1">
                            <div className="global-right text-center text-lg-start">
                                <h2 className="font--70 font-200 white-text line--80 mb---20">
                                    Parcel
                                </h2>
                                <p className="font-18 font-100 white--light--80 mb---40 line-25 my-4">
                                    Parcel is the smallest piece of
                                    metaverse land. The size of 1 parcel is 1,88,976.40 pixels. Here, users
                                    can keep their digital assets and build their own place just by
                                    purchasing assets from the Blockchain Land Marketplace or importing
                                    their own assets. It's like owning a parallel world of your own.
                                </p>
                                <h3 className="font-40 font-200 white-text">1 Parcel = <span className="text-blue">188976.40 Pixel</span></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section Digital Global end */}

            {/* mint parcel start */}
            <section className="section-citizenship section-mintparcel">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-8 col-xl-6">
                            <div className="text-center text-lg-start">
                                <h2 className="font--70 font-200 white-text line--80 mb---20">
                                    Mint Your Land
                                </h2>
                                <p className="font-18 font-100 white--light--80 line-25">
                                    Users can buy a parcel if
                                    someone has already minted a Valley, Country or Planet. Users can
                                    choose their parcel from the available options in the Blockchain Land
                                    Marketplace. In the Blockchain Land metaverse, 1 valley = 100 parcel
                                    (10*10), 1 country = 625 parcel (25*25), and 1 planet = 10,00,000
                                    (1000*1000). There are no limits on purchasing and minting of valley
                                    and country.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--55 border--bottom pb---55 justify-content-center">
                        <div className="col-lg-4 col-md-6 mt-lg-0 mt-4">
                            <div className="pe-2">
                                <img className="w-100" src="/assets/image/mint-parcel/mint-parcel-01.png" alt="Meatverse Valley in Blockchain Land" />
                                <div className="d-flex justify-content-between align-items-center mt---40">
                                    <div>
                                        <h3 className="font-20 font-200 white-text mb---0 line--25">Valley</h3>
                                        <span className="font-18 font-200 white--light--80">Parcel : 10 X 10</span>
                                    </div>
                                    <a href="https://market.blockchain.land/mint" target="_blank" className="btn-mint-head btn-contain text-center text-custom-dark d-block d-sm-inline-block font-16 font-400" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Mint Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-lg-0 mt-4">
                            <div className="pe-2">
                                <img className="w-100" src="/assets/image/mint-parcel/mint-parcel-02.png" alt="Meatverse Country in Blockchain Land" />
                                <div className="d-flex justify-content-between align-items-center mt---40">
                                    <div>
                                        <h3 className="font-20 font-200 white-text mb---0 line--25">Country</h3>
                                        <span className="font-18 font-200 white--light--80">Parcel : 25 X 25</span>
                                    </div>
                                    <a href="https://market.blockchain.land/mint" target="_blank" className="btn-mint-head btn-contain text-center text-custom-dark d-block d-sm-inline-block font-16 font-400" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Mint Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-lg-0 mt-4">
                            <div className="pe-2">
                                <img className="w-100" src="/assets/image/mint-parcel/mint-parcel-03.png" alt="Meatverse Planet in Blockchain Land" />
                                <div className="d-flex justify-content-between align-items-center mt---40">
                                    <div>
                                        <h3 className="font-20 font-200 white-text mb---0 line--25">Planet</h3>
                                        <span className="font-18 font-200 white--light--80">Parcel : 1000 X 1000</span>
                                    </div>
                                    <a href="https://market.blockchain.land/planets" target="_blank" className="btn-mint-head btn-contain text-center text-custom-dark d-block d-sm-inline-block font-16 font-400" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <p className="text-blue font--18 font-100 mt---55">Note : <span className="white-text">But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.</span></p> */}
                </div>
            </section>
            {/* mint parcel end */}

            {/* assert metaplace strat */}
            <section className="margintop_180">
                <div className="container-fluid px-0">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="pl-243 pr-20 mb-5 text-center text-lg-start">
                                <h2 className="font--70 font-200 white-text line--80 mb---20">Assets <br className="d-lg-block d-none" />
                                    Marketplace</h2>
                                <p className="font-18 font-100 white--light--80 mb-35 line-25">
                                    Metaverse land provides a complete framework for their users. They can Buy, Sell, or mint their established assets without any intermediation as it is a trusted bridge between issuers and investors.
                                </p>
                                <div className='d-flex btn_height'>
                                    <a href='https://market.blockchain.land/citizenship' target="_blank" className="btn-head btn-contain text-center text-white font-16 d-block font-200 mx-auto mx-lg-0" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Get Citizenship</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps_100">
                            <video className="video_metaverse" src="assets/video/metaverse.mp4" type="video/ogg" autoPlay loop muted />
                        </div>
                    </div>
                </div>
            </section>
            {/* assert metaplace end */}

            {/* vr metaverse start */}
            <section className="section-lose meta_vr">
                <div className="container-fluid px-0">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-1 order-2">
                            <img src="assets/image/metaverse/meta_vr_mobile.png" alt="lose" className="lose-image d-xl-none d-block" />
                        </div>
                        <div className="col-lg-6 order-lg-2 order-1">
                            <div className="pr-243 mb-5 text-center text-lg-start">
                                <h2 className="font--70 font-200 white-text line--80 mb---20">VR
                                    <br className="d-lg-block d-none" />
                                    Metaverse
                                </h2>
                                <p className="font-18 font-100 white-text mb-45 line-25">
                                    Blockchain Land is presenting a very unique concept of virtual reality and augmented reality in the form of VR metaverse. Where without any physical presence, users can enjoy every place worldwide. This distinct concept is organized to bring them together all in one immersive and engaging environment without any intermediation.
                                </p>
                                {/* <div className='d-flex btn_height'>
                                <Link to={"/mint"} className="btn-head btn-contain text-center text-white font-16 d-block font-200 mx-auto mx-lg-0" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Get Citizenship</Link>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb_150 meta_cont">
                <div className="container-fluid">
                    <div className="row align-items-end">
                        <div className="col-md-6">
                            <div className="pl-243 pr-20 mb-2">
                                <span className="round_img"></span>
                                <p className="font-20 font-300 white-text mb-0">Supported with
                                </p>
                                <h3 className="font-40 font-300 white-text">oculus quest 2</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-center text-lg-start">
                                <div className='d-flex btn_height'>
                                    <a href="https://market.blockchain.land/" target="_blank" className="btn-head btn-contain text-center text-white d-inline-block font-16 font-200 mr-md-20 me-2" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>See Demo</a>
                                    <a href="javascript:void(0);" className="btn-head btn-contain text-center text-white font-16 d-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Install In Oculus</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* vr metaverse end */}

            {/*Side by Side Start*/}
            <section className="section-lose">
                <div className="container-fluid p-0">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="pl-243 pr-20 mb--30 text-center text-lg-start">
                                <p className="btn-point btn-blue text-center d-inline-block font-22 font-200 white-text mb-30" style={{ backgroundImage: 'url("assets/image/small-elements/btn-blue-point.svg")' }}>Explore</p>
                                <h2 className="font--70 font-400 white-text line--80 mb-35">
                                    Lose yourself in an amazing, evolving world
                                </h2>
                                <p className="font-18 font-100 white--light--80 mb-35 line-25">
                                    Explore lands owned by users to experience incredible scenes and structures. From a space adventure to a medieval dungeon maze to entire villages crafted from the minds of community members.
                                </p>
                                <div className='d-flex btn_height'>
                                    <a href="https://meta.blockchain.land/" target="_blank" className="btn-head btn-contain text-center text-white font-16 d-block font-200 mx-auto mx-lg-0" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>Start Exploring</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src="/assets/image/metaverse/metaplace.png" alt="Ticket to the Blockchain Land" className="lose-image" type="img/webp" />
                        </div>
                    </div>

                    <div className="row align-items-center mt--180">
                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <img src="assets/image/side-img/side-by-side/side-by-02.webp" alt="Low Poly Castle in Blockchain Land" className="tabsaidsapce test-image d-lg-block d-none ps-0" type="image/webp" />
                            <img src="assets/image/side-img/side-by-side/side-by-02-tablet.webp" alt="lose" className="tabsaidsapce test-image d-block d-lg-none px-0" type="image/webp" />
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 text-center text-lg-start">
                            <div className="pr-243">
                                <p className="btn-point btn-blue text-center d-inline-block font-22 font-200 white-text mb-30" style={{ backgroundImage: 'url("assets/image/small-elements/btn-blue-point.svg")' }}>Create</p>
                                <h2 className="mb-35 font--70 font-400 white-text line--80">
                                    Test the limits of your imagination
                                </h2>
                                <p className="font-18 font-100 white--light--80 mb-35 line-25">
                                    Create scenes, artworks, challenges and more, using the simple Builder tool, then take part in events to win prizes. For more experienced creators, the SDK provides the tools to fill the world with social games and applications.
                                </p>
                                <div>
                                    {/* <div className='d-flex'>
                                        <a href="/citizenship" className="btn-head btn-contain text-center text-white d-inline-block font-16 font-200 mr-md-20 me-2 mb-2 mb-lg-0" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>Start Building</a>
                                    </div> */}
                                    <div className='d-flex justify-content-center justify-content-lg-start'>
                                        <a href="/typesofnft" className="btn-head btn-contain text-center text-white d-inline-block font-16 font-200 mb-2 mb-lg-0" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Start Developing</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Side by Side End*/}

            {/*Virtual Space Start*/}
            <section className="section-trade mb_150">
                <div className="container">
                    <div className="position-relative">
                        <div className="main-trade position-absolute text-center px-3">
                            <p className="btn-point btn-blue text-center d-inline-block font-22 font-200 white-text mb-13" style={{ backgroundImage: 'url("assets/image/small-elements/btn-blue-point.svg")' }}>Trade</p>
                            <h2 className="font--70 font-400 white-text line--80 mb-23">
                                Exploring new horizons in the virtual space
                            </h2>
                            <p className="font-18 font-100 white--light--80 line-25 mb-23 w-70 mx-auto">
                                Any user can create their own virtual shop where users can buy and sell land, estates, avatar wearables and names in their own marketplace or reach out to us to create a blockchain-based application to scale up your existing business in the virtual space.
                            </p>
                            <a href="https://market.blockchain.land/" target="_blank" className="btn-head btn-contain text-center text-white d-inline-block font-16 font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>Start Browsing</a>
                        </div>
                        <img src="assets/image/section-bg/virtual-space-bg.webp" alt="Blockchain Land Meatverse NFT Marketplace" className="w-100" type="image/webp" />
                    </div>
                </div>
            </section>
            {/*Virtual Space End*/}

            <Community />
            <Footer />
        </>
    );
}