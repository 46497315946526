import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function MetaLand() {
    return (
        <>
            <Helmet>
                <title>Meta Land In Metaverse | Blockchain Land</title>
                <meta name="title" content="Meta Land In Metaverse | Blockchain Land" />
                <meta name="description"
                    content="Blockchain Land marketplace offers exclusive variety to the users to buy and sell land, estate, avatar wearables, NFTs and names too In the Metaverse." />
                <meta name="keywords" content="Meta Land In Metaverse, Real estate in metaverse, NFTs, Sell NFT, Digital asset, Sell NFT art" />
                <link rel="canonical" href="https://blockchain.land/metaland" title="metaland" />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>

            <Header />

            {/* Meta Land Start */}
            <section className="meta_land shadow-blue">
                <div className="row align-content-between justify-content-center h-100">
                    <div className="col-xl-12 meta_land_padd">
                        <div className="container">
                            <div className="text-center">
                                <div className="meta_land_img">
                                    <h1 className="meta_title_main metaland_gradiant">METALAND</h1>
                                    {/* <img src="assets/image/meta-land/meta-land-text.png" /> */}
                                </div>
                                <p className="font-18 font-100 meta_land_p">Explore the Metaverse Planet now by discovering the main map.</p>
                                <a href="https://market.blockchain.land/map" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta-land/meta-user.png")' }}>Explore Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="meta_land_parcel">
                            {/* <img src="assets/image/meta-land/meta-land-parcel.png"></img> */}
                        </div>
                    </div>
                </div>
            </section>
            {  /* Meta Land end */}

            <div className="main__bg">
                {/* Tha palnet start */}
                <section className="meta_planet meta-planet-shadow">
                    <div className="container">
                        <div className="row align-items-xl-center justify-content-center">
                            <div className="col-xl-6 col-md-8 order-xl-1 order-2">
                                <div className="meta_land_planet">
                                    <h2 className="meta_planet_h2 bg--contain" style={{ backgroundImage: 'url("assets/image/meta-land/the-planet.png")' }}>THE PLANET</h2>
                                    <p className="font-100">There are six planets in total. Planet has a gigantic untracked Meta space with immense possibilities. It has its own peculiarity as a planet that contains a total of 1 million parcels where 8,67,625 parcels are useable parcels.</p>
                                    <a href="https://market.blockchain.land/planets" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta-land/meta-user.png")' }}>View all planets</a>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6 order-xl-2 order-1">
                                <div className="planet_img">
                                    <img src="assets/image/meta-land/meta-planet.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Tha palnet End */}

                {/* country vally parcel section start */}
                <section className="parcel-card">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="parcel_card">
                                    <div className="card__header">
                                        <p>No. of country</p>
                                    </div>
                                    <div className="card_body">
                                        <p>713</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="parcel_card">
                                    <div className="card__header">
                                        <p>No. of Valley</p>
                                    </div>
                                    <div className="card_body">
                                        <p>4220</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="parcel_card">
                                    <div className="card__header bg-box-3">
                                        <p className="text-dark">No. of PARCELS</p>
                                    </div>
                                    <div className="card_body">
                                        <p className="h_gradient">1,000,000</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* country vally parcel section End*/}

                {/* forms of meta land section start */}
                <section className="meta_land_exist meta_land_exist_shadow">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="meta_exist_heading exist_heading_padd">
                                    <h2>2 forms of Meta land
                                        <br />
                                        exist here.
                                    </h2>
                                    <p className="font-100">It is possible to acquire Meta land in two forms: As a country or as a valley.</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12">
                                <div className="meta-exist-card h-100">
                                    <div className="d-flex flex-md-row flex-column justify-content-md-between">
                                        <div className="meta-exist-card-img order-md-1 order-2">
                                            <img src="assets/image/meta-land/meta-country.png" />
                                        </div>
                                        <div className="parcel_coordinate order-md-2 order-1">
                                            <p>25 * 25</p>
                                        </div>
                                    </div>
                                    <div className="meta_exist_heading heading_padd">
                                        <h2>COUNTRY</h2>
                                        <p>Get started in the blockchain land metaverse by minting your own Country. The 625 Parcels will be owned by the person who mints a Country.</p>
                                        <a href="https://market.blockchain.land/mint" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta-land/meta-user.png")' }}>Mint Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 mt-xl-0 mt-5">
                                <div className="meta-exist-card ms-auto mb-0 h-100">
                                    <div className="d-flex flex-md-row flex-column justify-content-md-between">
                                        <div className="meta-exist-card-img order-md-1 order-2">
                                            <img src="assets/image/meta-land/meta-valley.png" />
                                        </div>
                                        <div className="parcel_coordinate order-md-2 order-1">
                                            <p>10 * 10</p>
                                        </div>
                                    </div>
                                    <div className="meta_exist_heading heading_padd">
                                        <h2>VALLEY</h2>
                                        <p>Mint your valley in the metaverse of the Blockchain Land. 100 Parcels will be owned by the person who mints the Valley.</p>
                                        <a href="https://market.blockchain.land/mint" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta-land/meta-user.png")' }}>Mint Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* forms of meta land section end */}

                {/* on parcel section start */}
                <section className="on_parcel on_parcel_shadow">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-md-12 order-xl-1 order-2">
                                <div className="single_parcel">
                                    <img src="assets/image/meta-land/single_parcel.png" className="d-md-block d-none" />
                                    <img src="assets/image/meta-land/single_parcel_mobile.png" className="d-md-none d-block" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-12 order-xl-2 order-1">
                                <div className="meta_exist_heading">
                                    <h2>ONE PARCEL</h2>
                                    <p>Parcel is the smallest piece of metaverse land. The size of 1 parcel is 1,88,976.40 pixels. Here, users can keep their digital assets and build their own place just by purchasing assets from the Blockchain Land Marketplace or importing their own assets. It's like owning a parallel world of your own.</p>
                                    <p className="one_parcel_meters"><span>1 PARCEL =</span> 50 METERS * 50 METERS</p>
                                    <a href="https://market.blockchain.land/map" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta-land/meta-user.png")' }}>Buy Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* on parcel section end */}

                {/* marquee section start */}
                <section className="section_slide">
                    <div className="section_slide_content">
                        <marquee width="100%" direction="left">
                            {/* <p>praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth</p> */}
                        </marquee>
                    </div>
                </section>
                {/* marquee section end */}

                {/* citizenship section start */}
                <section className="citizenship_card citizenship_card_shadow">
                    <div className="container">
                        <div className="row align-items-xl-center">
                            <div className="col-xl-7 col-12 order-xl-1 order-2">
                                <div className="meta_exist_heading">
                                    <div className="user_icon">
                                        <img src="assets/image/meta-land/user-icon.svg" />
                                    </div>
                                    <h2>Citizenship Card
                                        <br />
                                        for meteverse entry
                                    </h2>
                                    <p className="citizenship_content_padd">The most important factor to gain entry into the Blockchain Land Metaverse is citizenship. Users can experience the Innovative and exciting virtual world with Virtual and Augmented Reality.</p>
                                    <a href="https://market.blockchain.land/citizenship" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta-land/meta-user.png")' }}>Apply now</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-12 offset-xl-1 order-xl-2 order-1">
                                <div className="meta_land_user">
                                    {/* <img src="assets/image/meta-land/metalend-usertwo.png" className="ml_one" /> */}
                                    <img src="assets/image/meta-land/metalend-user.png" className="ml_two" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* citizenship section end */}

                {/* Parcel view section start */}
                <section className="parcel_view">
                    <div className="container">
                        <div className="border_gred">
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <div className="position-relative">
                                        <div className="parcel_view_img">
                                            <img src="assets/image/meta-land/parcel_view.png" className="parcele-img" />
                                            <img src="assets/image/meta-land/parcel_view_md.png" className="parcele-md-img" />
                                        </div>
                                        <div className="parcel_view_content">
                                            <img src="assets/image/meta-land/parcel-view-text.png" />
                                            <p>Users can take a look at the actual view of the unbuilt Meta-Land parcel, which is currently undeveloped.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                            </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* Parcel view section end */}

                {/* Metaverse Map section start */}
                <section className="metaverse-map">
                    <div className="container">
                        <div className="row align-items-xl-center justify-content-center">
                            <div className="col-xl-7 md-12 order-xl-1 order-2">
                                <div className="metaverse_content">
                                    <h2>Metaverse Map</h2>
                                    <p>In the Blockchain Land Metaverse, users own LANDS, which are bits of the world, which is used to create and monetize experiences.</p>
                                    <a href="https://market.blockchain.land/map" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta-land/meta-user.png")' }}>Apply now</a>
                                </div>
                                <div className="map__paecel d-md-flex justify-content-md-between">
                                    <div>
                                        <p >No. of country</p>
                                        <p className="meta_count">713</p>
                                    </div>
                                    <div>
                                        <p>No. of valley</p>
                                        <p className="meta_count">4220</p>
                                    </div>
                                    <div>
                                        <p>No. of parcels</p>
                                        <p className="meta_count">1,000,000</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-8 offset-xl-1 order-xl-2 order-1">
                                <div className="metaverse-map-img">
                                    <img src="assets/image/meta-land/metaverse-map.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Metaverse Map section end */}
            </div>

            {/* User cases section start */}
            <section className="user_cases">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-12">
                            <div className="user_cases_content">
                                <h3>Use Cases of
                                    <br />
                                    metaverse country & valley
                                </h3>
                            </div>
                            <div className="metaverse_user_cases">
                                <ol className="mb-0">
                                    <li>Users can name their country according to their choice. </li>
                                    <li>The name of the Parcel will be depended on the Country name which will contain each coordinate address like -65, -75.</li>
                                    <li>Whoever wants to buy a single parcel, can but the buyer will precisely require citizenship.</li>
                                    <li> The owner can sell these parcels at any price and currency. </li>
                                    <li>Whoever will mint a Country, will be the owner of the 625 Parcels. </li>
                                    <li>There are some ecosystem regulations. According to it, the owner can set 70% of 625 parcels as saleable Parcels and 30% of parcels can be designed with such elements as road, snow, forest, and desert. </li>
                                    <li>Whatever base cost will be needed to have 625 parcels, will be displayed on the Meta Land Portal. </li>
                                </ol>
                            </div>
                        </div>
                        <div className="col-xl-6 col-12">
                            <div className="user_cases_content">
                                <h3>How to apply for
                                    <br />
                                    metaverse
                                </h3>
                            </div>
                            <div className="metaverse_user_cases">
                                <ol className="mb-0">
                                    <li>There are some ecosystem regulations. According to it, the owner can set 70% of 625 parcels as saleable Parcels and 30% of parcels can be designed with such elements as road, snow, forest, and desert.</li>
                                    <li>The name of the Parcel will be depended on the Country name which will contain each coordinate address like -65, -75.</li>
                                    <li>Users can name their country according to their choice.</li>
                                    <li> Whatever base cost will be needed to have 625 parcels, will be displayed on the Meta Land Portal.</li>
                                    <li> The owner can sell these parcels at any price and currency.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* User cases section end */}

            <Community />

            <Footer />
        </>
    );
}