import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

function IMO() {

    return (
        <>
            <Helmet>
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                <title>Initial Metaverse Offerings (IMO)</title>
                <meta name="title" content=" Initial Metaverse Offerings (IMO) | Blockchain Land" />
                <meta name="description"
                    content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta name="keywords" content="NFT, Virtual reality, financial assets, Digital Citizenship, Multichain Metaverse, Mixed Reality, Virtual Land, Metaverse Land, Decentralized Land, digital assets" />
                <link rel="canonical" href="https://blockchain.land/initialmetaverseofferings" title="initialmetaverseofferings" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blockchain.land" />
                <meta property="og:title" content="MultiChain Metaverse | Blockchain.land" />
                <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/imo_meta.png" />
                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blockchain.land" />
                <meta property="twitter:title" content="MultiChain Metaverse | Blockchain.land" />
                <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/imo_meta.png" />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>

            <Header />

            <div className="imo_program">
                <div className="container">
                    <div className="imo_tital">
                        <img src="/assets/image/imo/bcl.svg" alt="BCL" className="mb-3" />
                        <h1 className="ambassador_h1 text-white font-200">INITIAL METAVERSE OFFERING (IMO)</h1>
                        <p className="font-18 font-100 color_coco line_height mt-4 mb-40">Blockchain Land is the first Initial Metaverse Offering (IMO) Platform. It allows participants to create their own Metaverse.</p>
                        <div className="d-flex align-items-center justify-content-md-center justify-content-xl-start justify-content-start">
                            <a href="https://market.blockchain.land/metaverseoffering" target={"_blank"} className="btn-head btn-contain font-16 text-center text-white d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }}>Apply Now</a>
                            <a href="https://market.blockchain.land/tutorials" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200 ms-3" style={{ backgroundImage: 'url("assets/image/ambassadorprogram/ambassador_btn.png")' }}>Tutorial</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="img_map">
                <div className="container">
                    <div className="imo_list">
                        <img src="/assets/image/imo/imo_map.svg" className="w-100 hide_show" alt="Blockchain Land Initial Metaverse Offering" />
                        <img src="/assets/image/imo/map_tab.svg" className="w-100 show_hide" alt="imo map" />
                        <img src="/assets/image/imo/map_m.svg" className="w-100 d-block d-md-none" alt="imo map" />

                        <div className="imo_contain">
                            <h2 className="ambassador_h3 color_weight mb-3 font-100 text-uppercase">What is IMO?</h2>
                            <p className="imo18 ambassador_lineheight color_weight mb-3 font-100">The Initial Metaverse Offering creates a launchpad for raising funds for metaverse projects in the future. This new fundraising model aims to raise funds for idle metaverse projects that can create significant opportunities for their owners in the future. Not only Blockchain Land (BCL) tokens, but any other cryptocurrency can be used here.</p>
                            <a href="https://market.blockchain.land/tutorials" target={"_blank"} className="btn-head btn-contain font-16 text-center text-custom-dark d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/ambassadorprogram/ambassador_btn.png")' }}>How it works?</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="imo_service">
                <div className="container">
                    <div className="imo_service_heading">
                        <h2 className="airdrop_h2 giveaway_h2 mb--40 font-100 text-uppercase">Features Of <br className="d-none d-md-block" />
                            Initial Metaverse<br className="d-none d-md-block" /> Offering</h2>
                    </div>
                    <div className="row gx-5">
                        <div className="col-lg-6">
                            <img src="/assets/image/imo/rocket.svg" className="mb-2" alt="Launchpad" />
                            <h3 className="font-18 font-300 text-white mt-3">LAUNCHPAD</h3>
                            <p className="font-18 font-100 color_body_text mt-3 mb-5 mb-md-0 line-25">Initial Metaverse Offering, a method for users with some part of the Meta Land to raise the ecosystem of their project by targeting a community where they can launch their own IMO or create Metaverse, Projects.
                            </p>
                        </div>
                        <div className="col-lg-6 mt-5 mt-lg-0">
                            <img src="/assets/image/imo/transparent.svg" className="mb-2" alt="Transparency" />
                            <h3 className="font-18 font-300 text-white mt-3">TRANSPARENCY</h3>
                            <p className="font-18 font-100 color_body_text mt-3 mb-5 mb-md-0 line-25">All parcels purchased in the IMO launching are blockchain-based.  All records of users’ transactions are stored securely, and they are entirely traceable and easy to manage.</p>
                        </div>
                        <div className="col-lg-6 mt-5">
                            <img src="/assets/image/imo/decentrlize.svg" className="mb-2" alt="Decentralized" />
                            <h3 className="font-18 font-300 text-white mt-3">DECENTRALIZED</h3>
                            <p className="font-18 font-100 color_body_text mt-3 mb-5 mb-md-0 line-25">Blockchain Land IMO Smart contract is firmly decentralized. In such a case, even if the IMO is not successful, the user can claim a refund through the smart contract. The entire IMO fund will be securely stored in a Smart contract.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="imo_metavers">
                <div className="container">
                    <div className="text-center">
                        <p className="letterspace8 d-flex font-22 font-200 white-text justify-content-center mb-30">
                            <img src="/assets/image/imo/left.svg" className="me-3" alt="icon" />
                            THE AGE OF
                            <img src="/assets/image/imo/right.svg" className="ms-3" alt="icon" />
                        </p>
                        <img src="/assets/image/imo/tital.webp" className="me-3" type="image/webp" alt="icon" />
                        <p className="d-flex letterspace8 font-22 font-200 white-text justify-content-center mb-30">
                            <img src="/assets/image/imo/b_left.svg" className="me-3" alt="icon" />
                            <span className="mt34">IS HERE!</span>
                            <img src="/assets/image/imo/b_right.svg" className="ms-3" alt="icon" />
                        </p>
                    </div>
                </div>
            </div>

            <div className="imo_participate">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-6">
                            <div className="imo_service_heading">
                                <h2 className="airdrop_h2 giveaway_h2 mb--40 font-100 text-uppercase">What is the Process <br className="d-none d-md-block" />
                                    of Launching an <br className="d-none d-md-block" /> IMO?</h2>
                                <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-3 line-25">Follow the steps to give life to your dream project and gain <br className="d-none d-md-block" />your ecosystem easily.</p>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-5">
                            <div className="d-flex">
                                <div className="imostep me-3">
                                    <img src="/assets/image/imo/get_citizenship.svg" alt="box" />
                                </div>
                                <div className="imo_detail">
                                    <h3 className="font-18 font-300 white-text mt-2">Get Citizenship</h3>
                                    <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-3 line-25">Whoever wants to launch IMO, must have Blockchain Land citizenship.</p>
                                    <a href="https://market.blockchain.land/citizenship" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }}>Get Citizenship</a>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="d-flex">
                                <div className="imostep me-3">
                                    <img src="/assets/image/imo/fill_out_the_form.svg" alt="box" />
                                </div>
                                <div className="imo_detail">
                                    <h3 className="font-18 font-300 white-text mt-2">Fill Out The Form</h3>
                                    <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-3 line-25">Once the participant has completed the IMO form, they will need to wait for approval. </p>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="d-flex">
                                <div className="imostep me-3">
                                    <img src="/assets/image/imo/select_the_parcel.svg" alt="box" />
                                </div>
                                <div className="imo_detail">
                                    <h3 className="font-18 font-300 white-text mt-2">Select The Parcel</h3>
                                    <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-3 line-25">After getting approval, the user can select the metaverse parcel and draw a map of it. At the same time, the user will need to mention the saleable price of every parcel.</p>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="d-flex">
                                <div className="imostep me-3">
                                    <img src="/assets/image/imo/set_the_date.svg" alt="box" />
                                </div>
                                <div className="imo_detail">
                                    <h3 className="font-18 font-300 white-text mt-2">Set The Date</h3>
                                    <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-3 line-25">The participant will have to set the start and end date of the IMO.</p>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="d-flex">
                                <div className="imostep me-3">
                                    <img src="/assets/image/imo/imo_release.svg" alt="box" />
                                </div>
                                <div className="imo_detail">
                                    <h3 className="font-18 font-300 merchandize_color mt-2">IMO Release</h3>
                                    <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-3 line-25">The IMO will be released after that, and other users will be able to participate in it.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="imo_participate">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-5">
                            <div className="imo_service_heading">
                                <h2 className="airdrop_h2 giveaway_h2 mb--40 font-100 text-uppercase">How Can You <br className="d-none d-md-block" />
                                    Participate in an <br className="d-none d-md-block" /> IMO?</h2>
                                <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-5 mb-md-0 line-25">The initial Metaverse offering is a method catered toward Metaverse projects. <br className="d-none d-md-block" />Let's take a look at the process of joining the IMO.</p>
                            </div>
                        </div>
                        <div className="col-lg-7 mt-5 mt-lg-0">
                            <div className="d-flex">
                                <div className="imostep active me-3">
                                    <img src="/assets/image/imo/step_1.svg" alt="box" />
                                </div>
                                <div className="imo_detail">
                                    <h3 className="font-18 font-300 white-text mt-2">Step 1</h3>
                                    <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-3 line-25">Anyone who wants to participate in IMO must have to obtain citizenship of the Blockchain Land.</p>
                                </div>
                            </div>
                            <div className="d-flex mt80">
                                <div className="imostep active me-3">
                                    <img src="/assets/image/imo/step_2.svg" alt="box" />
                                </div>
                                <div className="imo_detail">
                                    <h3 className="font-18 font-300 white-text mt-2">Step 2</h3>
                                    <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-3 line-25">Visit the IMO listing page to gain more knowledge about the parcel, map, and IMO. </p>
                                </div>
                            </div>
                            <div className="d-flex mt80">
                                <div className="imostep active me-3">
                                    <img src="/assets/image/imo/step_3.svg" alt="box" />
                                </div>
                                <div className="imo_detail">
                                    <h3 className="font-18 font-300 white-text mt-2">Step 3</h3>
                                    <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-3 line-25">After selecting the IMO, The participant will be able to see a metaverse map and parcel details. Here, they can book the IMO after selecting a parcel where the payment gateway will be mentioned to complete the booking process.</p>
                                </div>
                            </div>
                            <div className="d-flex mt80">
                                <div className="imostep me-3">
                                    <img src="/assets/image/imo/step_4.svg" alt="box" />
                                </div>
                                <div className="imo_detail">
                                    <h3 className="font-18 font-300 merchandize_color mt-2">Step 4</h3>
                                    <p className="font-18 font-100 color_body_text mt-lg-3 mt-0 mb-3 line-25">After IMO has been completed, the selected parcel will be added to the metaverse. The parcel can then be bought, sold, or auctioned by the participant.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="join_bcl">
                <div className="container">
                    <div className="position-relative">
                        <img src="/assets/image/imo/join.svg" className="w-100 hide_show" alt="imo map" />
                        <img src="/assets/image/imo/join_tab.svg" className="w-100 show_hide" alt="imo map" />
                        <img src="/assets/image/imo/join_m.svg" className="w-100 d-block d-md-none" alt="imo map" />

                        <div className="imo_contain">
                            <h2 className="ambassador_h3 color_weight mb-3 font-100 text-uppercase">Join Blockchain land <br className="d-none d-md-block" />
                                and Content creator network</h2>
                            <p className="imo18 ambassador_lineheight mb-3 color_weight font-100">Are you a crypto Influencer or IMO? We would love to<br className="d-none d-md-block" /> meet and work with you.</p>
                            <a href="https://market.blockchain.land/metaverseoffering" target={"_blank"} className="btn-head btn-contain font-16 text-center text-custom-dark d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/ambassadorprogram/ambassador_btn.png")' }}>Join Now</a>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section-listed pt-0">
                <div className="container">
                    <div className="accordion" id="accordionExample">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="imo_service_heading">
                                    <h2 className="airdrop_h2 giveaway_h2 mb--40 weight200">FAQ</h2>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="accordion-item m-0">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button faqaccbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Is blockchain Land citizenship mandatory to participate in IMO?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body faqbody">
                                            <p>The citizenship of Blockchain Land is undoubtedly the entrance to the Initial Metaverse Offering.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item m-0">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button faqaccbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            What if my IMO will not successful?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body faqbody">
                                            <p>
                                                If your IMO will not be successful then you can claim a refund through the smart contract where all the IMO fund is stored securely.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item m-0">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button faqaccbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Which Payment mode is suitable for my IMO?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body faqbody">
                                            <p>
                                                Every IMO has a different payment gateway. Whichever IMO you are selecting contains the information of mode of payment.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Community />
            <Footer />

        </>
    );
}

export default IMO;