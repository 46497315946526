import React from "react";
import { Helmet } from "react-helmet";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function ContentPolicy() {
  return (
    <>
      <Helmet>
        <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

        <title>Content Policy | Blockchain Land</title>
        <meta name="title" content="Content Policy | Blockchain Land" />
        <meta name="description" content="‘Content’ simply implies any ideas, piece of art, graphics, written words, creative designs, images, textures, photographs, video, audio, music, scriptures, NFTs, etc which are submitted by the users of Blockchain Land or are under the control of Blockchain Land." />
        <meta name="keywords" content="Blockchain land content policy" />
        <link rel="canonical" href="https://blockchain.land/content_policy" title="content policy" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blockchain.land/content_policy" />
        <meta property="og:title" content="Content Policy | Blockchain.land" />
        <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
        <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://blockchain.land/content_policy" />
        <meta property="twitter:title" content="Content Policy | Blockchain.land" />
        <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

        <meta name="reply-to" content="blockchain.land" />
        <meta name="robots" content="index, Follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="revisit-after" content="7 day" />
        <meta name="rating" content="GENERAL" />
        <meta name="language" content="English" />
        <meta name="distribution" content="Global" />
        <meta name="copyright" content="blockchain.land" />
        <link rel="publisher" href="https://blockchain.land/" />
        <link rel="author" href="https://blockchain.land/" />
      </Helmet>

      {/* Header Start */}
      <Header />
      {/* Header End */}
      {/* section privacy policy start */}
      <section>
        <div className="container">
          <div className="policy_head">
            <h1 className="text-center mt--200 text-white">
              Content Policy
            </h1>
          </div>
          <div className="policy_content">
            <p>‘Content’ simply implies any ideas, piece of art, graphics, written words, creative designs, images, textures, photographs, video, audio, music, scriptures, NFTs, etc which are submitted by the users of Blockchain Land or are under the control of Blockchain Land.
            </p>
            <ul className="newli">
              <li><h2>NFT</h2>
                <ul>
                  <li>
                    <p>NFT stands for Non-Fungible Tokens including land, wearables, or any other type of NFT under the display or available on the platform of Blockchain Land. All NFTs must also comply with this Content Policy.
                    </p>
                  </li>
                </ul>
              </li>
              <li><h2>Intellectual Property Rights</h2>
                <ul>
                  <li><p>
                    All the content would be protected by intellectual property rights. All content of the site and tools and all high-end information with Blockchain Land and will be associated with copyright, mask work rights in published and unpublished work of authorship, including without limitation 1- programs, graphics, user interfaces, and similar works. Patent rights, design rights, rights of discovery and invention. 2- Machine methods, processes, and other terms of the matter of improvement. 3- Trademarks, service marks, logos, ideas, value, tagline, or anything similar in association with a good, service, business, or product. 4- Tangible or intangible information or trade secrets that are supposed to be hidden from any third party through proper means including customer lists, designs, formulas, ideas, source code, methods, practices, processes, prototypes, and techniques.5- Internet domain names. 6- data and databases, information, and access. 7- Similar Proprietary rights arising under the laws of any jurisdiction. 8- A Person’s personal information like name, voice, signature, photograph, contact, etc. 9- Moral rights of an author.
                  </p></li>
                </ul>
              </li>
              <li><h2>Warranties and User Representation</h2>
                <ul><li><p>
                  Every time you add a piece of information you warrant and represent that you are at least the age of majority in the jurisdiction or are the parent or legal guardian if any minor is involved. There needs to be consent that content provided by you is a) accurate b) does not infringe any intellectual property right of any third party. c) You are the sole author and owner of the content and have the lawful right to submit or process the content. d) The user content will not be violating the terms of this content policy and will not cause any personal or professional damage to any person.
                </p></li></ul>
              </li>
              <li><h2>Prohibited Content</h2>
                <p>
                  All content updated, posted, created, displayed or transmitted, or made available through the site or toll or by the user must strictly exclude-
                </p>
                <ul>
                  <li>
                    <p>Content that is illegal, pirated, involves criminal activity, terrorism, pornography of any kind, obscenity of any type, and must not promote drug use or gambling of any type.
                    </p>
                  </li>
                  <li>
                    <p>Any content violating the intellectual property right of any third party.
                    </p>
                  </li>
                  <li>
                    <p>Any content spreading hate or wrong information opposed to any creed, race, religious community, or any community at large.
                    </p>
                  </li>
                  <li>
                    <p>Content that may harm, harass or promote violence against any individual or group based on a specific ethnic origin, religion, nationality, disability, gender., age, veteran status, sexual orientation, or gender identity.
                    </p>
                  </li>
                  <li>
                    <p>Content that may be inaccurate, false, misleading, or can harm an individual's personal or professional privacy.
                    </p>
                  </li>
                  <li>
                    <p>Content that breaches the privacy laws, content policy, or data laws.
                    </p>
                  </li>
                  <li>
                    <p>Any content that can evade the above-mentioned limitations.
                    </p>
                  </li>
                </ul>
              </li>
              <li> <h2>Age-related Limitations</h2>
                <ul>
                  <li><p>Any content that portrays violence, entices hate promotes gambling, or showcases sexually explicit content should be only accessible to people with age 18 or above. If there is any upload, promotion, creation, or distribution of this content happening, there should be an age-restricted warning before the content is displayed. Failure to do so will result in termination of your account under section 15 of the terms of use.
                  </p>
                  </li>
                </ul>
              </li>
              <li><h2>Storage of Content</h2>
                <ul>
                  <li><p>
                    Blockchain Land has a decentralized nature and with this blockchain technology, your data is stored in the decentralized nodes, not on the centralized server. Thus, it is stated that Blockchain Land is not responsible for any tampering or loss of any information or data.
                  </p></li>
                  <li> <p>
                    However, it is accepted and recognized by the Nodes that in the event of any order related to blocking, tampering, or deletion of any content, they will be abiding with any laws related to the matter.
                  </p></li>
                </ul>
              </li>
              <li><h2>Gambling</h2>
                <ul>
                  <li><p>
                    If your content involves gambling or any sort of promotion of the same, you need to fulfill these conditions- 1) You should be holding the proper license, to promote or carry gambling of any sort on your site or tools, the license should be acquired before the content has gone live. 2) You must be fully complying with the rules and regulations of gambling in your territory or country. 3) The content should be geo-blocked for content and compliance with the IPs from jurisdictions where gambling is banned. 4) You must include in your terms and conditions, a release from liability in favor of the foundation.
                  </p>
                  </li>
                </ul>
              </li>
              <li><h2>Breaches Of This Policy</h2>
                <ul>
                  <li><p>
                    Any content in infringement of section 2 may be blocked and can result in account suspension, court orders, civil action, criminal prosecutions, and other legal consequences brought to you by the foundation or any other third party.
                  </p>
                  </li>
                </ul>
              </li>
              <li><h2>Limitations to the Liability</h2>
                <ul>
                  <li><p>
                    The company, its officers, employees, and DAO are not responsible for the content, conduct, or information by the users or the third party. They do not control or endorse the exchange of communications in user-to-user interactions on the site or tools.
                  </p></li>
                  <li><p>
                    There will be exposure to various kinds of tools involving conduct, content, and other customer services. Your interactions with other users are entirely at your own risk. The company is not responsible for the accuracy of information submitted by any user. The company has no obligation to get involved in any dispute raised by you and or any other user on each other or any other third party.
                  </p></li>
                  <li><p>
                    The tools and site can contain links to third-party websites, servers, online services, or any setup which is not directly associated with the company. The company is not responsible for any discrepancy between you or the third-party websites, you can directly go through the terms and policies of the third party to get a detailed view of their workings.
                  </p></li>
                  <li><p>
                    You use or copy the data available at your own risk. You are liable for your use, production or reproduction, modification, or distribution of any content in violation of the intellectual property rights and you acknowledge that the company would not be liable in any way for your use of information or any trouble caused because of it.
                  </p></li>
                </ul>
              </li>
              <li> <h2>Changes to the Policy</h2>
                <ul>
                  <li><p>
                    The company or DAO has the right to make changes to this policy, amend it or update it from time to time. All users must stay tuned for these updates.
                  </p></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* section privacy policy end */}
      {/*Footer Start*/}
      <Footer />
      {/*Footer End*/}
    </>
  );
}