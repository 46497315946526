import './App.css'
import "react-multi-carousel/lib/styles.css"
import useAuth from './hooks/useAuth'
import { injected } from './connector'
import { useWeb3React } from '@web3-react/core'
import { loadContract, shortenAddress } from './utils'
import { ETH_CONTRACT, BSC_CONTRACT } from './constants'
import Web3 from 'web3'
import ethABI from './abis/etherAbi.json'
import bnbABI from './abis/bnbAbi.json'
import { useState, useCallback, useEffect } from 'react'
import { setupNetwork } from './helpers/setupNetwork'
import Loading from 'react-simple-dots-loader'
import Loader from './Loader'
import useToast from './hooks/useToast'
import Header from './component/Header'
import Footer from './component/Footer'
import { Helmet } from 'react-helmet'

function Citizenship() {

  const metadataUrl = 'https://gateway.pinata.cloud/ipfs/QmfSnGmfexFsLDkbgN76Qhx2W8sxrNDobFEQZ6ER5qg2wW'
  const [validation, setValidation] = useState({
    _fullName: false,
    _aliasName: false,
    _email: false,
    _emailpattern: false,
    _dob: false
  })
  const { login } = useAuth()
  const { account, connector, chainId, library, activate } = useWeb3React();
  const { toastError } = useToast()
  const [isUserRegistered, setIsUserRegistered] = useState(false)
  const [fees, setFees] = useState(0)
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [aliasName, setAliasName] = useState('')
  const [dob, setDob] = useState('')
  const [universe, setUniverse] = useState('ETH')
  const [metadata, setMetadata] = useState(metadataUrl)
  const [txRegister, setTxRegister] = useState(null)
  const [loading, setLoading] = useState(false)

  const CONTRACT_ADDRESS_BY_CHAINID = {
    4: ETH_CONTRACT,
    97: BSC_CONTRACT
  }

  const ABI_BY_CHAINID = {
    4: ethABI.abi,
    97: bnbABI.abi
  }

  const configNetwork = async () => {
    if (chainId) {
      const provider = await connector.getProvider()
      if (universe === 'ETH' && chainId !== 4) {
        setupNetwork(provider, 'MAINNET')
      } else if (universe === 'BNB' && chainId !== 97) {
        setupNetwork(provider, 'BSC')
      }
    }
  }

  useEffect(() => {
    configNetwork()
  }, [universe])

  const connectWallet = async (connector) => {
    const provider = await connector.getProvider()
    if (universe === 'ETH') {
      await setupNetwork(provider, 'MAINNET')
    } else if (universe === 'BNB') {
      await setupNetwork(provider, 'BSC')
    }
    await login(connector)

  }

  const getUserDetails = async () => {
    if (connector && account) {
      const c = new loadContract(library.provider, ethABI.abi, ETH_CONTRACT)
      const getUserDetails = await c.methods.getUserDetails(account).call()
      setIsUserRegistered(getUserDetails['isApproved'])
      return getUserDetails
    }
  }

  const registrationFeeCalc = async () => {
    if (connector && account) {
      const c = loadContract(library.provider, ethABI.abi, ETH_CONTRACT)
      const registrationFeeCalc = await c.methods.registrationFeeCalc().call()
      setFees(registrationFeeCalc)
    }
  }

  const registerUser = async () => {
    if (connector && account && chainId) {
      if (!isUserRegistered) {
        try {
          setLoading(true)
          const c = loadContract(library.provider, ABI_BY_CHAINID[chainId], CONTRACT_ADDRESS_BY_CHAINID[chainId])
          const registerUser = await c.methods.registerUser(
            fullName,
            email,
            aliasName,
            dob,
            universe,
            metadata
          )
            .send({ from: account, value: fees })
          setLoading(false)
          setTxRegister(registerUser)
        } catch (error) {
          setLoading(false)
          console.log(error)
          if (error.message === 'MetaMask Tx Signature: User denied transaction signature.') {
            toastError('Transaction Rejected!')
          }
        }
      } else {
        toastError("User already registered!!")
      }
    } else {
      toastError('Please connect wallet')
    }
  }

  useEffect(() => {
    if (account) {
      getUserDetails()
      registrationFeeCalc()
    }
  }, [account, txRegister])

  const onBuy = async () => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (!account || !chainId || !activate) {
      toastError('Please connect wallet')
      return
    }
    if (!fullName) {
      setValidation({ ...validation, _fullName: true })
      return
    }
    if (!aliasName) {
      setValidation({ ...validation, _aliasName: true })
      return
    }
    if (!email) {
      setValidation({ ...validation, _email: true })
      return
    }
    if (!regex.test(email)) {
      setValidation({ ...validation, _emailpattern: true })
      return
    }
    if (!dob) {
      setValidation({ ...validation, _dob: true })
      return
    }
    await registerUser()
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <div>
        <Helmet>
          <title>MultiChain Metaverse | Blockchain.land</title>
          <meta name="title" content="MultiChain Metaverse | Blockchain.land" />
          <meta name="description"
            content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
          <meta name="keywords" content="NFT, Virtual reality, financial assets, Digital Citizenship, Multichain Metaverse, Mixed Reality, Virtual Land, Metaverse Land, Decentralized Land, digital assets" />
          <link rel="canonical" href="https://blockchain.land" />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://blockchain.land" />
          <meta property="og:title" content="MultiChain Metaverse | Blockchain.land" />
          <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
          <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://blockchain.land" />
          <meta property="twitter:title" content="MultiChain Metaverse | Blockchain.land" />
          <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
          <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

          <meta name="reply-to" content="blockchain.land" />
          <meta name="robots" content="index, Follow" />
          <meta name="googlebot" content="index, follow" />
          <meta name="revisit-after" content="7 day" />
          <meta name="rating" content="GENERAL" />
          <meta name="language" content="English" />
          <meta name="distribution" content="Global" />
          <meta name="copyright" content="blockchain.land" />
          <link rel="publisher" href="https://blockchain.land/" />
          <link rel="author" href="https://blockchain.land/" />
        </Helmet>
        {/* Start Site Header */}
        <Header />
        {/* end site header */}
        {/* section citizenship start */}
        <section className="section-citizenship bg-citizenship mb_110">
          <div className="container">
            <div className="row">
              <div className="col-xxl-6 col-lg-8 col-md-10 col-12">
                <div>
                  <h2 className="font--40 font-400 white-text lie--47 mb--10 citizenship_h2">
                    Apply for Global Digital Citizenship
                  </h2>
                  <p className="font--16 white-text font-200">
                    {account}
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mt--30">
                      <input type="text" className="form-control bg-transparent border-0 bg-cover" id="formGroupExampleInput" placeholder="Full Name" onChange={e => { setFullName(e.target.value); setValidation({ ...validation, _fullName: false }) }} value={fullName} name="fullName" />
                      {validation._fullName && <p>Please Enter Full Name</p>}
                      <input type="text" className="form-control bg-transparent border-0 bg-cover" id="formGroupExampleInput" placeholder="Alias" onChange={e => { setAliasName(e.target.value); setValidation({ ...validation, _aliasName: false }) }} value={aliasName} name="aliasName" />
                      {validation._aliasName && <p>Please Enter Alias Name</p>}
                      <input type="email" className="form-control bg-transparent border-0 bg-cover" id="formGroupExampleInput" placeholder="Email Address" onChange={e => { setEmail(e.target.value); setValidation({ ...validation, _email: false }); setValidation({ ...validation, _emailpattern: false }) }} value={email} name="email" />
                      {validation._emailpattern && <p>Invalid Email</p>}
                      {validation._email && <p>Please Enter Email</p>}
                      <label htmlFor className="mb-1 font--16 white-text font-300">Birth Date</label>
                      <input type="date" className="form-control bg-transparent border-0 bg-cover" id="formGroupExampleInput" placeholder="Date of Birth" onChange={e => { setDob(new Date(e.target.value).getTime()); setValidation({ ...validation, _dob: false }) }} name="dob" />
                      {validation._dob && <p>Please Enter Date of birth</p>}
                      <label htmlFor className="mb-1 font--16 white-text font-300">Select your preferred token</label>
                      <div className="bg-border bg-cover">
                        <select className="form-select form-control border-0 bg-transparent" aria-label="Default select example" style={{ cursor: 'pointer' }} onChange={e => setUniverse(e.target.value)}>
                          <option selected value={'ETH'}>ETH</option>
                          <option value={'BNB'}>BNB</option>
                          <option value={'LYCAN'}>LYCAN</option>
                          <option value={'FMT'}>FANTOM</option>
                        </select>
                      </div>
                    </div>
                  </form>
                  <p className="font--16 white--light--80 font-300 w--70 mb-0 mt-4">For any news or updates related Blockchain Land we will reach you on the shared email address</p>
                  <div className="d-flex align-items-center mt--20">
                    <div className='btn_height'>
                      <a className="btn-head btn-contain font--16 text-center white-text d-inline-block font-200 mr--15" onClick={() => onBuy()} style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")', cursor: 'pointer' }}>
                        {loading ? <Loader /> : 'Buy Now'}
                      </a>
                    </div>
                    <p className="font--16 font-300 white-text mb-0">Citizenship Fee : <span className="text-blue">$100</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section citizenship end */}
        {/*Footer Start*/}
        <Footer />
        {/*Footer End*/}
      </div>
    </>
  )
}

export default Citizenship