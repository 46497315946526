import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";
import { ROOT_URL } from "./constants";
import useToast from "./hooks/useToast";

function Hire() {
  const { toastError, toastSuccess } = useToast()

  const [name, setName] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [position, setPosition] = useState('')
  const [email, setEmail] = useState('')
  const [citizenNo, setCitizenNo] = useState('')
  const [telegramId, setTelegramId] = useState('')
  const [linkedinLink, setLinkedinLink] = useState('')
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [validation, setValidation] = useState({ name: false, contactnumber: false, position: false, email: false, citizenno: false, telegramid: false, linkedinlink: false, file: false, term: false })
  const [errorMsg, setErroMsg] = useState("");
  const [term, setTerm] = useState(false)

  const onSubmit = async () => {
    setErroMsg("")
    if (!name) {
      setValidation({ ...validation, name: true });
      return
    }
    const patterNo = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    if (!patterNo.test(contactNumber)) {
      setValidation({ ...validation, contactnumber: true });
      return
    }
    if (!position) {
      setValidation({ ...validation, position: true });
      return
    }
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
    if (!pattern.test(email)) {
      setValidation({ ...validation, email: true });
      return
    }
    if (!citizenNo) {
      setValidation({ ...validation, citizenno: true });
      return
    }
    if (!telegramId) {
      setValidation({ ...validation, telegramid: true });
      return
    }
    if (!linkedinLink) {
      setValidation({ ...validation, linkedinlink: true });
      return
    }
    // if (!file) {
    //   setErroMsg("Please upload file")
    //   setValidation({ ...validation, file: true });
    //   return;
    // }
    if (file && file.size / 3145728 > 1) {
      setErroMsg("Filesize must be below 3 MB")
      setValidation({ ...validation, file: true });
      return;
    }
    if (file && (file.name.split(".").length - 1) > 1) {
      setErroMsg("Invalid file type / Contains more than one extensions in file name")
      setValidation({ ...validation, file: true });
      return;
    }
    if (file && !(file.name.split('.')[1] == 'pdf' || file.name.split('.')[1] == 'png' || file.name.split('.')[1] == 'jpg')) {
      setErroMsg("Invalid file extension")
      setValidation({ ...validation, file: true });
      return;
    }
    if (!term) {
      setValidation({ ...validation, term: true });
      return
    }

    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('fullname', name)
      formData.append('contact_number', contactNumber)
      formData.append('position', position)
      formData.append('email', email)
      formData.append('citizenshipnumber', citizenNo)
      formData.append('telegramid', telegramId)
      formData.append('linkdinlink', linkedinLink)
      file != null && formData.append('resume_cv', file)
      formData.append('is_file', file != null ? true : false)
      let career = await axios.post(`${ROOT_URL}/account/hire`, formData);
      if (career.data.length && career.data[0].msg == 'Inserted successfully.') {
        setLoading(false)
        toastSuccess(career.data[0].msg)
        setName('')
        setContactNumber('')
        setPosition('')
        setEmail('')
        setCitizenNo('')
        setTelegramId('')
        setLinkedinLink('')
        setFile(null)
        setTerm(false)
      } else {
        setLoading(false)
        toastError(career.data.code == 500 ? 'Something went wrong' : career.data[0].msg)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <>
      <div>
        <Helmet>
          <title>Career | Blockchain.land</title>
          <meta name="title" content="Career | Blockchain.land" />
          {/* <meta name="description"
            content="people in our metaverse community are eligible to claim exclusive NFTs and join our community. Airdrop winners will be rewarded with unique NFT cubes." />
          <meta name="keywords" content="Non-Fungible token" /> */}
          <link rel="canonical" href="https://blockchain.land/career" />

          <meta name="reply-to" content="blockchain.land" />
          <meta name="robots" content="index, Follow" />
          <meta name="googlebot" content="index, follow" />
          <meta name="revisit-after" content="7 day" />
          <meta name="rating" content="GENERAL" />
          <meta name="language" content="English" />
          <meta name="distribution" content="Global" />
          <meta name="copyright" content="blockchain.land" />
          <link rel="publisher" href="https://blockchain.land/" />
          <link rel="author" href="https://blockchain.land/" />
        </Helmet>

        {/* Header Start */}
        <Header />
        {/* Header End */}

        {/* section hire start */}
        <section className="section-airdrop mt--100 pt--100 garid_line airdrop-height air_hight">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 position-relative">
                <img src="/assets/image/hire/hire.png" className="d-none d-lg-block" alt="Blockchain Land Hiring" />
                <img src="/assets/image/hire/hire_tab.png" className="d-none d-lg-none d-md-block hire_img_tab" alt="card" />
                <img src="/assets/image/hire/hire_m.png" className="d-block d-md-none hire_img_tab" alt="card" />
                <div className="hire_content">
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <h1 className="font-400 hire_h1 mb-3 mb-md-4 mb-lg-0 d-flex">We're&nbsp;<span className="hire_h1 d-lg-block d-none">Hiring</span></h1>
                    <span className="team_highlight hire_h1 d-lg-none d-block">Hiring</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="hire_padding text-center text-lg-start">
                  <h2 className="hire_font_20 font-400 white-text mb-0">We are waiting for your assistance.</h2>
                  <span className="team_highlight hire_font_20">Make your move now...</span>
                  <p className="font-18 font-400 white-text mt-4">Explore the flexible opportunities and join our mission to make Metaverse life simpler, more pleasant and more productive by such advisory positions like legal marketing and strategy, partnership, etc. We are building a virtual reality culture at Blockchain Land  where amazing people like you can have their best virtual life experience with their own Metaverse Avatar. If you are ready to grow your career then you are certainly at right place.</p>
                  <div className="mt-60">
                    <img src="/assets/image/hire/hireline.webp" className="d-none d-lg-block" alt="card" type="image/webp" />
                    <img src="/assets/image/hire/hireline_tab.webp" className="d-none d-lg-none d-md-block" alt="card" type="image/webp" />
                    <img src="/assets/image/hire/hireline_m.webp" className="d-block d-md-none mt-5" alt="card" type="image/webp" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section hire end */}

        {/* section hire form start */}
        <section>
          <div className="container">
            <div className="form_padding">
              <div className="row">
                <div className="col-12">
                  <h3 className="hire_h3 text-center text-lg-start mb-4 mb-md-0">Fill out the form</h3>
                </div>
                <div className="col-lg-6 col-12 mt-60">
                  <div>
                    <div className="mb-4 position-relative">
                      <label className="mb-2 form_label">Full Name</label>
                      <input type="text" className="form-control" id="" placeholder="Enter your full name" value={name} onChange={(e) => { setName(e.target.value); setValidation({ ...validation, name: false }); }} />
                      {validation.name && (
                        <div>
                          <label className="err_msg_tooltip">
                            {name ? "" : "Please enter your full name"}
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="mb-4 position-relative">
                      <label className="mb-2 form_label">Contact Number</label>
                      <input type="number" className="form-control" id="" placeholder="Enter your contact number" value={contactNumber} onChange={(e) => { setContactNumber(e.target.value); setValidation({ ...validation, contactnumber: false }); }} />
                      {validation.contactnumber && (
                        <div>
                          <label className="err_msg_tooltip">
                            {contactNumber ? "Invalid contact number" : "Please enter your contact number"}
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="mb-4 position-relative">
                      <label className="mb-2 form_label">Category of Advisory</label>
                      <select className="form-select form-control" aria-label="Default select example" value={position} onChange={(e) => { setPosition(e.target.value); setValidation({ ...validation, position: false }); }} >
                        <option value='' selected>-- Select Designation --</option>
                        <option value='Growth Advisor'>Growth Advisor</option>
                        <option value='Legal Advisor'>Legal Advisor</option>
                        <option value='Marketing Advisor'>Marketing Advisor</option>
                        <option value='Strategy Advisor'>Strategy Advisor</option>
                        <option value='Partnerships'>Partnerships</option>
                        <option value='Open Position'>Open Position</option>
                      </select>
                      {validation.position && (
                        <div>
                          <label className="err_msg_tooltip">
                            {position ? "" : "Please select position"}
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="mb-4 position-relative">
                      <label className="mb-2 form_label">Email Id</label>
                      <input type="email" className="form-control" id="" placeholder="e.g. example@gmail.com" value={email} onChange={(e) => { setEmail(e.target.value); setValidation({ ...validation, email: false }); }} />
                      {validation.email && (
                        <div>
                          <label className="err_msg_tooltip">
                            {email ? "Invalid email address" : "Please enter your email address"}
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="mb-4 position-relative">
                      <label className="mb-2 form_label">Blockchain Land Citizenship ID#</label>
                      <input type="number" className="form-control" id="" placeholder="Enter your citizenship id" value={citizenNo} onChange={(e) => { setCitizenNo(e.target.value); setValidation({ ...validation, citizenno: false }); }} />
                      {validation.citizenno && (
                        <div>
                          <label className="err_msg_tooltip">
                            {citizenNo ? "" : "Please enter your citizenship number"}
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 margin_top">
                  <div className="mb-4 position-relative">
                    <label className="mb-2 form_label">Telegram Id</label>
                    <input type="text" className="form-control" id="" placeholder="Enter your telegram id" value={telegramId} onChange={(e) => { setTelegramId(e.target.value); setValidation({ ...validation, telegramid: false }); }} />
                    {validation.telegramid && (
                      <div>
                        <label className="err_msg_tooltip">
                          {telegramId ? "" : "Please enter your telegram id"}
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="mb-4 position-relative">
                    <label className="mb-2 form_label">LinkedIn Profile Link</label>
                    <input type="text" className="form-control" id="" placeholder="Enter your linkedin profile link" value={linkedinLink} onChange={(e) => { setLinkedinLink(e.target.value); setValidation({ ...validation, linkedinlink: false }); }} />
                    {validation.linkedinlink && (
                      <div>
                        <label className="err_msg_tooltip">
                          {linkedinLink ? "" : "Please enter your linkedin profile link"}
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <p className="font-14 font-400 white-text mb-2">Upload Resume / CV</p>
                    <p className="font-14 font-400 hire_p">File type : pdf, png or jpg file</p>
                    <p className="font-14 font-400 hire_p">File size : Max. 3 MB</p>
                  </div>
                  <div className="position-relative">
                    <input type="file" className="form-control drope" visibility="hidden" onChange={(e) => { setFile(e.target.files[0]); setValidation({ ...validation, file: false }); }} />
                    <div className="input_image_text">
                      {file ?
                        <p>{file.name}</p>
                        :
                        <>
                          <p className="font-16 font-400 text-white text_underline text-center">Upload File</p>
                          <span className="font-14 font-400 hire_p">Drag 'n' drop some file here, or click to select files</span>
                        </>
                      }
                    </div>
                    {validation.file && (
                      <div>
                        <label className="err_msg_tooltip file_top">
                          {errorMsg}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 d-flex align-items-center mb-lg-4 mb-2 mt-2 mt-lg-0">
                  <input type={"checkbox"} className="form-check-input pointer me-2 mt-0" style={validation.term ? { borderColor: 'red' } : {}} checked={term} onClick={() => { setTerm(!term); setValidation({ ...validation, term: false }); }} />
                  <p className="terms_condition">I have read and understood the <a href="/advisoragreement" target={"_blank"} className="text-white">Terms of the Advisory Agreement.</a></p>
                </div>
                <div className="col-12 text-center text-lg-start">
                  <button className="btn explore_now submit_btn" disabled={loading ? true : false} onClick={() => onSubmit()}>{loading ? "Loading..." : "Submit"}</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section hire form end */}

        <Community />

        {/*Footer Start*/}
        <Footer />
        {/*Footer End*/}
      </div>
    </>
  );
}

export default Hire;