import './App.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import Home from './Home';
import Citizenship from './Citizenship';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import { Toaster } from 'react-hot-toast';
import Mint from './Mint';
import PrivacyPolicy from './PrivacyPolicy';
import ContentPolicy from './ContentPolicy';
import Airdrop from './Airdrop';
import Token from './Token';
import JoinCommunity from './JoinCommunity';
import Contact from './Contact';
import FAQs from './FAQs';
import Marketplace from './Marketplace';
import Metaverse from './Metaverse';
import FutureMetaverse from './FutureMetaverse';
import ScrollToTop from './component/ScrollToTop';
import Roadmap from './Roadmap';
import CitizenShipInfo from './CitizenShipInfo';
import Whatknow from './Whatknow';
import NFTLaunchpad from './NFTLaunchpad';
import TypesOfNft from './TypesOfNft';
import Getcitizenship from './Getcitizenship';
import AirdropGiveaway from './AirdropGiveaway';
import NotFound from './NotFound';
import AirdropNew from './AirdropNew';
import Team from './Team';
import Hire from './Hire';
import AdvisorAgreement from './AdvisorAgreement';
import AdvisorOnboarding from './AdvisorOnboarding';
import MetaLife from './MetaLife';
import MetaverseEvolution from './MetaverseEvolution';
import AmbassadorProgram from './AmbassadorProgram';
import IMO from './IMO';
import Demo from './Demo';
import MetaLand from './MetaLand';
import BlogList from './BlogList';
import Blog_Ambassador from './Blog_Ambassador';
import Campaign from './Campaign';

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}

function App() {
  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/citizenship" element={<Citizenship />} /> */}
              <Route path="/mint" element={<Mint />} />
              <Route path="/privacy_policy" element={<PrivacyPolicy />} />
              <Route path="/content_policy" element={<ContentPolicy />} />
              <Route path="/airdrop" element={<Airdrop />} />
              <Route path="/token" element={<Token />} />
              <Route path='/joincommunity' element={<JoinCommunity />} />
              <Route path='/contact' element={<Contact />} />

              <Route path='/faqs' element={<FAQs />} />
              <Route path='/marketplace' element={<Marketplace />} />
              <Route path='/metaverse' element={<Metaverse />} />
              <Route path='/futuremetaverse' element={<FutureMetaverse />} />
              <Route path='/roadmap' element={<Roadmap />} />
              {/* <Route path='/citizenshipinfo' element={<CitizenShipInfo />} /> */}
              <Route path='/whatknow' element={<Whatknow />} />
              <Route path="/nftlaunchpad" element={<NFTLaunchpad />} />
              <Route path='/typesofnft' element={<TypesOfNft />} />
              <Route path='/getcitizenship' element={<Getcitizenship />} />
              <Route path='/airdropgiveaway' element={<AirdropGiveaway />} />
              <Route path='/giveaways' element={<AirdropNew />} />
              <Route path='/team' element={<Team />} />
              <Route path='/career' element={<Hire />} />
              <Route path='/advisoragreement' element={<AdvisorAgreement />} />
              <Route path='/advisoronboarding' element={<AdvisorOnboarding />} />
              <Route path='/metalife' element={<MetaLife />} />
              <Route path='/metaverseevolution' element={<MetaverseEvolution />} />
              <Route path='/ambassadorprogram' element={<AmbassadorProgram />} />
              <Route path='/initialmetaverseofferings' element={<IMO />} />
              <Route path='/metaland' element={<MetaLand />} />
              <Route path='/bloglist' element={<BlogList />} />
              <Route path='/ambassador_blog' element={<Blog_Ambassador />} />
              <Route path='/campaign' element={<Campaign />} />



              <Route path='/demo' element={<Demo />} />


              <Route path='/*' element={<NotFound />} />

            </Routes>
          </ScrollToTop>
        </BrowserRouter>
        <Toaster position="top-right" toastOptions={{ duration: 2000 }} />
      </Web3ReactProvider>
    </>
  );
}

export default App;
