import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function NFTLaunchpad() {

    const [seconds, setSeconds] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const endtime = 2592000000
    const currDate = 1662042368581 + endtime
    const loading0 = (num) => {
        return num < 10 ? "0" + num : num;
    };

    function getTimeUntil(deadline) {
        const time = Date.parse(deadline) - Date.parse(new Date());
        if (time < 0) {
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        } else {
            const seconds = Math.floor((time / 1000) % 60);
            const minutes = Math.floor((time / 1000 / 60) % 60);
            const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
            const days = Math.floor(time / (1000 * 60 * 60 * 24));
            setDays(days);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        }
    }
    // console.log("DDS",new Date())
    useEffect(() => {
        if (currDate) {
            let d = new Date(currDate);
            let interval = setInterval(() => getTimeUntil(d), 1000);
            return () => clearInterval(interval);
        }
    }, [currDate]);

    return (
        <>
            <Helmet>
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                <title>NFT Launchpad | Blockchain Land</title>
                <meta name="title" content="NFT Launchpad | Blockchain Land" />
                <meta name="keywords" content="NFT Launchpad, Mint NFTs, Minting an NFT, Metaverse Event" />
                <link rel="canonical" href="https://blockchain.land/nftlaunchpad" title="nftlaunchpad" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blockchain.land/nftlaunchpad" />
                <meta property="og:title" content="NFT Launchpad | Blockchain.land" />
                <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blockchain.land/nftlaunchpad" />
                <meta property="twitter:title" content="NFT Launchpad | Blockchain.land" />
                <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>

            <Header />
            {/* section brand start */}
            <section className="bg_brand brand_pt_425 brand_height brand_mt_100 Launchpad_bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="text-center mt-100">
                                <h1 className="font-200 white-text cmb_16">
                                    NFT Launchpad
                                </h1>
                                <p className="font-18 font-100 white-text line-25 mb--40">
                                    Any user can mint their NFTs as creators on NFT launchpad where they can make contact with other people and can raise funds to build, launch projects, or do marketing of their projects as well as they can acquire more knowledge about digital citizenship.
                                </p>
                                <a href="https://meta.blockchain.land/" target="_blank" className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Know More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* assert metaplace strat */}
            <section className="margintop_180 mb-md-5 mb-xl-0">
                <div className="container-fluid px-0">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="pl-243 pr-20 mb-5 text-center text-lg-start">
                                <h2 className="font--70 font-200 white-text line--80 mb---20">Metaverse Event
                                </h2>
                                <p className="font-18 font-100 white-text mb-35 line-25">
                                    Soon, one event will be launched called metaverse event where users can buy or sell assets and can get publicity for their brands or assets globally. All services are supported worldwide.
                                </p>
                                <span className="text-blue font-400 font-18 mb-2 d-block"> Current Events</span>
                                {/* <div className="d-flex align-items-center justify-content-between Launchpadevent mt-3">
                                    <p><img src="/assets/image/nftlaunchpad/blue.svg" className="me-2" alt="" />Airplane Event </p>
                                    <div className="d-flex align-items-center">
                                        <span className="Launchpevent me-2"></span>
                                        <p>Live</p>
                                    </div>
                                </div> */}
                                <div className="d-flex align-items-center justify-content-between Launchpadevent">
                                    <p className="font-16 font-100"><img src="/assets/image/nftlaunchpad/yellow.svg" className="me-2" alt="yellow" />Launchpad Event</p>
                                    <p className="font-16 font-100">{loading0(days)}d : {loading0(hours)}h : {loading0(minutes)}m : {loading0(seconds)}s</p>
                                </div>
                                <div className='d-flex btn_height mt-3'>
                                    <a href="https://meta.blockchain.land/" target="_blank" className="btn-head btn-contain text-center text-white font-16 d-block font-200 mx-auto mx-lg-0" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Visit Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps_100">
                            <img src="assets/image/nftlaunchpad/event.webp" alt="Blockchain Land Metaverse Event" className="lose-image" type="image/webp" />
                        </div>
                    </div>
                </div>
            </section>
            {/* assert metaplace end */}

            <section className="margintop_180 position-relative">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 mt-md-5 mt-lg-0">
                            <div className="mb-5 text-center">
                                <h2 className="font--70 font-200 white-text line--80 mb---20">Features</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain lunchcard" style={{ backgroundImage: 'url("assets/image/nftlaunchpad/cardbg.svg")' }}>
                                <img src="assets/image/nftlaunchpad/Easy-to-launch_projects.svg" alt="Features" />
                                <h3 className="font-30 font-400 white-text mt-2">Easy-to-launch projects:
                                </h3>
                                <p className="white--light--80 mt-2 line_height font-100">
                                    Each creator can receive a unique portfolio from the platform to launch their NFTs, projects or brands and can manage it separately.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-md-0 mt-4">
                            <div className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain lunchcard" style={{ backgroundImage: 'url("assets/image/nftlaunchpad/cardbg.svg")' }}>
                                <img src="assets/image/nftlaunchpad/Quick_community_development.svg" alt="Features" />
                                <h3 className="font-30 font-400 white-text mt-2">Quick community development:
                                </h3>
                                <p className="white--light--80 mt-2 line_height font-100">Creators build a community of collectors who can strive to purchase the new and exclusive NFTs which can build huge community traction towards your creations.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-lg-0 mt-4">
                            <div className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain lunchcard" style={{ backgroundImage: 'url("assets/image/nftlaunchpad/cardbg.svg")' }}>
                                <img src="assets/image/nftlaunchpad/Decentralized_Governance.svg" alt="Features" />
                                <h3 className="font-30 font-400 white-text mt-2">Decentralized Governance:
                                </h3>
                                <p className="white--light--80 mt-2 line_height font-100">Community can hold the voting before listing a new project on launchpad. Here, each member of the community can analyze the project and register their vote accordingly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section brand start */}

            {/* section community start */}
            <div className="mt-100"></div>
            <Community />
            {/* section community end */}
            <Footer />
        </>
    );
}