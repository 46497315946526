import React from 'react';
import { Helmet } from 'react-helmet';
import Community from './component/Community';
import Footer from './component/Footer';
import Header from './component/Header';

export default function FAQs() {
  return (
    <>
      <Helmet>
        <meta
          property="twitter:image"
          itemProp="image"
          content="https://blockchain.land/assets/image/meta_img/twitter_meta.png"
        />

        <title>Frequently Asked Questions (FAQs) | Blockchain Land</title>
        <meta
          name="title"
          content="Frequently Asked Questions (FAQs) | Blockchain Land"
        />
        <meta
          name="description"
          content="Looking for your answers? Discover all the information about Blockchain Land, the First-Ever Multichain Metaverse."
        />
        <meta
          name="keywords"
          content="Blockchain land FAQs, Blockchain land queries"
        />
        <link
          rel="canonical"
          href="https://blockchain.land/faqs"
          title="faqs"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blockchain.land/faqs" />
        <meta
          property="og:title"
          content="Frequently Asked Questions (FAQs) | Blockchain.land"
        />
        <meta
          property="og:description"
          content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains."
        />
        <meta
          property="og:image"
          itemProp="image"
          content="https://blockchain.land/assets/image/meta_img/facebook_meta.png"
        />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://blockchain.land/faqs" />
        <meta
          property="twitter:title"
          content="Frequently Asked Questions (FAQs) | Blockchain.land"
        />
        <meta
          property="twitter:description"
          content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains."
        />

        <meta name="reply-to" content="blockchain.land" />
        <meta name="robots" content="index, Follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="revisit-after" content="7 day" />
        <meta name="rating" content="GENERAL" />
        <meta name="language" content="English" />
        <meta name="distribution" content="Global" />
        <meta name="copyright" content="blockchain.land" />
        <link rel="publisher" href="https://blockchain.land/" />
        <link rel="author" href="https://blockchain.land/" />
      </Helmet>

      <Header />

      <section className="section-airdrop faq_bg mt--100">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-6">
              <div className="text-center text-sm-start">
                <h1 className="white-text font-200 mb--33">FAQs</h1>
                <p className="font-18 font-100 white-text mb--40">
                  Looking for your answers? You've come to the right place.
                  Browse through these FAQs to find answers to commonly raised
                  questions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section start faqs */}
      <section className="section-listed">
        <div className="container">
          {/*Listed Start*/}
          <div className="accordion" id="accordionExample">
            <div className="row">
              <div className="col-lg-6 pe-lg-3">
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button faqaccbtn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      How to create an account on Blockchain Land?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Blockchain Land is a firmly Decentralized network. If
                        users want to create an account then they just need to
                        connect to any web 3.0 Wallet and If users want to
                        register themselves then they have to fill all the
                        details in
                        <a
                          className="text-blue"
                          href="https://market.blockchain.land/citizenship"
                          target="_blank"
                        >
                          {' '}
                          https://market.blockchain.land/citizenship
                        </a>{' '}
                        form.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What is the difference between the Creator and Enterprise
                      Account?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Here, only a single person like an artist can apply as
                        creators, however as an enterprise, any brands can apply
                        as well as multiple people can access into the accounts
                        but here, the documentation process of this is quite
                        long.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How do I purchase one Parcel?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        People who mint in the country or valley can sell their
                        parcel or put it in the auction or sale. Users can buy a
                        parcel from this auction,owner, or metaverse
                        marketplace. The price will be decided by the parcel
                        owner.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      How do I mint a Valley?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        To mint valley, you have to purchase a map firstly or
                        you can design your own map by following such rules and
                        regulations. After That, you can mint your valley from
                        your map. 10*10 Parcels make a valley. You can decide
                        which map you want to mint from the shown inventory. The
                        price amount will be burnt when minting will be done. At
                        the end you can sell that parcel or put it in auction or
                        sale.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      How do I mint a Country?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        To mint Country, you have to purchase a map first or you
                        can design your own map by following such rules and
                        regulations. After That, you can mint your valley from
                        your map. 25*25 Parcels make a Country. You can decide
                        which map you want to mint from the shown inventory. The
                        price amount will be burnt when minting will be done. At
                        the end you can sell that parcel or put it in auction or
                        sale.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      How do I mint a Planet?
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Currently no one can mint planets. Blockchain Land will
                        launch DAO in which planet and blockchain based voting
                        will be done. Planet minting will be based on the result
                        of the voting.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Can I bring my own assets to design a Land?
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Yes, definitely you can design your Land by minting any
                        3D assets or NFTs otherwise you can purchase it from the
                        marketplace to design your Land with your desired
                        facilities like luxurious home, car, garage.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingEight">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      Which Blockchains are supported right now?
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Popular, unshakeable,and scalable blockchains like
                        Ethereum, Binance Smart Chain, LycanChain, and Fantom
                        will be released in phase 1 and other popular
                        blockchains will be integrated in phase 2.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingNine">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      What is the native currency of Blockchain Land?
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingNine"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Blockchain Land Token (BCL) is the native currency of
                        Blockchain Land. Users can read all the details from
                        <a
                          href="https://blockchain.land/token"
                          className="text-blue"
                        >
                          {' '}
                          https://blockchain.land/token .
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingTen">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      What is BCL used for?
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        BCL can be used to buy citizenship and NFT as well as
                        parcel, valley, and country can be minted. Trading and
                        swap can be done with BCL.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingEleven">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven"
                      aria-expanded="false"
                      aria-controls="collapseEleven"
                    >
                      What can I do with my Parcel?
                    </button>
                  </h2>
                  <div
                    id="collapseEleven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEleven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        You can resell your parcel or put it in an auction or in
                        Sale. Users can buy or mint assets from the marketplace
                        and can Visit around the Parcel. They also can organize
                        events or open the store.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingTwelve">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwelve"
                      aria-expanded="false"
                      aria-controls="collapseTwelve"
                    >
                      How do I know who owns a Parcel?{' '}
                    </button>
                  </h2>
                  <div
                    id="collapseTwelve"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwelve"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        All the details of the parcel will be shown in the
                        metaverse portal of Blockchain Land. From there, users
                        can know Owner’s address also.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingThirteen">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThirteen"
                      aria-expanded="false"
                      aria-controls="collapseThirteen"
                    >
                      How to buy and use the NFTs within my land parcel?
                    </button>
                  </h2>
                  <div
                    id="collapseThirteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThirteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Any NFT can be bought from the marketplace. When you
                        will enter in to the land portal, your parcel will be
                        shown there. The numbers and types of NFTs will be shown
                        into the Inventory and it will be shown after the
                        selection of the parcel. You can add it into the parcel
                        and can design your parcel on your own.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ps-lg-4">
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingFourteen">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourteen"
                      aria-expanded="false"
                      aria-controls="collapseFourteen"
                    >
                      What devices can I use to explore Blockchain Land?
                    </button>
                  </h2>
                  <div
                    id="collapseFourteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFourteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        You can use oculus quest 2, macbook, pc, android, ios
                        devices. Blockchain Land metaverse compatible in any
                        device but we personally suggest oculus quest 2 for best
                        experiences.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingFifteen">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFifteen"
                      aria-expanded="false"
                      aria-controls="collapseFifteen"
                    >
                      Can I log in simultaneously from multiple devices?
                    </button>
                  </h2>
                  <div
                    id="collapseFifteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFifteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        No, certainly not. You can activate your account at one
                        place at a time. However, you can activate your account
                        as many as you like in the marketplace.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingSixteen">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixteen"
                      aria-expanded="false"
                      aria-controls="collapseSixteen"
                    >
                      What Browsers do I use for my Laptop or PC?
                    </button>
                  </h2>
                  <div
                    id="collapseSixteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSixteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        We are compatible with Google Chrome, Firefox, Safari
                        and Opera but we recommend Google Chrome for the best
                        user experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingSeventeen">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeventeen"
                      aria-expanded="false"
                      aria-controls="collapseSeventeen"
                    >
                      Is there a Mobile App or do I use a Mobile Browser?
                    </button>
                  </h2>
                  <div
                    id="collapseSeventeen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeventeen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        While our Mobile App will come out soon until then we
                        recommend the following mobile browsers: Google Chrome.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingEighteen">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEighteen"
                      aria-expanded="false"
                      aria-controls="collapseEighteen"
                    >
                      Are there any minimum system requirements when using a
                      Mobile, Laptop or PC?
                    </button>
                  </h2>
                  <div
                    id="collapseEighteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEighteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Well, there is not any minimum requirement but if you
                        want to better explore then the Graphics should be at
                        least about 2GB.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingNineteen">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNineteen"
                      aria-expanded="false"
                      aria-controls="collapseNineteen"
                    >
                      Do I need to connect my wallet to explore Blockchain Land?
                    </button>
                  </h2>
                  <div
                    id="collapseNineteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingNineteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Yes, ofcourse. Everything is connected with your Web 3.0
                        Wallet address in Blockchain Land.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingTwenty">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwenty"
                      aria-expanded="false"
                      aria-controls="collapseTwenty"
                    >
                      If I lose my account credentials, what do I do?
                    </button>
                  </h2>
                  <div
                    id="collapseTwenty"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwenty"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Well, unfortunately you have to forget that account as
                        Blockchain Land’s accesses are decentralized so,
                        platform can not help you at all.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingTwenty-one">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwenty-one"
                      aria-expanded="false"
                      aria-controls="collapseTwenty-one"
                    >
                      How can I block or report someone or something?
                    </button>
                  </h2>
                  <div
                    id="collapseTwenty-one"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwenty-one"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        If you will block or report someone then that request
                        will be sent to the system to verify the reported issue
                        and if the issue will be genuine then that account will
                        be suspended and you will not get to see it ever again.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingTwenty-two">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwenty-two"
                      aria-expanded="false"
                      aria-controls="collapseTwenty-two"
                    >
                      Can I name my assets? How can I ensure no one else is
                      fraudulently using my asset names?
                    </button>
                  </h2>
                  <div
                    id="collapseTwenty-two"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwenty-two"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Yes, you can name your assets and no one can create the
                        same name’s assets again. If any other user will make
                        small changes and create the assets like yours then you
                        can report it to the system.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingTwenty-three">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwenty-three"
                      aria-expanded="false"
                      aria-controls="collapseTwenty-three"
                    >
                      How is the Content stored?
                    </button>
                  </h2>
                  <div
                    id="collapseTwenty-three"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwenty-three"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Decentralized storage (IPFS) stores each and every
                        content of the marketplace and for better speed AWS s3
                        is also integrated. you can also see your assets in your
                        Blockchain transaction.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingTwenty-four">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwenty-four"
                      aria-expanded="false"
                      aria-controls="collapseTwenty-four"
                    >
                      How do I transfer assets outside Blockchain Land?
                    </button>
                  </h2>
                  <div
                    id="collapseTwenty-four"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwenty-four"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Every asset will be in your wallet and you can share it
                        to any other address as per your convenience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item m-0">
                  <h2 className="accordion-header" id="headingTwenty-five">
                    <button
                      className="accordion-button faqaccbtn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwenty-five"
                      aria-expanded="false"
                      aria-controls="collapseTwenty-five"
                    >
                      How do I withdraw my funds?
                    </button>
                  </h2>
                  <div
                    id="collapseTwenty-five"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwenty-five"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        You don't need to withdraw, because every system is
                        decentralized so, it will be directly transfer to your
                        wallet
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Listed End*/}
        </div>
      </section>
      {/* section end faqs */}
      {/* section community start */}
      <Community />
      {/* section community end */}
      <Footer />
    </>
  );
}
