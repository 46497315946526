import React from "react";
import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

function AirdropNew() {
    return (
        <>
            <div>
                <Helmet>
                    <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                    <title>Metaverse Exclusive Airdrop | Blockchain Land</title>
                    <meta name="title" content="Metaverse Exclusive Airdrop | Blockchain Land" />
                    <meta name="description"
                        content="people in our metaverse community are eligible to claim exclusive NFTs and join our community. Airdrop winners will be rewarded with unique NFT cubes." />
                    <meta name="keywords" content="Non-Fungible token" />
                    <link rel="canonical" href="https://blockchain.land/giveaways" title="giveaways" />

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://blockchain.land/giveaways" />
                    <meta property="og:title" content="Airdrop | Blockchain.land" />
                    <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                    <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://blockchain.land/giveaways" />
                    <meta property="twitter:title" content="Airdrop | Blockchain.land" />
                    <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                    <meta name="reply-to" content="blockchain.land" />
                    <meta name="robots" content="index, Follow" />
                    <meta name="googlebot" content="index, follow" />
                    <meta name="revisit-after" content="7 day" />
                    <meta name="rating" content="GENERAL" />
                    <meta name="language" content="English" />
                    <meta name="distribution" content="Global" />
                    <meta name="copyright" content="blockchain.land" />
                    <link rel="publisher" href="https://blockchain.land/" />
                    <link rel="author" href="https://blockchain.land/" />
                </Helmet>
                {/* Header Start */}
                <Header />
                {/* Header End */}

                {/* section airdrop start */}
                <section className="section-airdrop mt--100 pt--100 garid_line airdrop-height air_hight">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <h1 className="white-text font-200 mb--33">
                                        Introducing
                                    </h1>
                                    <div className="airdrop_img">
                                        <img src="/assets/image/text/air.webp" alt="airdrope" type="image/webp" />
                                    </div>
                                    <p className="font-18 font-100 white-text mb--40">
                                        we are excited to announce that Blockchain Land is giving away an exclusive <br className="d-md-block d-none" /> airdrop for Blockchain Land members in different phases with great rewards.
                                    </p>
                                    {/* <a className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Airdrop Ended</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="citizen_sec mt--100">
                        <img src="/assets/image/airdrop/citizen.webp" className="d-none d-lg-block" alt="Blockchain Land Metaverse Citizenship Card" type="image/webp" />
                        <img src="/assets/image/airdrop/citizen_mob.webp" className="d-block d-lg-none" alt="Citizenship card" type="image/webp" />
                    </div>
                </section>
                {/* section airdrop end */}

                {/* section token start */}
                <section className="pb-2 pb-lg-5">
                    <div className="container">
                        <div className='vally_mapping mt-5'>
                            <img src="/assets/image/airdrop/avatar1.webp" className="w-100 d-none d-lg-block" alt="Metaverse Avatars" type="image/webp" />
                            <img src="/assets/image/airdrop/avatar2.webp" className="w-100 d-none d-md-block d-lg-none" alt="Metaverse Exclusive Airdrop" type="image/webp" />
                            <img src="/assets/image/airdrop/avatar3.webp" className="w-100 d-block d-md-none" alt="Metaverse Exclusive Airdrop" type="image/webp" />

                            {/* <img src="/assets/image/airdrop/card_m.svg" className="mx-auto d-block d-md-none" alt="card" /> */}
                            <img src="/assets/image/airdrop/card_tab.webp" className="mx-auto d-block d-lg-none w-75" alt="Citizenship card" type="image/webp" />
                            <img src="/assets/image/airdrop/card.png" className="citizen_card d-lg-block d-none" alt="Metaverse Citizenship Card" />

                            <div className="row align-items-center mt--100 bg_nft_position">
                                <div className="col-lg-6 col-12">
                                    <div className="spacemap_card text-center text-lg-start">
                                        <h2 className="airdrop_h2 mb---30">Blockchain Land Metaverse Exclusive Airdrop</h2>
                                        <div className='d-flex btn_height justify-content-center justify-content-lg-start'>
                                            <a href="https://market.blockchain.land/" target="_blank" className="btn_avatar btn-contain text-center text-white font-16 d-block mx-auto mx-sm-0" style={{ backgroundImage: 'url("assets/image/airdrop/btn.svg")' }}>How to join the Airdrop?</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-7 offset-lg-1 col-sm-4">
                                    <img src="assets/image/typesnft/map.svg" alt="map" title='map' />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* section token end */}

                {/* section Citizenship Giveaway start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 text-center text-lg-start">
                                <div className="phase">
                                    <span>Phase 1</span>
                                </div>
                                <div className="airdrop_live">
                                    <span></span>
                                    <p>Airdrop Ended</p>
                                </div>
                                <h2 className="airdrop_h2 giveaway_h2 mb--40">Citizenship Giveaway</h2>
                                <p className="font-18 font-100 mb--40 white-text line-25">
                                    Blockchain Land is giving away exclusive airdrop to members of the community in the form of free citizenship worth of $100 which gives them access to enter into the virtual world of Blockchain Land.
                                </p>
                                <p className="font-18 font-300 white-text mb--20 d-flex justify-content-center justify-content-lg-start align-items-center">
                                    <img src="assets/image/airdrop/time.svg" className="me-3" alt="time" />04 - Oct - 2022 to 14 - Oct - 2022
                                </p>
                                <p className="font-18 font-300 white-text bg_hightlight mb--30 d-lg-inline-block d-flex align-items-md-center align-items-start text-center text-lg-start justify-content-center justify-content-lg-start">
                                    <img src="assets/image/airdrop/gift.svg" className="me-3" alt="gift" />Get Free Citizenship Of Blockchain Land Worth Of $100.
                                </p>
                                <a href="https://market.blockchain.land/" target="_blank" className="btn_agrab btn-contain text-center text-white font-16 d-block mx-auto mx-lg-0" style={{ backgroundImage: 'url("assets/image/airdrop/grab.png")' }}>Citizenship Ended</a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Citizenship Giveaway end */}

                {/* section Metaverse NFT Assets start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="giveaway">
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <img src="assets/image/airdrop/assets.webp" className="img_top d-lg-block d-none" alt="card" type="image/webp" />
                                    <img src="assets/image/airdrop/assets_m.webp" className="img_top d-lg-none d-block" alt="card" type="image/webp" />
                                </div>
                                <div className="col-lg-6 text-center text-lg-start">
                                    <div className="phase mb--40">
                                        <span>Phase 2</span>
                                    </div>
                                    <h2 className="airdrop_h2 giveaway_h2 mb--40">Metaverse NFT Assets
                                        Giveaway</h2>
                                    <p className="font-18 font-100 mb--40 white-text line-25">
                                        Blockchain Land is giving away exclusive airdrop to several lucky winners of the community in the form of free metaverse NFT assets worth of $100K. Embrace your inner creator, seize the opportunity earlier.
                                    </p>
                                    <p className="font-18 font-300 white-text mb--20 d-flex justify-content-center justify-content-lg-start align-items-center">
                                        <img src="assets/image/airdrop/time.svg" className="me-3" alt="time" />15 - Oct - 2022 to 24 - Oct - 2022
                                    </p>
                                    <p className="font-18 font-300 white-text mb--20 d-flex justify-content-center justify-content-lg-start align-items-center">
                                        <img src="assets/image/airdrop/photo.svg" className="me-3" alt="time" />Total No. of Assets : 1000
                                    </p>
                                    <p className="font-18 font-300 white-text mb--20 d-flex justify-content-center justify-content-lg-start align-items-center">
                                        <img src="assets/image/airdrop/user.webp" className="me-3" alt="time" type="image/webp" />Lucky User : 1000
                                    </p>
                                    <p className="font-18 font-300 white-text bg_hightlight d-lg-inline-block d-flex align-items-md-center align-items-start text-center text-lg-start justify-content-center justify-content-lg-start mb--30">
                                        <img src="assets/image/airdrop/gift.svg" className="me-3" alt="time" />1K Lucky Winners Will Get Free Metaverse NFT Assets Worth Of $100K.
                                    </p>
                                    <a href="https://market.blockchain.land/" target="_blank" className="btn_agrab btn-contain text-center text-white font-16 d-block mx-auto mx-lg-0" style={{ backgroundImage: 'url("assets/image/airdrop/grab.png")' }}>Citizenship Ended</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Metaverse NFT Assets end */}

                {/* section Metaverse Land / Parcel start */}
                <section className="section-worth pt-5">
                    <div className="container">
                        <div className="giveaway giveaway_2">
                            <div className="row">
                                <div className="col-lg-6 text-center text-lg-start order-1 order-lg-0">
                                    <div className="phase mb--40">
                                        <span>Phase 3</span>
                                    </div>
                                    <h2 className="airdrop_h2 giveaway_h2 mb--40">Metaverse Land / Parcel Giveaway</h2>
                                    <p className="font-18 font-100 mb--40 white-text line-25">
                                        Blockchain Land is giving away an exclusive airdrop of 500 parcels of the Metaverse land to members of the community which gives them access to enter into the Virtual world of Blockchain Land.
                                    </p>
                                    <p className="font-18 font-300 white-text mb--20 d-flex justify-content-center justify-content-lg-start align-items-center">
                                        <img src="assets/image/airdrop/time.svg" className="me-3" alt="time" />25 - Oct - 2022 to 04 - Nov - 2022
                                    </p>
                                    <p className="font-18 font-300 white-text mb--20 d-flex justify-content-center justify-content-lg-start align-items-center">
                                        <img src="assets/image/airdrop/photo.svg" className="me-3" alt="time" />Total Parcels : 500
                                    </p>
                                    <p className="font-18 font-300 white-text mb--20 d-flex justify-content-center justify-content-lg-start align-items-center">
                                        <img src="assets/image/airdrop/user.webp" className="me-3" alt="time" type="image/webp" />Lucky User : 500
                                    </p>
                                    <p className="font-18 font-300 white-text bg_hightlight d-lg-inline-block d-flex align-items-md-center align-items-start justify-content-center justify-content-lg-start mb--30">
                                        <img src="assets/image/airdrop/gift.svg" className="me-3" alt="time" />Get a free parcel of Metaverse Land of Blockchain Land
                                    </p>
                                    <a href="https://market.blockchain.land/" target="_blank" className="btn_agrab btn-contain text-center text-white font-16 d-block mx-auto mx-lg-0" style={{ backgroundImage: 'url("assets/image/airdrop/grab.png")' }}>Citizenship Ended</a>
                                </div>
                                <div className="col-lg-6 order-0 order-lg-1 mb-5 mb-lg-0">
                                    <img src="assets/image/airdrop/map.webp" className="img_top d-lg-block d-none" alt="Metaverse Parcel" type="image/webp" />
                                    <img src="assets/image/airdrop/map_m.webp" className="img_top d-lg-none d-block" alt="Parcel" type="image/webp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Metaverse Land / Parcel end */}

                {/* section step start */}
                <section className="section-step" id="howtojoinairdrop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="airdrop_h2 giveaway_h2 mb--30 text-center text-sm-start">
                                    How To Get Airdrop?
                                </h2>
                                <div className="d-flex align-items-center justify-content-between flex-md-row flex-column mb--50">
                                    <p className="font-18 font-100 white-text line-25 w-50 text-center text-md-start">
                                        Just follow these steps to Participate in Airdrop! Participate now!
                                    </p>
                                    <a href="https://market.blockchain.land/" target="_blank" className="btn_agrab btn-contain text-center text-white font-16 d-block mx-auto mx-sm-0 mt-2 mt-md-0" style={{ backgroundImage: 'url("assets/image/airdrop/grab.png")' }}>Airdrop Ended</a>
                                </div>

                                <a href="https://t.me/BlockchainLandOfficial" target="_blank">
                                    <div className="d-flex align-items-center step-bt step-bb step-padd w-100">
                                        <div className="get_airdrop_1">
                                            <p className="font-20 font-300 white-text">Step - 1</p>
                                        </div>
                                        <div className="d-flex align-items-center get_airdrop_2">
                                            <img src="assets/image/airdrop/social-step/telegram.svg" alt="telegram" className="airdrop_social" />
                                            <p className="font-20 font-300 white-text ms-lg-3 ms-2">Join our Telegram Channel</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://discord.com/invite/8hZVg9ACNz" target="_blank">
                                    <div className="d-flex align-items-center step-bb step-padd w-100">
                                        <div className="get_airdrop_1">
                                            <p className="font-20 font-300 white-text">Step - 2</p>
                                        </div>
                                        <div className="d-flex align-items-center get_airdrop_2">
                                            <img src="assets/image/airdrop/social-step/discard.svg" alt="Discord" className="airdrop_social" />
                                            <p className="font-20 font-300 white-text ms-lg-3 ms-2">Join our Discord Server</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://twitter.com/land_blockchain" target="_blank">
                                    <div className="d-flex align-items-center step-bb step-padd w-100">
                                        <div className="get_airdrop_1">
                                            <p className="font-20 font-300 white-text">Step - 3</p>
                                        </div>
                                        <div className="d-flex align-items-center get_airdrop_2">
                                            <img src="assets/image/airdrop/social-step/twitter.svg" alt="twitter" className="airdrop_social" />
                                            <p className="font-20 font-300 white-text ms-lg-3 ms-2">Follow Twitter + Re-tweet</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://www.instagram.com/theblockchainland/" target="_blank">
                                    <div className="d-flex align-items-center step-bb step-padd w-100">
                                        <div className="get_airdrop_1">
                                            <p className="font-20 font-300 white-text">Step - 4</p>
                                        </div>
                                        <div className="d-flex align-items-center get_airdrop_2">
                                            <img src="assets/image/airdrop/social-step/instagram.svg" alt="instagram" className="airdrop_social" />
                                            <p className="font-20 font-300 white-text ms-lg-3 ms-2">Follow Instagram</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://market.blockchain.land/citizenship" target="_blank">
                                    <div className="d-flex align-items-center step-bb step-padd w-100">
                                        <div className="get_airdrop_1">
                                            <p className="font-20 font-300 white-text">Step - 5</p>
                                        </div>
                                        <div className="d-flex align-items-center get_airdrop_2">
                                            <img src="assets/image/fevicon/fevicon.svg" alt="citizenship" className="airdrop_social" />
                                            <p className="font-20 font-300 white-text ms-lg-3 ms-2">Enter your citizenship ID</p>
                                        </div>
                                        <div className="align-items-center d-none d-lg-flex get_airdrop_3">
                                            <img src="assets/image/airdrop/info.svg" alt="icone" className="airdrop_social" />
                                            <p className="font-20 font-300 color_opacity_60 ms-2">To complete the Airdrop and become eligible for upcoming Airdrop</p>
                                        </div>
                                    </div>
                                </a>
                                {/* <a href="/" target="_blank" className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Participate Now</a> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* section step end */}

                <Community />

                {/*Footer Start*/}
                <Footer />
                {/*Footer End*/}
            </div>
        </>
    );
}

export default AirdropNew;