import React from "react";
import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function Roadmap() {
    return (
        <>
            <Helmet>
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                <title>A Metaverse Roadmap | Blockchain Land</title>
                <meta name="title" content="A Metaverse Roadmap | Blockchain Land" />
                <meta name="description" content="Know more about our flexible planning schedule of our key features that Blockchain Land metaverse would like to add in future." />
                <meta name="keywords" content="Blockchain land roadmap, Metaverse Roadmap" />
                <link rel="canonical" href="https://blockchain.land/roadmap" title="roadmap" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blockchain.land/roadmap" />
                <meta property="og:title" content="A Metaverse Roadmap | Blockchain.land" />
                <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blockchain.land/roadmap" />
                <meta property="twitter:title" content="A Metaverse Roadmap | Blockchain.land" />
                <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>

            {/* Header Start */}
            <Header />
            {/* Header End */}

            <div>
                {/* section brand start */}
                <section className="road_map mt-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="text-center">
                                    <h1 className="font-200 white-text cmb_16">
                                        Roadmap
                                    </h1>
                                    <p className="font-18 font-100 white-text mb-2">
                                        These are the key features we wish to add. These are not fixed, planned  <br className="d-none d-md-block" /> or scheduled ideas but rather ideas that need to be further explored.
                                        {/* With the amount of EVM based Blockchain solutions <br className="d-none d-md-block" />
                                        making dominance in the industry, it only makes sense to <br className="d-none d-md-block" />boost that space. */}
                                    </p>
                                    {/* <a href="https://sweepwidget.com/view/57177-er5dnqtl" target="_blank" className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Download Map</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="mapline">
                            <img src="assets/image/roadmap/roadmap.svg" className="d-lg-block d-none" />
                            <img src="assets/image/roadmap/roadmap_tablet.svg" className="d-lg-none d-md-block d-none mx-auto" />
                            <img src="assets/image/roadmap/m_roadmap.svg" className="d-md-none d-block" />

                            {/* <div className="row top_up">
                                <div className="col-lg-4">
                                    <div className="queater road_line">
                                        <span>Q3 - 2022/23</span>
                                        <h4>Stage 1</h4>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Lack of business - Oriented Communities</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Shaping the idea</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Whitepaper release</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Token release on various claim</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Security Audit</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 ps-0">
                                    <div className="queater road_line">
                                        <span>Q1 - 2023</span>
                                        <h4>Stage 2</h4>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Token Legal Opinion</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Marketplace Version 1.0 Release</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Global digital citizenship Release</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Partnership - readyplayer.me</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 ps-0">
                                    <div className="queater road_line road_line_short ">
                                        <div className="right_line">
                                            <span>Q2 - 2023</span>
                                            <h4>Stage 3</h4>
                                            <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Metaverse Map Version</p>
                                            <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> WebGL and VR Based</p>
                                            <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> NFT LaunchPad Release</p>
                                            <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> First Metaverse Event</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-5">
                                    <div className="queater road_line">
                                        <span>Q2 - 2023/24</span>
                                        <h4>Stage 4</h4>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> First Metaverse planet minted on BSC</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Map editor Release</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Metaverse</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Marketplace Version 2.0 <br />( With Enterprise edition )</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Security Audit & Improvement</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 mt-5 ps-0">
                                    <div className="queater road_line">
                                        <span>Q1 - 2024</span>
                                        <h4>Stage 5</h4>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Token Listing on top 10 Exchange</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Business and exchange <br /> release on metaverse</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-5 ps-0">
                                    <div className="queater road_line">
                                        <span>Q2 - 2024</span>
                                        <h4>Stage 6</h4>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Meta - Event Platform Release</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Education Platform Release</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <div className="queater road_line">
                                        <span>Q3 - 2024/25</span>
                                        <h4>Stage 7</h4>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Blockchain Valley LaunchPad</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Virtual Real-estate concept release</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> 3D Metaverse editor release</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 mt-4 ps-0">
                                    <div className="queater road_line">
                                        <span>Q1 - 2025 </span>
                                        <h4>Stage 8</h4>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Release Unity SDK for Developers</p>
                                        <p><img src="/assets/image/roadmap/list.svg" alt="list" className="me-2" /> Decentralized metaverse release</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                {/* section brand end */}
            </div>
            <Community />

            {/*Footer Start*/}
            <Footer />
            {/*Footer End*/}
        </>
    );
}