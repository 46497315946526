import React from 'react'
import { Helmet } from 'react-helmet';
import Community from './component/Community'
import Footer from './component/Footer'
import Header from './component/Header'

export default function TypesOfNft() {
    return (
        <div>
            <Helmet>
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                <title>Types of NFT In Metaverse | Blockchain Land</title>
                <meta name="title" content="Types of NFT In Metaverse | Blockchain Land" />
                <meta name="description"
                    content="Blockchain Land provides a variety of NFTs. Which can be designed , minted, bought, sold, or auctioned in the metaverse by users." />
                <meta name="keywords" content="NFTs, Digital art, Non-fungible token" />
                <link rel="canonical" href="https://blockchain.land/typesofnft" title='Types of nft' />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blockchain.land/typesofnft" />
                <meta property="og:title" content="Types of NFT | Blockchain.land" />
                <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blockchain.land/typesofnft" />
                <meta property="twitter:title" content="Types of NFT | Blockchain.land" />
                <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>

            {/* header start */}
            <Header />
            {/* header end */}

            {/* section types nft start */}
            <section className="section-airdrop bg_types_nft mt--100">
                <div className="container">
                    <div className="row">
                        <div className="col-11 col-sm-8 col-lg-6">
                            <div className="text-start">
                                <h1 className="font--70 font-200 white-text line--80 mb--20">
                                    Variety of NFT in Blockchain Land
                                </h1>
                                <p className="font-18 font-100 white-text line-25 mb-0">
                                    Numerous types of NFTs of the Blockchain Land are mentioned below in prime detail.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section types nft end */}

            {/* metaverse nft start */}
            <section className="mb-200">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-12 col-lg-8 col-xl-6">
                            <div className="text-center">
                                <h2 className="font--70 font-200 white-text line--80 mb---20">
                                    Metaverse NFT
                                </h2>
                                <p className="font-18 font-100 white-text line-25">
                                    Users can design the land of the parcel of the metaverse with Metaverse NFT. Which can be minted, bought, sold, or auctioned in the metaverse.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--55 justify-content-center">
                        <div className="col-lg-3 col-md-6 col-6 mt-lg-0 mt-4">
                            <div>
                                <img className="w-100" src="/assets/image/typesnft/nft_home.webp" alt="nft-home" type="image/webp" title='NFT' />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 mt-lg-0 mt-4">
                            <div>
                                <img className="w-100" src="/assets/image/typesnft/nft_tree.webp" alt="nft-tree" type="image/webp" title='NFT' />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 mt-lg-0 mt-4">
                            <div>
                                <img className="w-100" src="/assets/image/typesnft/nft_rock.webp" alt="nft-rock" type="image/webp" title='NFT' />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 mt-lg-0 mt-4">
                            <div>
                                <img className="w-100" src="/assets/image/typesnft/nft_car.webp" alt="nft-car" type="image/webp" title='NFT' />
                            </div>
                        </div>
                        <div className='col-lg-12 text-center mt_60'>
                            <a href="https://market.blockchain.land/mint" target="_blank" className="btn-head btn-contain text-center text-white d-inline-block font-16 font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>And Many More</a>
                        </div>
                    </div>
                </div>
            </section>
            {/* metaverse nft end */}

            {/* vally nft start */}
            <section className="pb---55 position-relative">
                <div className="container">
                    <div className='vally_mapping'>
                        <img className="w-100 d-sm-block d-none" src="/assets/image/typesnft/vally_map.svg" alt="vally map" title='NFT' />
                        {/* <img className="w-100 d-lg-none d-sm-block d-none" src="/assets/image/typesnft/vally_map_tab.svg" alt="vally map" /> */}
                        <img className="w-100 d-sm-none d-block" src="/assets/image/typesnft/vally_map_mobile.svg" alt="vally map" title='NFT' />
                        <div className="row align-items-center bg_nft_position">
                            <div className="col-lg-8 col-sm-8">
                                <div className="spacemap_card text-center text-sm-start">
                                    <h2 className="font--70 font-200 white-text line--80 mb---20">Valley Map</h2>
                                    <p className="btn-small btn-contain text-center d-inline-block font-16 font-200 mb---30" style={{ backgroundImage: 'url("/assets/image/btn-bg/btn_squre.webp")' }}>10 X 10</p>
                                    <p className="font-18 font-100 white-text mb---30 line-25 text-sm-start text-center">
                                        Whichever elements are used by users to design a valley map will be presented as NFT first.
                                    </p>
                                    <div className='d-flex btn_height'>
                                        <a href="https://market.blockchain.land/mint" target="_blank" className="btn-head btn-contain text-center text-dark font-16 d-block font-200 mx-auto mx-sm-0" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Mint Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 offset-lg-1 col-sm-4">
                                <img src="assets/image/typesnft/map.svg" alt="Valley Map" title='Valley Map' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='shadow_blue top_shadow'></div>
            </section>

            <section className="position-relative mb-200 mt-5 mt-md-0">
                <div className="container">
                    <div className='vally_mapping'>
                        <img className="w-100 d-sm-block d-none" src="/assets/image/typesnft/country_map.svg" alt="vally map" title='MAP' />
                        {/* <img className="w-100 d-lg-none d-sm-block d-none" src="/assets/image/typesnft/country_map_tab.svg" alt="vally map" /> */}
                        <img className="w-100 d-sm-none d-block" src="/assets/image/typesnft/vally_map_mobile.svg" alt="vally map" title='MAP' />
                        <div className="row align-items-center bg_nft_position">
                            <div className="col-lg-3 col-sm-4 order-lg-1 order-2 ">
                                <img src="assets/image/typesnft/country.svg" alt="Country Map" title='Country Map' />
                            </div>
                            <div className="col-lg-8 offset-lg-1 col-sm-8 order-lg-2 order-1">
                                <div className="spacemap_card text-center text-sm-start">
                                    <h2 className="font--70 font-200 white-text line--80 mb---20">Country Map</h2>
                                    <p className="btn-small btn-contain text-center d-inline-block font-16 font-200 mb---30" style={{ backgroundImage: 'url("/assets/image/btn-bg/btn_squre.webp")' }}>25 X 25</p>
                                    <p className="font-18 font-100 white-text mb---30 line-25 text-sm-start text-center">
                                        Whichever elements are used by users to design a country map will be presented as NFT first.
                                    </p>
                                    <div className='d-flex btn_height'>
                                        <a href="https://market.blockchain.land/mint" target="_blank" className="btn-head btn-contain text-dark text-center font-16 d-block font-200 mx-auto mx-sm-0" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Mint Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='shadow_blue bottom_shadow'></div>
            </section>
            {/* vally nft end */}

            {/* land material start */}
            <section className="mb-200 section-mintparcel bg-cover mt-235">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-8 col-xl-6">
                            <div className="text-center text-lg-start">
                                <h2 className="font--70 font-200 white-text line--80 mb---20">
                                    Land Material
                                </h2>
                                <p className="font-18 font-100 white-text line-25">
                                    The material required to design a metaverse land can be minted through this as NFT.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mt---55">
                    <div className="col-lg-12 px-0">
                        <marquee behavior="alternate" direction="left" scrollamount={15} onmouseover="this.stop();" onmouseout="this.start();">
                            <div className="token-left">
                                <img src="/assets/image/airdrop/cube/cube1.webp" alt="land" type="image/webp" title='land' />
                                <img src="/assets/image/airdrop/cube/cube2.webp" alt="land" type="image/webp" title='land' />
                                <img src="/assets/image/airdrop/cube/cube3.webp" alt="land" type="image/webp" title='land' />
                                <img src="/assets/image/airdrop/cube/cube4.webp" alt="land" type="image/webp" title='land' />
                                <img src="/assets/image/airdrop/cube/cube5.webp" alt="land" type="image/webp" title='land' />
                                <img src="/assets/image/airdrop/cube/cube6.webp" alt="land" type="image/webp" title='land' />
                                <img src="/assets/image/airdrop/cube/cube7.webp" alt="land" type="image/webp" title='land' />
                            </div>
                        </marquee>
                    </div>
                </div>
            </section>
            {/* land material end */}

            {/* character start */}
            <section className="mb-200 section-mintparcel bg-cover">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-12 col-lg-8 col-xl-6">
                            <div className="text-center">
                                <h2 className="font--70 font-200 white-text line--80 mb---20">
                                    Character
                                </h2>
                                <p className="font-18 font-100 white-text line-25 pb---55">
                                    Users can mint their own designed characters as NFTs in Blockchain Land.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-12 px-lg-0">
                            <img className="w-100" src="/assets/image/typesnft/characters.webp" alt="characters" type="image/webp" title='characters' />
                        </div>
                    </div>
                </div>
            </section>
            {/* character end */}

            {/* community start */}
            <Community />
            {/* community end */}

            {/* footer start */}
            <Footer />
            {/* footer end */}
        </div>
    )
}