import React from "react";
import { Helmet } from "react-helmet";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

        <title>Privacy Policy | Blockchain Land</title>
        <meta name="title" content="Privacy Policy | Blockchain Land" />
        <meta name="description" content="The privacy policy of Blockchain Land underlines the importance of ethics and important guidelines. This privacy policy aims to determine how the information provided by you will be used by our organization only." />
        <meta name="keywords" content="Blockchain land privacy policy" />
        <link rel="canonical" href="https://blockchain.land/privacy_policy" title="privacy policy" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blockchain.land/privacy_policy" />
        <meta property="og:title" content="Privacy Policy | Blockchain.land" />
        <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
        <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://blockchain.land/privacy_policy" />
        <meta property="twitter:title" content="Privacy Policy | Blockchain.land" />
        <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

        <meta name="reply-to" content="blockchain.land" />
        <meta name="robots" content="index, Follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="revisit-after" content="7 day" />
        <meta name="rating" content="GENERAL" />
        <meta name="language" content="English" />
        <meta name="distribution" content="Global" />
        <meta name="copyright" content="blockchain.land" />
        <link rel="publisher" href="https://blockchain.land/" />
        <link rel="author" href="https://blockchain.land/" />
      </Helmet>

      {/* Header Start */}
      <Header />
      {/* Header End */}
      {/* section privacy policy start */}
      <section>
        <div className="container">
          <div className="policy_head">
            <h1 className="text-center mt--200 text-white">
              Privacy Policy
            </h1>
          </div>
          <div className="policy_content">
            <p>
              The privacy policy of Blockchain Land underlines the importance of ethics and important guidelines. This privacy policy aims to determine how the information provided by you will be used by our organization. The privacy policy also inscribes that by using this site or tool you agree that we can collect, process, and use your information as and when required. The guidelines and the policies mentioned here are just applicable to this site and not any other products or website. Reading the entire privacy policy is highly recommended while providing your information to this or any other website.
            </p>
            <p>
              Here is the entire documentation of how the personal data provided by you will be shared, used, and protected. What are the options that you have to limit or allow the usage of your data and how you can contact us?
            </p>
            <p>
              An important point to be highlighted is that the site and the tools are entitled to evolve and modify with time. The users will be timely notified about these modifications. If there is a possibility of a substantial change, a notification will be placed on the Homepage for higher visibility.
            </p>
            <p>
              This is to be notified that in some cases, third parties might place cookies or pixels, please note that this Privacy policy does not cover the use of cookies or pixels by such third parties. You can however always change your browser settings to automatically remove these cookies.
            </p>
            <p>The privacy policy should be read along with the terms of use. By accessing the tools, you are consenting to your personal information and agree to the practices mentioned in the privacy policy.
            </p>
            <p>Your use of Blockchain Land and any other personal information you provide is subject to the terms of use and privacy policy which is subject to change from time to time.
            </p>
            <p>Any discrepancy, query, or feedback can be mailed to us at
            </p>
            <div>
              <ul className="newli">
                <li>
                  <h2>What is the information collected by the site or tool?
                  </h2>
                  <p>The personal information collected from you may include the followings-
                  </p>
                  <ul>
                    <li>
                      <p>Contact details like Name, Contact information, Billing Address, your Ether address along with your email address. etc.</p>
                    </li>
                    <li><p>
                      Network information including Transaction details and other information like types of devices used, access times, hardware models operating system, the model used, etc.
                    </p></li>
                    <li>
                      <p>Blockchain Land requires high-end browser permissions that can also give access to more personal details. However, these permissions are limited to specific purposes.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2>How is your Personal information used?
                  </h2>
                  <ul>
                    <li><p>
                      Blockchain Land requires full browser permissions to function properly that can allow permissions for extracting additional personal information. This permission is used for some critical technical access, it does not access any unnecessary information which is not required and most importantly, no information is passed on to the third party.
                    </p></li>
                    <li>
                      <p>Like all the information transferred online, servers can receive information through your interaction with the browser, and the information can go beyond the access of IP Addresses.
                      </p>
                    </li>
                    <li>
                      <p>Every public blockchain provides transparency in all its transactions. The company or the website won't be liable to prevent, hide, show or manage the transactions showcased in the public blockchains.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2>What Do we do with your information?</h2>
                  <ul>
                    <li><p>To personalize your experience of viewing the site and the tools, for consumers' ease and satisfaction.</p></li>
                    <li><p>To alert you with updates, security notifications, confirmations, and providing all sorts of consumer support.</p></li>
                    <li> <p>To update you with regards to new arrivals in products and services and also to
                      provide assistance to your queries and answer your questions.  </p>
                    </li>
                    <li><p>To stay up to date with the trends and recent interests of consumers. </p></li>
                    <li><p>For the betterment of the site and improvement in tools. </p></li>
                    <li>
                      <p>The company reserves the right to disclose your personal information to agents, business providers, and other third parties. The agreements with such service providers are such that it limits the use of your data and how they can use or process it as long as they can secure most of that data without hampering your privacy.
                      </p>
                    </li>
                    <li>
                      <p>On occasions wherein the company might get involved in a merger or acquisition, bankruptcy, insolvency, reorganization or any applications of laws or equitable principles that might affect the creditor's right in any case or there is a change of power, the disclosure of your information could be required to another party.  </p>
                    </li>
                    <li>
                      <p>Your personal information can be disclosed to the third party when any improvement of the site or tools is required. Whenever the company requires any analytics report it can save the company from any legal discrepancy or maybe is helpful in the company’s growth in some way or another.   </p>
                    </li>
                    <li>
                      <p>The company also reserves the right to release your information on trusted grounds if it is necessary for the enforcement of Terms Of Use. It can also be revealed if there is a requirement for the protection of organizations from allegations by third parties which are meant to be responded to by law or officially. It is also meant to protect the security, integrity, property, and rights of the site or tools.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2>Cookies</h2>
                  <ul>
                    <li>
                      <p>The site and tools do not use cookies as of now, but it is subject to change in the future.
                        In this scenario, the privacy policy will be timely updated. </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2>Security of Information</h2>
                  <ul>
                    <li> <p>The Company has enough security measures to protect your personal information. Limited access and encryption provide extra security to your personal information.</p>
                    </li>
                  </ul>
                </li>
                <li><h2>Changes and Updates</h2>
                  <ul>
                    <li><p>The privacy policy is revised regularly and can be reflected in
                      ‘Last Update Posted’. Please keep visiting the Privacy policy page to stay updated. </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2>Privacy Rights</h2>
                  <ul>
                    <li><p>To obtain a copy of your data and also the awareness of how it is being processed.</p></li>
                    <li><p>Right to make amendments to the incorrect information.</p></li>
                    <li><p>To erase or restrict the information that you think is unnecessary or requires restriction.</p></li>
                    <li><p>To challenge the processing of personal data if there is a possibility of personal threat. </p></li>
                    <li><p>To prevent direct marketing from sites and tools.</p></li>
                    <li><p>To withdraw your consent with regards to the processing of your data.</p></li>
                    <li><p>To object to the decisions based on the wrong processing of your personal information.</p></li>
                    <li><p>You have the right to file a complaint if you feel like your privacy is threatened in any way. </p></li>
                    <li><p>If you have any discrepancy with regards to your privacy rights. Please mail at.  </p></li>
                  </ul>
                </li>
                <li>
                  <h2>Your Choice</h2>
                  <ul>
                    <li><p>The personal information provided by you is processed concerning this privacy policy and you can not modify or change the system as to how the information is used.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2>Non- Collectible Information
                  </h2>
                  <ul>
                    <li>
                      <p>
                        Apart from the above-mentioned information pieces, any other set of information about you can't be collected, unless you provide it directly to the company as a written form, feedback, or communication via social media, third party, or directly through the sites or tools.
                      </p>
                    </li>
                    <li>
                      <p>Your Public Ethereum Address cant be collected but the transactions on the site are directly recorded on the public Ethereum blockchain which is publicly visible as soon as the transaction is confirmed on the Ethereum blockchain.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section >
      {/* section privacy policy end */}
      {/*Footer Start*/}
      <Footer />
      {/*Footer End*/}
    </>
  );
}