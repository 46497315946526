import React from "react";
import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function AirdropGiveaway() {
    return (
        <>
            <div>
                <Helmet>
                    <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                    <title>Airdrop Giveaway | Blockchain Land</title>
                    <meta name="title" content="Airdrop Giveaway | Blockchain Land" />
                    <meta name="description" content="The exclusive NFT Airdrop for blockchain.land Metaverse community. Participate Now into the Exclusive Airdrop .Click here to know more about Airdrop." />
                    <meta name="keywords" content="Non-Fungible token" />
                    <link rel="canonical" href="https://blockchain.land/airdropgiveaway" title="airdropgiveaway" />

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://blockchain.land/airdropgiveaway" />
                    <meta property="og:title" content="Airdrop Giveaway | Blockchain.land" />
                    <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                    <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://blockchain.land/airdropgiveaway" />
                    <meta property="twitter:title" content="Airdrop Giveaway | Blockchain.land" />
                    <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                    <meta name="reply-to" content="blockchain.land" />
                    <meta name="robots" content="index, Follow" />
                    <meta name="googlebot" content="index, follow" />
                    <meta name="revisit-after" content="7 day" />
                    <meta name="rating" content="GENERAL" />
                    <meta name="language" content="English" />
                    <meta name="distribution" content="Global" />
                    <meta name="copyright" content="blockchain.land" />
                    <link rel="publisher" href="https://blockchain.land/" />
                    <link rel="author" href="https://blockchain.land/" />
                </Helmet>

                {/* Header Start */}
                <Header />
                {/* Header End */}
                {/* section airdrop start */}
                <section className="section-airdrop bg-airdrop mt--100 pt--400 res_height">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-lg-6">
                                <div className="text-center text-sm-start">
                                    <h1 className="white-text font-200 mb--33">
                                        Airdrop <br className="d-lg-block d-none" />
                                        Giveaway
                                    </h1>
                                    <p className="font-18 font-100 white-text mb--40">With the amount of EVM based Blockchain solutions making dominance in the industry, it only makes sense to boost that space.</p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="know_line white-text">
                                    <div className="d-flex justify-content-between w-100">
                                        <p className="font-300 font-18">NFT Credit</p>
                                        <p className="font-300 font-18">2 NFT Blocks</p>
                                    </div>
                                </div>
                                <div className="know_line white-text step-bb">
                                    <div className="d-flex justify-content-between w-100">
                                        <p className="font-300 font-18">Wallet Address</p>
                                        <p className="text-blue text-decoration-underline font-18 font-300 new_word">0xdi489vtmj039wckty7q3v0mt67083qcl7xkqv5mq</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section airdrop end */}
                {/* section token start */}
                <section className="section-token mb-100 pt-5 spacemobile">
                    <div className="container">
                        <span className="font-20 font-300 text-blue mb--66 d-block mt-lg-5 mt-xl-0">Non-Fungible token</span>
                    </div>
                    <marquee behavior="alternate" direction="left" scrollamount={15} onmouseover="this.stop();" onmouseout="this.start();">
                        <div className="token-left mb--33">
                            <img src="/assets/image/airdrop/cube/cube1.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube2.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube3.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube4.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube5.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube6.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube7.webp" alt="token" type="image/webp" />
                        </div>
                    </marquee>
                    <marquee behavior="alternate" direction="right" scrollamount={15} onmouseover="this.stop();" onmouseout="this.start();">
                        <div className="token-left">
                            <img src="/assets/image/airdrop/cube/cube8.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube9.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube10.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube11.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube12.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube13.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube14.webp" alt="token" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube15.webp" alt="token" type="image/webp" />
                        </div>
                    </marquee>
                </section>
                {/* section token end */}
                <Community />
            </div >
            <Footer />
        </>
    );
}