import React from "react";
import { Helmet } from "react-helmet";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function AdvisorAgreement() {
  return (
    <>
      <Helmet>
        <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

        <title>Advisor Agreement | Blockchain Land</title>
        <meta name="title" content="Advisor Agreement | Blockchain Land" />
        <meta name="keywords" content="Advisor Agreement" />
        <link rel="canonical" href="https://blockchain.land/advisoragreement" title="advisoragreement" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blockchain.land/advisoragreement" />
        <meta property="og:title" content="Advisor Agreement | Blockchain.land" />
        <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
        <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://blockchain.land/advisoragreement" />
        <meta property="twitter:title" content="Advisor Agreement | Blockchain.land" />
        <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

        <meta name="reply-to" content="blockchain.land" />
        <meta name="robots" content="index, Follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="revisit-after" content="7 day" />
        <meta name="rating" content="GENERAL" />
        <meta name="language" content="English" />
        <meta name="distribution" content="Global" />
        <meta name="copyright" content="blockchain.land" />
        <link rel="publisher" href="https://blockchain.land/" />
        <link rel="author" href="https://blockchain.land/" />
      </Helmet>

      {/* Header Start */}
      <Header />
      {/* Header End */}

      {/* section privacy policy start */}
      <section>
        <div className="container">
          <div className="policy_head">
            <h1 className="text-center mt--200 text-white">
              Advisor Agreement
            </h1>
          </div>
          <div className="policy_content">
            <p>In consideration of the foregoing and the mutual promises and covenants contained inthis Agreement, the Company and Advisor agree to the following:
            </p>
            <ul className="newli">
              <li>
                <h2>ENGAGEMENT OF SERVICES; COMPENSATION</h2>
                <ul>
                  <li>
                    <p>The Company hereby appoints Advisor as a member of the Advisory Board to advise the
                      Company.Advisor, pursuant to the provisions of this Agreement, agrees to serve as a member
                      of the Advisory Board. Such services will include discussions with Company management and
                      may include attendance at Advisory Board Meetings; additionally, Advisor shall provide such
                      other clinical and technical advice as the parties may mutually agree (all the foregoing shall
                      collectively be referred to herein as the “Services”).The Company recognises that Advisor
                      may from time to time be unable to attend Advisory Board meetings due to Advisor’s other
                      obligations. Advisor will perform the foregoing Services for the Company in good faith and to
                      the best of Advisor’s ability.
                    </p>
                  </li>
                  <li>
                    <p>The Advisor at their own discretion may participate in promotional/marketing/growth, and
                      fundraising activities and also help to grow the reach, exposure, influence and overall value of
                      the Company through strategic collaborations and partnerships after seeking approval from
                      the company’s COO or CEO.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>ADDITIONAL ACTIVITIES</h2>
                <ul>
                  <li>
                    <p>During the period in which Advisor provides Services to the Company under this Agreement
                      (the “Advising Period”),Advisor will not directly or indirectly (whether for compensation or
                      without compensation) engage in or provide consulting services to any entities conducting
                      any business activity directly relating to products, processes or techniques related to the
                      Company business (the “Field”); provided, however, that the foregoing shall not prevent
                      Advisor from engaging in any academic research, teaching or related activity in the Field.
                    </p>
                  </li>
                  <li>
                    <p>An Advisor can also be an Investor in the Company. After the initial lock-in period, if the
                      Advisor wants to liquidate their released tokens, they shall give the Company the right of first
                      refusal and give 30 days for the company to manage the Buy-Back at the current established
                      value. The company reserves the right to accept the buy-back or refuse it within 30 days.
                      However, if the Company refuses the Buy-Back, the Advisor can sell the tokens responsibly so
                      as to avoid a token value depreciation.
                    </p>
                  </li>
                  <li>
                    <p>During the Advising Period, and one year thereafter, Advisor will not, directly or indirectly
                      (whether for compensation or without compensation) recruit, solicit or induce, or attempt to
                      induce, any employee or contractor of the Company to terminate their employment or
                      contractual relationship with the Company.
                    </p>
                  </li>
                  <li>
                    <p>The restrictions set forth in Sections 2.1, 2.2 and 2.3 are considered by the parties to be
                      reasonable for the purposes of protecting the business of the Company. However, if any such
                      restriction is found by any court of competent jurisdiction tobe unenforceable because its
                      duration, range of activities or geographic area is tooextensive, Sections 2.1, 2.2 and 2.3 shall
                      be interpreted to extend for the maximum period of time, range of activities or geographic
                      area enforceable by law.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>REVENUE SHARING</h2>
                <ul>
                  <li>
                    <p>
                      The Advisor is an active member of the Web3 space and therefore has the opportunity to
                      bring in several projects seeking Metaverse or Blockchain Integrations.
                    </p>
                  </li>
                  <li>
                    <p>
                      It is so agreed between both parties that the net profit from the business brought in by the
                      Advisor shall be split in the ratio of 80:20.
                    </p>
                  </li>
                  <li>
                    <p>
                      80% will be kept with the company and 20% will be shared with the Advisor on their BCL
                      Wallet account within 7 days of realisation of funds.
                    </p>
                  </li>
                  <li>
                    <p>
                      Additionally, for investments brought in by the consultant, the Company agrees to pay a 5% commission on investments higher than USD 2.5, 4% on investments between USD 1-2.49Mn and 3% of USD 100K-999K to the consultant, subject to applicable withholding taxes.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>COMPANY’S RIGHTS</h2>
                <ul>
                  <li>
                    <p>During the term of this Agreement,Advisor may receive and otherwise be exposed to
                      information regarding the patents, trade secrets, technology and business of the Company.
                      Advisor, therefore, agrees that all Proprietary Information (as defined in Section 3.2),
                      whether presently existing or developedin the future, whether or not patentable or
                      registrable under copyright law, shall bethe sole property of the Company and its assigns and
                      that the Company and its assigns shall be the sole owner of intellectual property and other
                      rights in connection with such Proprietary Information.
                    </p>
                  </li>
                  <li>
                    <p>“Proprietary Information” includes, without limitation, any information created, discovered,
                      developed, or otherwise known to the Company, all inventions, worksof authorship, trade
                      secrets, business plans, confidential knowledge, data or any other proprietary information of
                      the Company and any information assigned or otherwise conveyed to the Company by
                      another entity.
                    </p>
                  </li>
                  <li>
                    <p>By way of illustration, but not limitation, Proprietary Information specifically includes
                      inventions, developments, designs, applications, improvements, tradesecrets, formulae, ideas,
                      know-how, methods or processes, discoveries, techniquesand data (hereinafter collectively
                      referred to as“Inventions”); information regarding plans for research, development, new
                      products, marketing and selling business plans, budgets and unpublished financial statements,
                      licenses, prices and costs, suppliers and customers; and information regarding the skills and
                      compensation of employees of the Company.
                    </p>
                  </li>
                  <li>
                    <p>During the term of this Agreement and after its termination, Advisor will keep in confidence
                      and trust all Proprietary Information and shall not reproduce, use or disclose any Proprietary
                      Information or anything related to such informationwithout the written consent of the
                      Company, except as required in performing the Services.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>RECOGNITION OF COMPANY’S RIGHTS</h2>
                <ul>
                  <li>
                    <p>Advisor agrees not to reproduce Proprietary Information in any format, except as necessary
                      for Advisor’s performance of Services.
                    </p>
                  </li>
                  <li>
                    <p>During the term of this Agreement and after its termination, Advisor will keep in confidence
                      and trust all Proprietary Information. Advisor shall not use or discloseto any third party,
                      Proprietary Information or anything related to such informationwithout the prior written
                      consent of the Company unless such actions are required in the ordinary course of
                      performing Services for the Company pursuant to this Agreement.
                    </p>
                  </li>
                  <li>
                    <p>Advisor agrees not to disclose, without the prior written consent of the Company the terms
                      and conditions under which Advisor will provide Services under this Agreement. The Advisor
                      may disclose the fact that Advisor serves on the Advisory Board.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>NONDISCLOSURE OF THIRD-PARTY INFORMATION</h2>
                <ul>
                  <li>
                    <p>
                      Advisor understands that the Company has received, and in the future will receive, information from third
                      parties that is confidential or proprietary (“Third-PartyInformation”). Advisor recognises the Company’s duty to
                      maintain the confidentiality ofsuch information. During the term of this Agreement and thereafter, Advisor will
                      holdThird-Party Information in the strictest confidence and will not disclose or use Third-Party Information
                      except as permitted by the agreement between the Company and such third party, and as necessary for
                      performing Services under this Agreement, unlessexpressly authorised to act otherwise by a written statement
                      of an officer of the Company
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>NO CONFLICTING OBLIGATION; PUBLICATION</h2>
                <ul>
                  <li>
                    <p>
                      Advisor hereby certifies that Advisor’s performance of all of the terms of this Agreement and
                      the Services will not breach or conflict with any agreement to keep the proprietary
                      information of another entity in confidence.
                    </p>
                  </li>
                  <li>
                    <p>
                      Advisor certifies that Advisor has not and will not enter into any agreement either written or
                      oral, in conflict with this Agreement. Absent a conflict of interest,Advisor is free to provide
                      services to any other entity during the performance of this Agreement.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>NO IMPROPER USE OF MATERIALS</h2>
                <ul>
                  <li>
                    <p>
                      Advisor agrees not to bring to the Company or to use in the performance of Services any materials or
                      documents of a present or former employer of Advisor, or Advisor’s employees, or any materials or documents
                      obtained by Advisor under an obligation of confidentiality imposed because of another of Advisor’s contracting
                      relationships unless such materials or documents are generally available to the public or Advisor has
                      authorisation from such present or former employer or client for the possession and unrestricted use of such
                      materials. Advisor understands that Advisor is not to breach any obligation of confidentiality that Advisor has
                      to present or former employers and agreesto fulfil all such obligations during the term of this Agreement.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>ADVISOR REPRESENTATION</h2>
                <ul>
                  <li>
                    <p>
                      The Company and Advisor represent and agrees that Advisor is an independent contractor
                      and not an agent or employee of the Company. Advisor has no authority to act on behalf of
                      the Company or obligate the Company by contract or otherwise.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Advisor represents, warrants and covenants that the Advisor has the full power and
                      authority to enter, execute and perform this Agreement, and the execution and performance
                      of this Agreement by the Advisor shall not violate any law or agreement to which the Advisor
                      is a party or by which it is otherwise bound.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>TERM ANDTERMINATION</h2>
                <ul>
                  <li>
                    <p>
                      Either party may terminate this Agreement at will upon thirty (30) days written notice to the other.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>EFFECT OFTERMINATION</h2>
                <ul>
                  <li>
                    <p>
                      Upon the expiration of this Agreement, each party shall be released from all obligations and
                      liabilities to the other occurring or arising after the date of such termination, except that any
                      termination of this Agreement shall not relieve Advisor of Advisor’s obligations under Sections
                      2.2, 3, 4, and 5 hereof.
                    </p>
                  </li>
                  <li>
                    <p>
                      Upon any termination of this Agreement pursuant to Section 9, Advisor shall promptly deliver
                      to the Company all documents and other materials of any nature in Advisor’s possession
                      pertaining to the Services, together with all documents and other items containing or
                      pertaining to any Proprietary Information. Advisor shall not retain copies of any such
                      documents or other materials after the termination of this Agreement.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>GOVERNING LAW AND SEVERABILITY</h2>
                <ul>
                  <li>
                    <p>
                      This Agreement shall be governed by the laws of Dubai, UAE. If one or more of the provisions in this
                      Agreement are deemed unenforceable by law, then such provision will be deemed stricken from this
                      Agreement and the remaining provisions will continue in full force and effect
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>INDEMNIFICATION</h2>
                <ul>
                  <li>
                    <p>
                      The Advisor shall indemnify, defend and hold the Company, its subsidiaries, affiliates, and their
                      respective officers, directors, agents and employees harmless from and against all claims,
                      damages, liabilities, costs, losses and expenses, including reasonable attorneys’ fees and
                      expenses incurred by such party owing to a breach by the Advisor of his/her representations,
                      warranties, undertakings or obligations under this Agreement or arising as a result of any
                      negligence or fraud by the Advisor
                    </p>
                  </li>
                  <li>
                    <p>
                      In no event shall the Company be liable for any punitive or special damages whatsoever,
                      including without limitation, damages for loss of business profits, business interruption, loss of
                      business information, and the like, arising out of this agreement, even if such party has been
                      advised of the possibility of such damages. The parties understand and agree that the internet
                      is inherently unsafe; consequently, under no circumstances will either party be responsible for
                      any damage, loss, or injury resulting from (a) hacking, tampering, or other unauthorised access
                      or use of the platform not caused by the gross negligence or wilful misconduct of such party;
                      (b) any interruption or cessation of the services; (c) any software bugs, viruses, trojan horses,
                      or other harmful code that may be transmitted to or through the platform; (d) errors,
                      inaccuracies, or omissions of information, or any losses incurred as a result of, resulting from
                      the use of the platform; and/or (e) information that is transmitted using the platform that is
                      defamatory, offensive, or illegal.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>COMPLETE UNDERSTANDING; MODIFICATION</h2>
                <ul>
                  <li>
                    <p>
                      This Agreement constitutes the final, exclusive and complete understanding and agreement of the parties
                      hereto and supersedes all prior understandings and agreements.This Agreement is entered into without
                      reliance upon any representation, whether oral or written, not stated herein. Any waiver, modification or
                      amendment of any provision of this Agreement shall be effective only if in writing and signed by a Company
                      officer
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>NOTICES</h2>
                <ul>
                  <li>
                    <p>
                      Any notices required or permitted hereunder shall be given to the appropriate party at the address specified
                      below or at such other address as the party shall specify in writing. Such notice shall be deemed given upon
                      personal delivery to the appropriate address or sent by certified or registered mail, three days after the date of
                      mailing
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* section privacy policy end */}
      {/*Footer Start*/}
      <Footer />
      {/*Footer End*/}
    </>
  );
}