import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Community from './component/Community';
import Footer from './component/Footer';
import Header from './component/Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import { ROOT_URL } from './constants';
import { useEffect } from 'react';
import { shortenAddress } from './utils';

export default function Getcitizenship() {
  const [imageClicked, setImageClicked] = useState({
    first: false,
    second: false,
    ground: true,
    third: false,
  });
  const onClickHandler = order => {
    const resetImages = {
      first: false,
      second: false,
      ground: false,
      third: false,
    };
    setImageClicked({
      ...resetImages,
      [order]: true,
    });
  };

  const options = {
    loop: false,
    margin: 30,
    nav: true,
    navText: [
      `<img src="/assets/image/citizenship/next.svg" />`,
      `<img src="/assets/image/citizenship/prev.svg" />`,
    ],
    dots: false,
    autoplayHoverPause: true,
    navClass: ['owl-next', 'owl-prev'],
    navSpeed: 1500,
    navElement: 'div',
    navContainerClass: 'owl-nav',
    lazyLoad: true,
    responsiveClass: true,
    autoplay: true,
    autoplaySpeed: 1200,
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
        margin: 10,
      },
      768: {
        items: 2,
        slideBy: 1,
      },
      1280: {
        items: 4,
        slideBy: 2,
      },
      1440: {
        items: 4,
        slideBy: 2,
      },
      1920: {
        items: 5,
        slideBy: 2,
      },
    },
  };

  // get recent citizen
  const [recentCitizen, setRecentCitizen] = useState(null);
  const GetRecentCitizen = async () => {
    try {
      let citizen = await axios.post(`${ROOT_URL}/account/recent_citizenship`);
      setRecentCitizen(citizen.data.length ? citizen.data : []);
    } catch (error) {
      setRecentCitizen([]);
      console.log('error get recent citizen');
    }
  };
  useEffect(() => {
    GetRecentCitizen();
  }, []);

  return (
    <>
      <Helmet>
        <meta
          property="twitter:image"
          itemProp="image"
          content="https://blockchain.land/assets/image/meta_img/citizen_meta.png"
        />

        <title>Get Metaverse Virtual Citizenship | Blockchain Land</title>
        <meta
          name="title"
          content="Get Metaverse Virtual Citizenship | Blockchain Land"
        />
        <meta
          name="description"
          content="Blockchain Land citizenship is the key to this new virtual world of opportunities where citizens with SBT (Soul Bound Token) can buy, sell, create, and participate in metaverse event."
        />
        <meta
          name="keywords"
          content="Digital Citizenship, Metaverse citizenship, Global Digital Citizenship, Meta-Citizenship, SoulBound Citizenship"
        />
        <link
          rel="canonical"
          href="https://blockchain.land/getcitizenship"
          title="getcitizenship"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://blockchain.land/getcitizenship"
        />
        <meta
          property="og:title"
          content="Get Metaverse Virtual Citizenship | Blockchain Land"
        />
        <meta
          property="og:description"
          content="Blockchain Land citizenship is the key to this new virtual world of opportunities where citizens with SBT (Soul Bound Token) can buy, sell, create, and participate in metaverse event."
        />
        <meta
          property="og:image"
          itemProp="image"
          content="https://blockchain.land/assets/image/meta_img/citizen_meta.png"
        />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://blockchain.land/getcitizenship"
        />
        <meta
          property="twitter:title"
          content="Get Metaverse Virtual Citizenship | Blockchain Land"
        />
        <meta
          property="twitter:description"
          content="Blockchain Land citizenship is the key to this new virtual world of opportunities where citizens with SBT (Soul Bound Token) can buy, sell, create, and participate in metaverse event."
        />

        <meta name="reply-to" content="blockchain.land" />
        <meta name="robots" content="index, Follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="revisit-after" content="7 day" />
        <meta name="rating" content="GENERAL" />
        <meta name="language" content="English" />
        <meta name="distribution" content="Global" />
        <meta name="copyright" content="blockchain.land" />
        <link rel="publisher" href="https://blockchain.land/" />
        <link rel="author" href="https://blockchain.land/" />
      </Helmet>

      <Header />
      {/* section metaverse start */}
      <section className="section-airdrop bg_city mt--100 pt--100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 text-lg-start text-center">
              <h1 className="font--70 font-200 white-text line--80 mb--20">
                Citizenship
              </h1>
              <p className="font-18 font-100 white-text line-25 mb--40">
                Blockchain Land wants to ensure that only real people are able
                to enjoy the Metaverse and avoid overcrowding by enabling
                Citizenship that will grant users complete access to all the
                services of Blockchain Land. This includes not just owning
                assets but complete comprehensive support from the team.
              </p>
              <a
                href="https://market.blockchain.land/citizenship"
                target="_blank"
                className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200"
                style={{
                  backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")',
                }}
              >
                Get it Now
              </a>
            </div>
            <div className="col-lg-6 mt-md-5 mt-4 mt-lg-0 text-lg-start text-center">
              <img
                src="/assets/image/citizenship/card.webp"
                alt="Metaverse Virtual Citizenship - Blockchain Land"
                type="image/webp"
              />
            </div>
          </div>
        </div>
      </section>
      {/* section metaverse end */}

      {/* section Digital Global start */}
      <section className="section-global">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 order-lg-1 order-2 text-md-center">
              <img
                src="assets/image/getcitizenship/sbt.webp"
                alt="Blockchain Land Soul Bound Token"
                type="image/webp"
              />
            </div>
            <div className="col-lg-6 col-md-12 mb-lg-0 mb-5 order-lg-2 order-1">
              <div className="global-right text-center text-lg-start">
                <h2 className="font--70 font-200 white-text line--80 mb---20 mb-0 mb-md-2">
                  SBT
                </h2>
                <p
                  className="btn-head btn-contain font-16 my-0 text-center text-blue d-inline-block font-200"
                  style={{
                    backgroundImage:
                      'url("/assets/image/getcitizenship/btn.svg")',
                  }}
                >
                  Soul Bound Token
                </p>
                <p className="font-18 font-100 white-text mb---40 line-25 my-md-4 my-1">
                  Citizenship will be in totally decentralized form so users can
                  organize it according to their rules and regulations. Here, to
                  earn this advantageous citizenship, users will need to have
                  SBT (Soul Bound Token).
                </p>
                <a
                  href="https://market.blockchain.land/citizenship"
                  target="_blank"
                  className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200"
                  style={{
                    backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")',
                  }}
                >
                  Get it Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section Digital Global end */}

      {/*Side by Side Start*/}
      <section className="section-lose position-relative pt-5 mt-5 pt-md-0 mt-md-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center text-lg-start">
                <p
                  className="btn-point btn-blue text-center d-inline-block font-22 font-200 white-text mb-30"
                  style={{
                    backgroundImage:
                      'url("assets/image/small-elements/btn-blue-point.svg")',
                  }}
                >
                  How to get
                </p>
                <h2 className="font--70 font-200 white-text line--80 mb-35">
                  Citizenship
                </h2>
                <img
                  src="/assets/image/citizenship/line.svg"
                  className="mb-4"
                  alt="line"
                />
                {/* <p className="font-18 font-100 white-text mb-35 line-25">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                                </p> */}
                {/* <div className='d-flex btn_height'>
                                    <a href="https://market.blockchain.land/map" target="_blank" className="btn-head btn-contain text-center text-white font-16 d-block font-200 mx-auto mx-lg-0" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>Start Exploring</a>
                                </div> */}
              </div>
            </div>
          </div>
          <div className="accordion" id="accordionExample">
            <div className="row mt-5 align-items-center">
              <div className="col-lg-6">
                <div
                  className="accordion-item m-0"
                  onClick={() => onClickHandler('ground')}
                >
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button faqaccbtn true"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <img
                        src="/assets/image/citizenship/true.svg"
                        className="me-2"
                        alt="line"
                      />{' '}
                      Step 1
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        Connect your wallet in Blockchain Land marketplace{' '}
                        <a
                          className="text-blue"
                          href="https://market.blockchain.land/"
                          target="_blank"
                        >
                          Market.blockchain.land
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item m-0"
                  onClick={() => onClickHandler('first')}
                >
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button faqaccbtn collapsed true"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <img
                        src="/assets/image/citizenship/true.svg"
                        className="me-2"
                        alt="line"
                      />{' '}
                      Step 2
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>Fill all the required details in the given form</p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item m-0"
                  onClick={() => onClickHandler('second')}
                >
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button faqaccbtn collapsed true"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <img
                        src="/assets/image/citizenship/true.svg"
                        className="me-2"
                        alt="line"
                      />{' '}
                      Step 3
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        To design a character Upload your photo or take selfie
                        at a moment to create your own avatar
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item m-0"
                  onClick={() => onClickHandler('third')}
                >
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button faqaccbtn collapsed done"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <img
                        src="/assets/image/citizenship/done.svg"
                        className="me-2"
                        alt="line"
                      />{' '}
                      Done
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body faqbody">
                      <p>
                        This is the completion of your process where if you
                        visit the page, will get a chance of editing as well as
                        statistics will be shown to the user.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0">
                <div className="accordion-body">
                  {imageClicked.ground && (
                    <img
                      src="/assets/image/getcitizenship/connect_wallet.webp"
                      alt="Connect your wallet in the Blockchain Land Marketplace for Getting Metaverse Citizenship"
                      className="w-100"
                      type="image/webp"
                    />
                  )}
                  {imageClicked.first && (
                    <img
                      src="/assets/image/getcitizenship/form.webp"
                      alt="Fill the form for Become Blockchain Land Metaverse Citizen"
                      className="w-100"
                      type="image/webp"
                    />
                  )}
                  {imageClicked.second && (
                    <img
                      src="/assets/image/getcitizenship/avatar.webp"
                      alt="Create Your Own Avatar in Blockchain Land Metaverse"
                      className="w-100"
                      type="image/webp"
                    />
                  )}
                  {imageClicked.third && (
                    <img
                      src="/assets/image/getcitizenship/done.webp"
                      alt="Blockchain Land Metaverse Citizen details"
                      className="w-100"
                      type="image/webp"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Side by Side End*/}

      <section className="recent_citizen_padding">
        <div className="container">
          <div className="row justify-content-center mb-4">
            <div className="col-12 col-sm-12 col-lg-8 col-xl-6">
              <div className="text-center">
                <h2 className="font--70 font-200 white-text line--80 mb---20">
                  Recent Citizens
                </h2>
              </div>
            </div>
          </div>

          {recentCitizen ? (
            recentCitizen.length ? (
              <OwlCarousel className="owl-theme" {...options}>
                {recentCitizen.map((i, index) => {
                  let id = i.tokenid ? i.tokenid.toString() : '';
                  let a = '';
                  for (let i = 0; i < 6 - new String(id).length; i++) {
                    a = a + '0';
                  }
                  let find = a + id;
                  return (
                    <div key={index} className="item">
                      <a>
                        <div className="card custom-card overflow-hidden">
                          <div className="card-image pointer overflow-hidden">
                            <img
                              src={'/assets/image/getcitizenship/avatar_clone.png'}
                              alt="Blockchain Land Metaverse Citizen"
                            />
                          </div>
                          <div className="card-body p-0">
                            <div className="mt-3 pb-2 border_bottom">
                              <p className="font_16 font-400 mb-0 text-white">
                                {i.fullname ? i.fullname.substr(0, 20) : '-'}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mt-1">
                              <p className="font_12 font-400 mb-0">
                                {i.coinaddress
                                  ? shortenAddress(i.coinaddress)
                                  : ''}
                              </p>
                              <p className="font_12 font-400 text-white mb-0">
                                #{find ? find : ''}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </OwlCarousel>
            ) : (
              <div className="text-center">
                <p className="font-400 search_font mb-0">No Data Found</p>
              </div>
            )
          ) : (
            <div className="text-center">
              <p className="font-400 search_font mb-0">Loading ...</p>
            </div>
          )}
        </div>
      </section>

      {/* section Digital Global start */}
      <section className="section-global pb-0 pt-5 mb-100 spacemobile">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-lg-8 col-xl-6">
              <div className="text-center">
                <h2 className="font--70 font-200 white-text line--80 mb---20 ">
                  Benefits
                </h2>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4 col-12 text-center mb-5 mb-md-0 d-flex flex-row flex-md-column align-items-center">
              <img
                src="assets/image/small-elements/new_world.svg"
                alt="world"
                className="icon_width"
              />
              <div className="text-start text-md-center ms-3 ms-md-0">
                <h3 className="font-30 font-300 white-text mt-3">
                  A new world
                </h3>
                <p className="font-18 font-100 white--light--80 mt-lg-3 mt-0 mb-5 mb-md-0 line-25 text-md-center">
                  Build a new world with the latest and limitless facilities.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12 text-center mb-5 mb-md-0 d-flex flex-row flex-md-column align-items-center">
              <img
                src="assets/image/small-elements/realastic.svg"
                alt="realistic"
                className="icon_width"
              />
              <div className="text-start text-md-center ms-3 ms-md-0">
                <h3 className="font-30 font-300 white-text mt-3">
                  More realistic
                </h3>
                <p className="font-18 font-100 white--light--80 mt-lg-3 mt-0 mb-5 mb-md-0 line-25 text-md-center">
                  Enjoy this real world's experience with virtual reality
                  anytime-anywhere.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12 text-center d-flex flex-row flex-md-column align-items-center">
              <img
                src="assets/image/small-elements/Own dominance.svg"
                alt="dominance"
                className="icon_width"
              />
              <div className="text-start text-md-center ms-3 ms-md-0">
                <h3 className="font-30 font-300 white-text mt-3">
                  Own dominance
                </h3>
                <p className="font-18 font-100 white--light--80 mt-lg-3 mt-0 line-25 text-md-center">
                  Build your place with your own idea and comfortness without
                  any intermediation.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-12 text-center mt-5">
                            <a href="https://sweepwidget.com/view/57177-er5dnqtl" target="_blank" className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Explore Metaverse</a>
                        </div> */}
          </div>
        </div>
      </section>
      {/* section Digital Global end */}

      <Community />
      <Footer />
    </>
  );
}
