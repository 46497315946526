import './App.css';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useState } from 'react';
import Header from './component/Header';
import Footer from './component/Footer';
import Community from './component/Community';
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError,
} from '@web3-react/core';
import { injected } from './connector';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';

function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2.5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const cityplay = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    laptop: {
      breakpoint: { max: 1024, min: 769 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 768, min: 600 },
      items: 1.5,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const {
    connector,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
    library,
  } = useWeb3React();

  const shortenAddress = address => {
    return `${address.substring(0, 8)}...${address.substring(
      address.length - 6
    )}`;
  };

  return (
    <>
      <div>
        <Helmet>
          <title>The First Ever MultiChain Metaverse | Blockchain Land</title>
          <meta
            name="title"
            content="The First Ever MultiChain Metaverse | Blockchain Land"
          />
          <meta
            name="description"
            content="Experience the Future of Virtual Reality with Blockchain Land Metaverse Platform: A Complete Solution for Immersive Entertainment, Education & Business."
          />
          <meta
            name="keywords"
            content="NFT, Virtual reality, financial assets, Digital Citizenship, Multichain Metaverse, Mixed Reality, Virtual Land, Metaverse Land, Decentralized Land, digital assets"
          />
          <link rel="canonical" href="https://blockchain.land" />
        </Helmet>

        {/* Header Start */}
        <Header />
        {/* Header End */}
        {/*Hero Start*/}
        <section className="hero hero__1 multiverse-height multiverse-before">
          <video
            className="video-galaxy"
            // src="https://d2odr8c7oo7gad.cloudfront.net/1661234379.mp4"
            src="assets/image/hero/hero-video.mp4"
            type="video/ogg"
            autoPlay
            loop
            muted
          />
          <div className="container">
            <div className="main-multiverse d-flex justify-content-between flex-column">
              <div className="row mutliverse_order">
                <div className="col-12">
                  <div className="multiverse-card bg-multiverse mt-hero-tab btn-contain d-flex align-items-center position-relative">
                    <h1 className="white-text font-400 font_31">
                      The First-Ever
                      <br /> MultiChain Metaverse!
                    </h1>
                  </div>
                </div>
              </div>
              <div className="row justify-content-xl-end justify-content-center order_change margin_top_hero">
                <div className="col-md-12 col-lg-10">
                  <div className="multiverse-content text-xl-end text-md-center">
                    <p className="font-22 white-text font-200">
                      Allowing your businesses to scale up <br /> globally and
                      across blockchains!
                    </p>
                    <h2 className="white-text font-900 mt-15 mb-30 font-113 hero_title">
                      Blockchain <br className="d-md-none d-block" /> Land
                    </h2>
                    <a
                      onClick={() => activate(injected)}
                      className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200"
                      style={{
                        backgroundImage:
                          'url("assets/image/btn-bg/btn-black.svg")',
                      }}
                    >
                      {account ? shortenAddress(account) : 'Connect Wallet'}
                    </a>
                  </div>
                </div>
              </div>

              <div className="metaverse_live margin_top_front mb--50 order-3">
                <div className="row">
                  <div className="col-lg-6 col-12 metaverse_padding text-start text-md-center text-lg-start">
                    <div className="width_130">
                      <LazyLoad>
                        <img
                          src="assets/image/hero/live.webp"
                          alt="live"
                          type="image/webp"
                        />
                      </LazyLoad>
                    </div>
                    <h2 className="metaverse_live_h2 mb-4">
                      Now, Blockchain Land Metaverse{' '}
                      <span className="text_highlight">Marketplace</span> Is
                      Live. Reach To Your Virtual Destination.
                    </h2>
                    <div className="mb-30">
                      <a href="https://market.blockchain.land/" target="_blank">
                        <img
                          src="assets/image/hero/explore.webp"
                          alt="explore"
                          className="explore_width"
                          type="image/webp"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row order-4">
                <div className="col-md-12">
                  <div className="main-process border-custom-top border-custom-bottom d-sm-flex justify-content-center align-items-center mt--tab d-none text-md-center">
                    <p className="white-text font-200 font-20 mb-0">Ethereum</p>
                    <img
                      src="assets/image/small-elements/chain-blue-mark.svg"
                      alt="process"
                    />
                    <p className="white-text font-200 font-20 mb-0">
                      Binance Smart Chain
                    </p>
                    <img
                      src="assets/image/small-elements/chain-blue-mark.svg"
                      alt="process"
                    />
                    <p className="white-text font-200 font-20 mb-0">
                      Lycan Chain
                    </p>
                    {/* <img src="assets/image/small-elements/chain-blue-mark.svg" alt="process" title='process' />
                    <p className="white-text font-200 font-20 mb-0">Fantom</p> */}
                  </div>
                  <div className="main-process border-custom-top border-custom-bottom mb-70 d-flex justify-content-between align-items-center mt--tab d-sm-none">
                    <marquee behavior direction>
                      <div className="d-flex">
                        <p className="white-text font-200 font-20 mb-0">
                          Ethereum
                        </p>
                        <img
                          src="assets/image/small-elements/chain-blue-mark.svg"
                          alt="process"
                        />
                        <p className="white-text font-200 font-20 mb-0">
                          Binance Smart Chain
                        </p>
                        <img
                          src="assets/image/small-elements/chain-blue-mark.svg"
                          alt="process"
                        />
                        <p className="white-text font-200 font-20 mb-0">
                          Lycan Chain
                        </p>
                        {/* <img src="assets/image/small-elements/chain-blue-mark.svg" alt="process" title='process' />
                        <p className="white-text font-200 font-20 mb-0">Fantom</p> */}
                      </div>
                    </marquee>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Hero End*/}

        {/* <section className='discord_iframe'>
          <div className='container'>
            <iframe src="https://discordapp.com/widget?id=967003427844521995&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
          </div>
        </section> */}

        {/*Planet Mint Start*/}
        <section className="section-planet bg-planet btn-contain">
          <div className="container">
            <div className="row position-relative justify-content-between">
              <div className="col-md-12 col-xl-7 text-start text-md-center text-xl-start">
                <div className="main-planet mb-md-0 mb-2">
                  <img
                    className="mint-line"
                    src="assets/image/dot-line/mint-line.svg"
                    alt="line"
                  />
                  <span className="d-flex align-items-center font-25 font-300 white-text mb-10 justify-content-start justify-content-md-center justify-content-xl-start text_upercase">
                    <img
                      className="mr-10"
                      src="assets/image/small-elements/planet-icon.svg"
                      alt="planticon"
                    />
                    Metaverse
                  </span>
                  <h2 className="white-text font-400 font-113 text_upercase">
                    Planet
                  </h2>
                  <div className>
                    <img
                      className="planet-line"
                      src="assets/image/dot-line/mint-right-line.svg"
                      alt="line"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-5 text-start text-md-center text-xl-start">
                <div className="main-evm">
                  <span className="font-18 font-300 text-blue mb-3 d-inline-block">
                    Complete EVM compatibility
                  </span>
                  <p className="font-18 font-100 white-text">
                    A planet is the biggest parcel in Blockchain Land. It's like
                    owning a parallel world of your own. In the Blockchain Land
                    universe, only 7 planets can be minted, The first planet
                    would be minted by the creators of Blockchain Land. Whenever
                    a planet will be minted on Blockchain Land 5% of the total
                    supply of BCL will be burned. Planet owners can create their
                    infrastructure using assets on our marketplace, they can
                    also sell and trade land on their planet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Planet Mint End*/}

        {/*Country Mint Start*/}
        <div className="main-country mb-md-0 mb-2">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <span className="d-flex align-items-center justify-content-md-center justify-content-start font-25 font-300 white-text mb-10 text_upercase">
                    <img
                      className="mr-10"
                      src="assets/image/small-elements/country-icon.svg"
                      alt="countryicon"
                    />
                    Mint Your
                  </span>
                  <h2 className="white-text text-md-center text-start font-400 font-113 text_upercase">
                    Country
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-country bg-country btn-contain">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div>
                  <p className="font-18 font-100 white-text text-md-center text-start mb-22">
                    A country is a mid-tier parcel that can be minted on
                    Blockchain Land. Countries are part of the planet, each
                    country could be dedicated to various sectors like media &
                    entertainment, cryptocurrency, real estate, fashion,
                    apparel, etc. A country could be bought from the planet
                    owners or creators of Blockchain Land. Countries could be
                    capitalized on by businesses to take their business into the
                    virtual world and cater to our digital citizens.
                  </p>
                  <div className="d-flex">
                    <a
                      href="https://market.blockchain.land/mint"
                      target="_blank"
                      className="btn-head btn-contain font-16 text-center white-text mx-md-auto d-block font-200"
                      style={{
                        backgroundImage:
                          'url("assets/image/btn-bg/btn-black.svg")',
                      }}
                    >
                      Pre-Mint Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Country Mint End*/}

        {/*City Mint Start*/}
        <div className="city-line mb-md-0 mb-3">
          <div className="container">
            <div className="text-center">
              <img
                src="assets/image/dot-line/mint-topline.svg"
                alt="line"
                className="w-100 d-none d-xl-block"
              />
              <span className="d-flex align-items-center justify-content-md-center justify-content-start font-25 font-300 white-text mb-10 mt-xl-4 mt-5 text_upercase">
                <img
                  className="mr-10"
                  src="assets/image/small-elements/city-icon.svg"
                  alt="city icon"
                />
                Mint Your
              </span>
              <h2 className="white-text text-md-center text-start font-400 font-113 text_upercase">
                Valley
              </h2>
            </div>
          </div>
        </div>
        <section className="secion-city">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-10">
                <div>
                  <p className="font-18 font-100 white-text mb-22 text-md-center text-start">
                    A valley is the smallest parcel that can be minted on
                    Blockchain Land. Valleys coexist within the countries, A
                    valley could be bought from the planet/ country owners or
                    creators of Blockchain Land. Owners can create their
                    infrastructure using NFTs and assets which are available on
                    our marketplace. Valleys are a great opportunity for
                    influencers, SMEs, brands, etc.
                  </p>
                  <div className="d-flex">
                    <a
                      href="https://market.blockchain.land/mint"
                      target="_blank"
                      className="btn-head btn-contain font-16 text-center white-text mx-md-auto d-block font-200"
                      style={{
                        backgroundImage:
                          'url("assets/image/btn-bg/btn-black.svg")',
                      }}
                    >
                      Pre-Mint Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*City Mint End*/}

        {/*Village Mint Start*/}
        <section className="section-village position-relative pt--100 buy_land">
          <div className="container">
            <div className="row justify-content-between align-items-start">
              <div className="col-12 col-xl-6 order-2 order-xl-1">
                <img
                  src="assets//image/blockchain_land/village-left-side.webp"
                  alt="The-First-Ever-MultiChain-Metaverse"
                  className="mint-village"
                  type="image/webp"
                />
              </div>
              <div className="col-12 col-xl-5 order-1 order-xl-2 text-start text-md-center text-xl-start">
                {/* <span className="d-flex align-items-center font-25 font-300 white-text mb-10 justify-content-center justify-content-lg-start"><img className="mr-10" src="assets/image/small-elements/village-icon.svg" alt="" />Mint Your</span> */}
                <h2 className="white-text font-400 mb--20 village-line position-relative mt-0 mt-md-3 mt-lg-0 font-113 line_height_home text_upercase">
                  Buy your <br className="d-block d-md-none" /> Land
                </h2>
                <p className="font-18 font-100 white-text mb-22">
                  Creating your infrastructure has never been so engaging and
                  easy. Design your parcel using assets that are available on
                  our marketplace. You can also create your assets.
                </p>
                <div className="d-flex btn_height">
                  <a
                    href="https://market.blockchain.land/mint"
                    target="_blank"
                    className="btn-head btn-contain font-16 text-center white-text d-block font-200 mx-md-auto mx-xl-0"
                    style={{
                      backgroundImage:
                        'url("assets/image/btn-bg/btn-black.svg")',
                    }}
                  >
                    Pre-Mint Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Village Mint End*/}

        {/*Village Card Slider Start*/}
        <section className="section-gap">
          <div className="container-fluid p-0">
            <div className="row align-items-center justify-content-end">
              <div className="col-xl-12">
                <Carousel
                  className="pt-0 pt-lg-3 offer-style-1"
                  itemClass="me-3"
                  responsive={cityplay}
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={20000000}
                  arrows={false}
                >
                  {/*Card 1 Start*/}
                  <div
                    className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain"
                    style={{
                      backgroundImage:
                        'url("assets/image/side-img/village/Gaming.webp")',
                    }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-7 p-0">
                        <h3 className="font-40 font-200 white-text mb-10 text_upercase">
                          Gaming
                        </h3>
                        {/* <p className="font-16 font-300 white-text mb-10">Total Theatres : <span className="text-blue d-inline-block">2 Million</span></p> */}
                        <p className="font-16 font-100 white-text line-22 scroll_show">
                          Gaming is one of the most well-crafted arenas to be
                          minted in Blockchain Land. Gaming already has a
                          multiverse involvement.
                        </p>
                        {/* <a href="https://market.blockchain.land/mint" target="_blank" className="d-flex font-16 font-200 white-text mt-35 justify-content-start">
                          <img className="mr-10" src="assets/image/small-elements/house/house-bullet.svg" alt="house_bullet" />
                          Mint Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/*Card 1 End*/}
                  {/*Card 2 Start*/}
                  <div
                    className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain"
                    style={{
                      backgroundImage:
                        'url("assets/image/side-img/village/Social_Places.webp")',
                    }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-7 p-0">
                        <h3 className="font-40 font-200 white-text mb-10 text_upercase">
                          Social Place
                        </h3>
                        {/* <p className="font-16 font-300 white-text mb-10">Total Shopping Malls : <span className="text-blue d-inline-block">0.5 Million</span></p> */}
                        <p className="font-16 font-100 white-text line-22 scroll_show">
                          A multichain metaverse is just what you need, to
                          create your social space in the metaverse.
                        </p>
                        {/* <a href="https://market.blockchain.land/mint" target="_blank" className="d-flex font-16 font-200 white-text mt-35 justify-content-start">
                          <img className="mr-10" src="assets/image/small-elements/house/house-bullet.svg" alt="house_bullet" />
                          Mint Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/*Card 2 End*/}
                  {/*Card 3 Start*/}
                  <div
                    className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain"
                    style={{
                      backgroundImage:
                        'url("assets/image/side-img/village/Commodities.webp")',
                    }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-7 p-0">
                        <h3 className="font-40 font-200 white-text mb-10 text_upercase">
                          Commodities
                        </h3>
                        {/* <p className="font-16 font-300 white-text mb-10">Total Virtual Offices : <span className="text-blue d-inline-block">2 Million</span></p> */}
                        <p className="font-16 font-100 white-text line-22 scroll_show">
                          The one-stop platform that can provide varied types of
                          metaverse space to display your commodities.
                        </p>
                        {/* <a href="https://market.blockchain.land/mint" target="_blank" className="d-flex font-16 font-200 white-text mt-35 justify-content-start">
                          <img className="mr-10" src="assets/image/small-elements/house/house-bullet.svg" alt="house_bullet" />
                          Mint Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/*Card 3 End*/}
                  {/*Card 4 Start*/}
                  <div
                    className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain"
                    style={{
                      backgroundImage:
                        'url("assets/image/side-img/village/Events.webp")',
                    }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-7 p-0">
                        <h3 className="font-40 font-200 white-text mb-10 text_upercase">
                          Events
                        </h3>
                        {/* <p className="font-16 font-300 white-text mb-10">Total Events : <span className="text-blue d-inline-block">2 Million</span></p> */}
                        <p className="font-16 font-100 white-text line-22 scroll_show">
                          Events are taking up the major metaverse state.
                          Blockchain Land is sure to mint your most experiential
                          event to date.
                        </p>
                        {/* <a href="https://market.blockchain.land/mint" target="_blank" className="d-flex font-16 font-200 white-text mt-35 justify-content-start">
                          <img className="mr-10" src="assets/image/small-elements/house/house-bullet.svg" alt="house_bullet" />
                          Mint Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/*Card 4 End*/}
                  {/*Card 5 Start*/}
                  <div
                    className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain"
                    style={{
                      backgroundImage:
                        'url("assets/image/side-img/village/Virtual_Office.webp")',
                    }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-7 p-0">
                        <h3 className="font-40 font-200 white-text mb-10 text_upercase">
                          Virtual Offices
                        </h3>
                        {/* <p className="font-16 font-300 white-text mb-10">Total Commodities : <span className="text-blue d-inline-block">2 Million</span></p> */}
                        <p className="font-16 font-100 white-text line-22 scroll_show">
                          We give you an amazing opportunity to create your
                          virtual office, keeping in mind your area, and your
                          design requirements.
                        </p>
                        {/* <a href="https://market.blockchain.land/mint" target="_blank" className="d-flex font-16 font-200 white-text mt-35 justify-content-start">
                          <img className="mr-10" src="assets/image/small-elements/house/house-bullet.svg" alt="house_bullet" />
                          Mint Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/*Card 5 End*/}
                  {/*Card 6 Start*/}
                  <div
                    className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain"
                    style={{
                      backgroundImage:
                        'url("assets/image/side-img/village/Shopping_Malls.webp")',
                    }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-7 p-0">
                        <h3 className="font-40 font-200 white-text mb-10 text_upercase">
                          Shopping Mall
                        </h3>
                        {/* <p className="font-16 font-300 white-text mb-10">Total Social Places : <span className="text-blue d-inline-block">2 Million</span></p> */}
                        <p className="font-16 font-100 white-text line-22 scroll_show">
                          There are a plethora of options of goods and services
                          in a Shopping Mall with High Scalability,
                          transparency, and easy payments.
                        </p>
                        {/* <a href="https://market.blockchain.land/mint" target="_blank" className="d-flex font-16 font-200 white-text mt-35 justify-content-start">
                          <img className="mr-10" src="assets/image/small-elements/house/house-bullet.svg" alt="house_bullet" />
                          Mint Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/*Card 6 End*/}
                  {/*Card 7 Start*/}
                  <div
                    className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain"
                    style={{
                      backgroundImage:
                        'url("assets/image/side-img/village/Theaters.webp")',
                    }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-7 p-0">
                        <h3 className="font-40 font-200 white-text mb-10 text_upercase">
                          Theaters
                        </h3>
                        {/* <p className="font-16 font-300 white-text mb-10">Total Gaming : <span className="text-blue d-inline-block">2 Million</span></p> */}
                        <p className="font-16 font-100 white-text line-22 scroll_show">
                          For the Entertainment Buffs, Create your personalize
                          and special single screen or multiplex experience.
                        </p>
                        {/* <a href="https://market.blockchain.land/mint" target="_blank" className="d-flex font-16 font-200 white-text mt-35 justify-content-start">
                          <img className="mr-10" src="assets/image/small-elements/house/house-bullet.svg" alt="house_bullet" />
                          Mint Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/*Card 7 End*/}
                  {/*Card 8 Start*/}
                  <div
                    className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain"
                    style={{
                      backgroundImage:
                        'url("assets/image/side-img/village/IT.webp")',
                    }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-7 p-0">
                        <h3 className="font-40 font-200 white-text mb-10 text_upercase">
                          Information Technology
                        </h3>
                        {/* <p className="font-16 font-300 white-text mb-10">Total Banking and Finance : <span className="text-blue d-inline-block">2 Million</span></p> */}
                        <p className="font-16 font-100 white-text line-22 scroll_show">
                          In today’s day and age, Information is power and with
                          Blockchain land we can put this power to great use.
                        </p>
                        {/* <a href="https://market.blockchain.land/mint" target="_blank" className="d-flex font-16 font-200 white-text mt-35 justify-content-start">
                          <img className="mr-10" src="assets/image/small-elements/house/house-bullet.svg" alt="house_bullet" />
                          Mint Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/*Card 8 End*/}
                  {/*Card 9 Start*/}
                  <div
                    className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain"
                    style={{
                      backgroundImage:
                        'url("assets/image/side-img/village/Digital_Asset.webp")',
                    }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-7 p-0">
                        <h3 className="font-40 font-200 white-text mb-10 text_upercase">
                          Digital Assets
                        </h3>
                        {/* <p className="font-16 font-300 white-text mb-10">Total Education : <span className="text-blue d-inline-block">2 Million</span></p> */}
                        <p className="font-16 font-100 white-text line-22 scroll_show">
                          Digitized Assets are the easiest to transact.
                          Blockchain land with its amazing EVM Compatibility and
                          Global Digital Citizenship.
                        </p>
                        {/* <a href="https://market.blockchain.land/mint" target="_blank" className="d-flex font-16 font-200 white-text mt-35 justify-content-start">
                          <img className="mr-10" src="assets/image/small-elements/house/house-bullet.svg" alt="house_bullet" />
                          Mint Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/*Card 9 End*/}
                  {/*Card 10 Start*/}
                  <div
                    className="cards offer-card offer-style-1 radius-5xl bg-village btn-contain"
                    style={{
                      backgroundImage:
                        'url("assets/image/side-img/village/Education.webp")',
                    }}
                  >
                    <div className="row justify-content-end">
                      <div className="col-7 p-0">
                        <h3 className="font-40 font-200 white-text mb-10 text_upercase">
                          Education
                        </h3>
                        {/* <p className="font-16 font-300 white-text mb-10">Total Digital Assets : <span className="text-blue d-inline-block">2 Million</span></p> */}
                        <p className="font-16 font-100 white-text line-22 scroll_show">
                          Blockchain Land can provide an easier platform in
                          terms of transparency between parents and students.
                        </p>
                        {/* <a href="https://market.blockchain.land/mint" target="_blank" className="d-flex font-16 font-200 white-text mt-35 justify-content-start">
                          <img className="mr-10" src="assets/image/small-elements/house/house-bullet.svg" alt="house_bullet" />
                          Mint Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/*Card 10 End*/}
                </Carousel>
              </div>
            </div>
          </div>
        </section>
        {/*Village Card Slider End*/}

        {/*Citizenship Start*/}
        <section className="section-digital bg-digital btn-contain">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 text-start text-md-center text-xl-start order-md-1 order-2 mt-3 mt-md-0">
                <h2 className="white-text mb-45 font-113 line_height_home font-400 text_upercase">
                  Digital Global Citizenship
                </h2>
                <p className="font-18 font-100 white-text mb-40 mr-200 line-25">
                  Digital citizenship is a decentralized unique identity which
                  gives users access to the virtual universe. With digital
                  citizenship in place, users can experience and be a part of
                  the planet, country & valley. Allowing them to explore the
                  infrastructure and experience the virtual world. Users can
                  also buy and sell NFTs using their digital citizenship rights.
                </p>
                <div className="d-flex">
                  <a
                    href="https://market.blockchain.land/citizenship"
                    target="_blank"
                    className="btn-head btn-contain font-16 text-center white-text d-block font-200 mx-md-auto mx-xl-0"
                    style={{
                      backgroundImage:
                        'url("assets/image/btn-bg/btn-black.svg")',
                    }}
                  >
                    Get it Now
                  </a>
                </div>
              </div>
              <div className="col-12 px-0 order-md-2 order-1">
                <img
                  src="assets/image/mobile-view/citizenship-bg-m.png"
                  alt="Metaverse Citizenship Card"
                  className="w-100 d-md-none d-block"
                />
              </div>
            </div>
          </div>
          <div className="container-fluid px-0">
            <div className="d-xl-none d-md-block d-none">
              <img
                src="assets/image/mobile-view/citizenship-bg-tab.webp"
                alt="digital-img"
                className="w-100"
                type="img/webp"
              />
            </div>
          </div>
        </section>
        {/*Citizenship End*/}

        {/* section download our section start */}
        <section className="our_app pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-md-12 mb-lg-0 mb-5 order-xl-1 order-2">
                <div className="text-xl-start text-md-center">
                  <span className="team_highlight hire_font_20">
                    Download Our App
                  </span>
                  <h2 className="font_73 font-400 white-text mt-4 text_upercase">
                    Explore the new world anytime, anywhere
                  </h2>
                  <p className="font-18 font-100 white-text line-25 my-4">
                    Experience an incredible world of metaverse with marvelous
                    features of our Mobile App and make your journey more
                    adventurous.
                  </p>

                  <div className="row">
                    <div className="col-6">
                      <div className="d-flex align-items-center justify-content-xl-start justify-content-md-center mb-4">
                        <img
                          className="wh_22"
                          src="assets/image/download/reality.svg"
                          alt="Augmented Reality"
                        />
                        <h3 className="font-18 font-100 text-white ms-3 text_upercase">
                          Augmented Reality
                        </h3>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex align-items-center justify-content-xl-start justify-content-md-center mb-4">
                        <img
                          className="wh_22"
                          src="assets/image/download/arena.svg"
                          alt="Arena"
                        />
                        <h3 className="font-18 font-100 text-white ms-3 text_upercase">
                          Arena
                        </h3>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex align-items-center justify-content-xl-start justify-content-md-center mb-sm-4 mb-0">
                        <img
                          className="wh_22"
                          src="assets/image/download/nft.svg"
                          alt="NFT"
                        />
                        <h3 className="font-18 font-100 text-white ms-3 text_upercase">
                          NFT Marketplace
                        </h3>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex align-items-center justify-content-xl-start justify-content-md-center mb-sm-4 mb-0">
                        <img
                          className="wh_22"
                          src="assets/image/download/meta.svg"
                          alt="Metaverse"
                        />
                        <h3 className="font-18 font-100 text-white ms-3 text_upercase">
                          Metaverse
                        </h3>
                      </div>
                    </div>
                    <div className="col-12 mt-40">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.blockchain.land"
                        target="_blank"
                        className="white-text d-inline-block"
                      >
                        <img
                          className="wh_200"
                          src="assets/image/download/google_play.svg"
                          alt="Google pay"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-12 order-xl-2 order-1 mb-xl-0 mb_150">
                <img
                  className="w-100"
                  src="assets/image/download/app_down.webp"
                  alt="Metaverse NFT Marketplace - Blockchain Land Mobile Application"
                  type="img/webp"
                />
              </div>
            </div>
          </div>
        </section>
        {/* section download our section end */}

        {/* section Mixed Reality start */}
        <section className="our_app pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7 col-md-12">
                <img
                  className="w-100"
                  src="assets/image/download/explore.webp"
                  alt="Mixed Reality in Blockchain Land Metaverse"
                  type="img/webp"
                />
              </div>
              <div className="col-xl-5 col-md-12 mt-xl-0 mt-5">
                <div className="text-xl-start text-md-center global-right">
                  <span className="team_highlight hire_font_20">
                    <img
                      className="wh_22 me-3"
                      src="assets/image/download/mixed_reality.svg"
                      alt="global"
                    />{' '}
                    Augmented Reality
                  </span>
                  <h2 className="font_73 font-400 white-text mt-4 text_upercase">
                    Explore <br className="d-md-none d-block" /> Mixed Reality
                  </h2>
                  <p className="font-18 font-100 white-text line-25 my-4">
                    Convert your imagination into the reality and have an
                    interactive experience of physical and digital worlds with
                    Augmented reality.
                  </p>
                  <div>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.blockchain.land"
                      target="_blank"
                      className="white-text d-inline-block"
                    >
                      <img
                        className="wh_200"
                        src="assets/image/download/google_play.svg"
                        alt="Google pay"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section Mixed Reality end */}

        <section className="section-worth pb-0">
          <div className="container">
            <div className="row">
              <div className="col-12 position-relative">
                <img
                  src="assets/image/ambassadorprogram/ambassadorprogram.webp"
                  alt="Blockchain Land Meatverse Ambassador Program"
                  type="image/webp"
                  className="d-xl-block d-none"
                />
                <img
                  src="assets/image/ambassadorprogram/ambassadorprogram_tab.webp"
                  alt="ambassadorprogram"
                  type="image/webp"
                  className="d-xl-none d-md-block d-none mx-auto"
                />
                <img
                  src="assets/image/ambassadorprogram/ambassadorprogram_m.png"
                  alt="ambassadorprogram"
                  className="d-md-none d-block mx-auto"
                />
                <div className="ambassador_front text-xl-start text-md-center">
                  <h3 className="ambassador_color ambassador_h1 font-400 text_upercase">
                    Blockchain land
                    <br className="d-lg-block d-none" /> Ambassador
                  </h3>
                  <p className="font--18 meta_p mt-3 mb-4 font-100">
                    Blockchain Land is emerging as a big Metaverse world. Let's
                    make
                    <br className="d-lg-block d-none" /> this possible together.
                  </p>
                  <div className="d-flex align-items-center justify-content-md-center justify-content-xl-start">
                    <Link
                      to={'/ambassadorprogram'}
                      className="btn-head btn-contain font-16 text-center text-custom-dark d-inline-block font-200"
                      style={{
                        backgroundImage:
                          'url("assets/image/meta_life/developer_btn.png")',
                      }}
                    >
                      Know More
                    </Link>
                    <a
                      href="https://market.blockchain.land/tutorials"
                      target={'_blank'}
                      className="btn-head btn-contain font-16 text-center text-white d-inline-block font-200 ms-3"
                      style={{
                        backgroundImage:
                          'url("assets/image/meta_life/user_btn.png")',
                      }}
                    >
                      Tutorial
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Virtual Land Start*/}
        <section className="section-owning">
          <div className="container">
            <div className="row justify-content-xl-start justify-content-center">
              <div className="col-md-12 text-md-center text-xl-start">
                <h2 className="white-text font-400 mb-35 font_73 text_upercase">
                  Owning a Virtual <br />
                  Land has Never Been Easier!
                </h2>
                <p className="font-18 font-100 white-text mb-40 line-25 w-40 mx-auto mx-xl-0">
                  Control governance, engage, and influence, showcase your
                  assets, and build your story!
                </p>
                <h3 className="font-22 text-blue font-300 mb-38 bb-mobile pb-4 pb-md-0">
                  1 Parcel = 50 Meter X 50 Meter
                </h3>
              </div>
              <div className="col-6 col-md-5 col-xl-3 col-xxl-2 text-xl-start">
                <p className="font-18 font-100 white-text line-25 border_dark ps-md-3 ps-0">
                  10 x 10 parcels to own a valley
                </p>
              </div>
              <div className="col-6 col-md-5 col-xl-4 col-xxl-2 text-xl-start">
                <p className="font-18 font-100 white-text line-25 border_light ps-md-3 ps-0">
                  25 x 25 parcels to own a country
                </p>
              </div>
            </div>
            <div className="row mt-180 top-line position-relative justify-content-center">
              <div className="col-md-6 col-xl-4 text-md-center text-xl-start">
                <div>
                  <img
                    src="assets/image/side-img/virtual-land/virtual-land-01.webp"
                    alt="Metaverse Land"
                    type="image/webp"
                  />
                  <div className="d-flex align-items-center">
                    <img
                      src="assets/image/dot-line/virtual-line-mobile.webp"
                      alt="line"
                      className="py-mobile me-2"
                      type="image/webp"
                    />
                    <div>
                      <h3 className="font-22 white-text font-300 d-flex mt-0 mt-md-4 mb-15 justify-content-md-center justify-content-xl-start text_upercase">
                        <span className="owning-round d-none d-md-block" />
                        Metaverse Land
                      </h3>
                      <p className="font-18 font-100 white-text line-25">
                        Here, is the piece of metaverse land, providing access
                        to explore content and the possibility to build your
                        universe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 text-md-center text-xl-start">
                <div>
                  <img
                    src="assets/image/side-img/virtual-land/virtual-land-02.png"
                    alt="Virtual Land"
                    className="w-100"
                  />
                  <div className="d-flex align-items-center">
                    <img
                      src="assets/image/dot-line/virtual-line-mobile.webp"
                      alt="line"
                      className="py-mobile me-2"
                      type="image/webp"
                    />
                    <div>
                      <h3 className="font-22 white-text font-300 d-flex mt-0 mt-md-4 mb-15 justify-content-md-center justify-content-xl-start text_upercase">
                        <span className="owning-round d-none d-md-block" />
                        Decentralized Land / Virtual Land
                      </h3>
                      <p className="font-18 font-100 white-text line-25">
                        A unified virtual universe for engaging with and
                        managing your digital residences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 text-md-center text-xl-start">
                <div className="line-mobile">
                  <img
                    src="assets/image/side-img/virtual-land/virtual-land-03.png"
                    alt="Real Land"
                    className="w-100"
                  />
                  <div className="d-flex align-items-start">
                    <img
                      src="assets/image/dot-line/virtual-line-mobile-last.webp"
                      alt="line"
                      className="py-mobile me-2"
                      type="image/webp"
                    />
                    <div>
                      <h3 className="font-22 white-text font-300 d-flex mt-4 mb-15 justify-content-md-center justify-content-xl-start text_upercase">
                        <span className="owning-round d-none d-md-block" />
                        Real land
                      </h3>
                      <p className="font-18 font-100 white-text line-25">
                        The land is a real asset, just like any other. On the
                        blockchain, the land is pegged against real assets to
                        maintain the value of your parcel of land.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Virtual Land End*/}

        {/*Explore Land Start*/}
        <section className="section-explore bg-exlpore btn-contain">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-md-center">
                <p
                  className="btn-point btn-contain text-center font-22 font-200 mx-md-auto text-dark mb-2 mx-0"
                  style={{
                    backgroundImage:
                      'url("assets/image/small-elements/btn-black-point.svg")',
                  }}
                >
                  Blockchain Land
                </p>
                <h2 className="white-text font-400 mb-22 position-relative text-md-center font--h2 text_upercase mobile_color">
                  Explore a different world on Blockchain Land
                </h2>
                <p className="font-18 font-100 white-text mb-40 line-25 text-md-center mobile_color">
                  We’ve built universes for you ... Explore what’s new?!
                </p>
                <div className="d-flex justify-content-md-center">
                  <div className="d-flex">
                    <a
                      href="https://market.blockchain.land/"
                      target="_blank"
                      className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200 me-2 mb-2 mb-lg-0"
                      style={{
                        backgroundImage:
                          'url("assets/image/btn-bg/btn-blue.svg")',
                      }}
                    >
                      Create Account
                    </a>
                  </div>
                  <div className="d-flex">
                    <Link
                      to={'/metaverse'}
                      className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200"
                      style={{
                        backgroundImage:
                          'url("assets/image/btn-bg/btn-black.svg")',
                      }}
                    >
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-explore-bottom bg-explore-bottom btn-contain d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="font-20 font-300 white-text text-center text-xl-start d-none d-sm-block">
                  <span className="text-blue">The Largest </span>Virtual Land,
                  Water and Space on Blockchain! Get
                </p>
                <marquee behavior direction className="d-block d-sm-none">
                  <p className="font-20 font-200 white-text text-center text-lg-start">
                    <span className="text-blue">The Largest </span>Virtual Land,
                    Water and Space on Blockchain! Get
                  </p>
                </marquee>
              </div>
            </div>
          </div>
        </section>
        {/*Explore Land End*/}

        {/*Video ands Listed Blockchain Start*/}
        <section className="section-listed bg-listed bg-cover">
          <div className="container px-md-2 px-0">
            {/*Video Start*/}
            <div className="row">
              <div className="col-12 listed-video bg-cover position-relative order-md-1 order-2">
                <div className="video-play text-center">
                  <a
                    href="https://www.youtube.com/watch?v=Cw7oLMzyMGQ"
                    className="btn-play-red-border mx-auto"
                    target={'_blank'}
                  >
                    <img
                      src="assets/image/section-bg/play.svg"
                      alt="play"
                      className="ps-1"
                    />
                  </a>
                </div>
              </div>
              <div className="col-12 mb-lg-0 mb-5 order-md-2 order-1 px-md-0 px-4">
                <h3 className="font-33 font-400 white-text text-md-center text-xl-end mt-30 text_upercase">
                  Introducing the world's first multiverse metaverse
                </h3>
                <p className="font-18 font-100 white-text mt-2 text-md-center text-xl-end line-25">
                  Blockchain Land is taking major leaps in metaverse space,
                  being the first multiverse metaverse gives blockchain access
                  to endless opportunities. Blockchain Land has created an
                  ecosystem for everyone, right from minting a planet to owning
                  citizenship. It promises to create an economy for everyone be
                  it businesses, high net worth individuals, or creators.
                </p>
              </div>
            </div>
            {/*Video End*/}
            {/*Listed Start*/}
            <div className="bg-listed-mobile">
              <div className="container">
                <div className="row mt-235">
                  <div className="col-md-12 text-md-center text-xl-start">
                    <h3 className="white-text font-400 font_73 text_upercase">
                      Listed Blockchains
                    </h3>
                    <p className="font-18 font-100 white-text mt-24 line-25">
                      Blockchain Land is a peer-to-peer network supported by
                      very powerful, unshakable, and secure blockchains like
                      ETH, BNB and LYC handled without any intermediation of
                      third parties.
                    </p>
                  </div>
                </div>
                <div className="row mt-60">
                  {/*Second Start*/}
                  <div className="col-md-12 col-xl-6">
                    <div className="bg-card-02 bg-content bg-cover mobile-bnb">
                      <div className="listed-content">
                        <h4 className="font-33 font-400 white-text">BNB</h4>
                        {/* <p className="font-16 font-300 white-text">Total Assets : 1</p> */}
                      </div>
                      <div className="d-flex align-items-center b-content">
                        <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55">
                          <img
                            className="mr-10"
                            src="assets/image/side-img/listed-card/listed-dot.svg"
                            alt="img"
                          />
                          1 Planet
                        </p>
                        <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55">
                          <img
                            className="mr-10"
                            src="assets/image/side-img/listed-card/listed-dot.svg"
                            alt="img"
                          />
                          713 Country
                        </p>
                        <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55">
                          <img
                            className="mr-10"
                            src="assets/image/side-img/listed-card/listed-dot.svg"
                            alt="img"
                          />
                          4220 Valley
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*Second End*/}
                  {/*First Start*/}
                  <div className="col-md-12 col-xl-6 mt-4 mt-xl-0">
                    <div className="bg-card-01 bg-content bg-cover mobile-eth">
                      <div className="listed-content">
                        <h4 className="font-33 font-400 white-text">
                          Ethereum
                        </h4>
                        {/* <p className="font-16 font-300 white-text">Total Assets : 0</p> */}
                      </div>
                      <div className="d-flex align-items-center b-content">
                        {/* <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/listed-dot.svg" alt="img" />1 Country</p>
                    <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/listed-dot.svg" alt="img" />1 Planet</p>
                    <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/listed-dot.svg" alt="img" />2 Valley</p> */}
                        <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55">
                          <img
                            className="mr-10"
                            src="assets/image/side-img/listed-card/listed-dot.svg"
                            alt="img"
                          />
                          Coming Soon
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*First Start*/}
                  {/*Three Start*/}
                  <div className="col-md-12 col-xl-6 mt-40">
                    <div className="bg-card-03 bg-content bg-cover mobile-lyc">
                      <div className="listed-content">
                        <h4 className="font-33 font-400 white-text">Lycan</h4>
                        {/* <p className="font-16 font-300 white-text">Total Assets : 0</p> */}
                      </div>
                      <div className="b-content">
                        <p className="font-16 font-300 white-text d-flex align-items-center mr-55">
                          <img
                            className="mr-10"
                            src="assets/image/side-img/listed-card/white-dot.svg"
                            alt="img"
                          />
                          Coming Soon
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*Three End*/}
                  {/*Fourth Start*/}
                  {/* <div className="col-md-10 col-lg-6 mt-40">
                <div className="bg-card-04 bg-content bg-cover mobile-dot">
                  <div className="listed-content">
                    <h4 className="font-33 font-400 white-text">Fantom</h4>
                    <p className="font-16 font-300 white-text">Total Assets : 0</p>
                  </div>
                  <div className="b-content">
                    <p className="font-16 font-300 white-text d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/white-dot.svg" alt="img" />Coming Soon</p>
                  </div>
                </div>
              </div> */}
                  {/*Fourth End*/}
                </div>
              </div>
            </div>
            {/*Listed End*/}
          </div>
        </section>
        {/*Video ands Listed Blockchain End*/}

        {/*Side by Side Start*/}
        <section className="section-listed section-lose pt-0">
          <div className="container-fluid p-0">
            <div className="row align-items-center">
              <div className="col-xl-6 col-12">
                <div className="pl-243 pr-20 mb-5 text-md-center text-xl-start">
                  <p
                    className="btn-point btn-blue text-center d-inline-block font-22 font-200 white-text mb-30"
                    style={{
                      backgroundImage:
                        'url("assets/image/small-elements/btn-blue-point.svg")',
                    }}
                  >
                    Explore
                  </p>
                  <h3 className="white-text font-400 mb-35 font--h3 text_upercase">
                    Lose yourself in the evolving virtual world of digital
                    assets &amp; engagements!
                  </h3>
                  <p className="font-18 font-100 white-text mb-35 line-25 text-xl-start">
                    Blockchain Land is not limited to a gallery; It is quite
                    literally the adaptation of real-world businesses migrating
                    and scaling up in the virtual universe. You can shop, watch
                    movies, explore museums and even socialize with other
                    players from anywhere in the world.
                  </p>
                  <div className="d-flex btn_height justify-content-xl-start justify-content-md-center">
                    <a
                      href="https://meta.blockchain.land/"
                      target="_blank"
                      className="btn-head btn-contain text-center text-white font-16 d-block font-200 mx-0 mx-md-auto mx-lg-0"
                      style={{
                        backgroundImage:
                          'url("assets/image/btn-bg/btn-black.svg")',
                      }}
                    >
                      Start Exploring
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <img
                  src="assets/image/blockchain_land/side-by-01.webp"
                  alt="Ticket to the Blockchain Land"
                  className="lose-image"
                  type="image/webp"
                />
              </div>
            </div>
            <div className="row align-items-center mt--180">
              <div className="col-xl-6 col-12 order-2 order-xl-1 mt-4 mt-xl-0">
                <img
                  src="assets/image/blockchain_land/side-by-02.webp"
                  alt="Low Poly Castle in Blockchain Land"
                  className="test-image d-xl-block d-none tabsaidsapce"
                  type="image/webp"
                />
                <img
                  src="assets/image/side-img/side-by-side/side-by-02-tablet.webp"
                  alt="lose"
                  className="test-image d-block d-xl-none px-xl-0 tabsaidsapce"
                  type="image/webp"
                />
              </div>
              <div className="col-xl-6 col-12 order-1 order-xl-2 text-md-center text-xl-start">
                <div className="pr-243">
                  <p
                    className="btn-point btn-blue text-center d-inline-block font-22 font-200 white-text mb-30"
                    style={{
                      backgroundImage:
                        'url("assets/image/small-elements/btn-blue-point.svg")',
                    }}
                  >
                    Create
                  </p>
                  <h3 className="white-text font-400 mb-35 font--h3 text_upercase">
                    Test the limits of your imagination
                  </h3>
                  <p className="font-18 font-100 white-text mb-35 line-25 text-md-center text-xl-start">
                    Blockchain Land also allows users to create their own space.
                    You can create artworks, challenges, and more, using the
                    simple Builder tool, then take part in events to win prizes.
                    For more experienced creators, the SDK provides the tools to
                    fill the world with social games and applications.
                  </p>
                  <div>
                    {/* <div className='d-flex'>
                    <a href="/citizenship" className="btn-head btn-contain text-center text-white d-inline-block font-16 font-200 me-2" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>Start Building</a>
                  </div> */}
                    <div className="d-flex justify-content-md-center justify-content-xl-start">
                      <a
                        href="/typesofnft"
                        className="btn-head btn-contain text-center text-white d-inline-block font-16 font-200"
                        style={{
                          backgroundImage:
                            'url("assets/image/btn-bg/btn-blue.svg")',
                        }}
                      >
                        Start Developing
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Side by Side End*/}

        {/*Virtual Space Start*/}
        <section className="section-trade">
          <div className="container px-md-2 px-0">
            <div className="bg-trade position-relative">
              <div className="main-trade position-absolute text-md-center px-3">
                <p
                  className="btn-point btn-blue text-center d-inline-block font-22 font-200 white-text mb-13"
                  style={{
                    backgroundImage:
                      'url("assets/image/small-elements/btn-blue-point.svg")',
                  }}
                >
                  Trade
                </p>
                <h3 className="white-text font-400 mb-23 line-83 text_upercase">
                  Exploring new horizons in the virtual space
                </h3>
                <p className="font-18 font-100 white-text mb-23 line-25 w-70 mx-auto">
                  Any user can create a virtual shop where users can buy and
                  sell land, estates, avatar wearables, and names in their
                  marketplace or reach out to us to create a blockchain-based
                  application to scale up your existing business in the Virtual
                  space.
                </p>
                <a
                  href="https://market.blockchain.land/"
                  target="_blank"
                  className="btn-head btn-contain text-center text-white d-inline-block font-16 font-200"
                  style={{
                    backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")',
                  }}
                >
                  Start Browsing
                </a>
              </div>
              <div className="d-md-block d-none">
                <img
                  src="assets/image/section-bg/virtual-space-bg.webp"
                  alt="Blockchain Land Meatverse NFT Marketplace"
                  className="w-100"
                  type="image/webp"
                />
              </div>
            </div>
          </div>
        </section>
        {/*Virtual Space End*/}

        {/*Roadmap Start*/}
        <section className="section-roadmap">
          <div>
            <h2 className="font-400 white-text font_73 text_upercase">
              Roadmap
            </h2>

            <div id="app">
              <div className="row">
                <div className="col-md-12">
                  <Carousel
                    className="pt-5"
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    arrows={false}
                    stopOnHover={true}
                  >
                    <div>
                      <div className="status-top">
                        <div className="status border_red size_high">
                          <div className="box-border bg-bubble bubble-top">
                            <span />
                            <h3 className="font-22 font-300 text-blue">
                              Stage 1
                            </h3>
                            <p className="font-18 font-300 white-text mb-md-3 mb-2">
                              Q2 - 2022
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              Ideation
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              Proof of Concept
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              Business Deck
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              Security Audit
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              Token on BEP 20
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="status-top">
                        <div className="status border_red size_high">
                          <div className="box-border bg-bubble bubble-top">
                            <span />
                            <h3 className="font-22 font-300 text-blue">
                              Stage 2
                            </h3>
                            <p className="font-18 font-300 white-text mb-md-3 mb-2">
                              Q3 - 2022
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              Builder NFT Airdrop
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              Website Launch
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              Legal Opinion
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              Whitepaper Release
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              PreSale - Digital Citizenship Release
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              ReadyPlayerMe Partnership
                            </p>
                            <p className="b-left font-18 font-100 white-text line-25">
                              NFT Marketplace Launch
                            </p>
                            <p
                              className="b-left font-18 font-100 white-text line-25">
                              BCL Mobile App V.1.0 Launch
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="status-top">
                        <div className="status border_red size_high">
                          <div className="box-border bg-bubble bubble-top">
                            <span />
                            <h3 className="font-22 font-300 white-text">
                              Stage 3
                            </h3>
                            <div>
                              <p className="font-18 font-300 white-text mb-md-3 mb-2">
                                Q4 - 2022
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25">
                                Citizenship and Land Parcel Airdrop
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25">
                                Meta Event Arena Launch
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25">
                                First Planet Phase 1 Launch
                              </p>
                              {/* <p
                                className="b-left font-18 font-100 white-text line-25"
                                style={{ opacity: '50%' }}
                              >
                                BCL Meta Music Festival
                              </p> */}
                              <p
                                className="b-left font-18 font-100 white-text line-25">
                                AR NFT Integration in Mobile App
                              </p>
                              <p
                                className="b-left font-18 font-100 white-text line-25">
                                NFT Artist Onboarding Release
                              </p>
                              {/* <p
                                className="b-left font-18 font-100 white-text line-25"
                                style={{ opacity: '50%' }}
                              >
                                Exchange Listing
                              </p> */}
                              <p
                                className="b-left font-18 font-100 white-text line-25">
                                Initial Metaverse offering Launch
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="status-top">
                        <div
                          className="status color-change"
                          style={{
                            borderTop: '1px solid rgba(229, 229, 229, 50%)',
                          }}
                        >
                          <div className="box-border bg-bubble bubble-top">
                            <span />
                            <h3 className="font-22 font-300 white-text">
                              Stage 4
                            </h3>
                            <div>
                              <p className="font-18 font-300 white-text mb-md-3 mb-2">
                                Q1 - 2023
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25">
                                Map Editor Launch
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                ETH and LYC Chain Integration
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                OneClick Business Integration for: NFT
                                Marketplace, Office Space, Virtual Classrooms
                                and Conference Rooms
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25">
                                BCL Marketplace V.2.0
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                Brand Collaborations
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                Partner Onboarding for BCL Launchpad
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="status-top">
                        <div
                          className="status color-change"
                          style={{
                            borderTop: '1px solid rgba(229, 229, 229, 50%)',
                          }}
                        >
                          <div className="box-border bg-bubble bubble-top">
                            <span />
                            <h3 className="font-22 font-300 white-text">
                              Stage 5
                            </h3>
                            <div>
                              <p className="font-18 font-300 white-text mb-md-3 mb-2">
                                Q2 - 2023
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                DAO Protocol Launch
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                OneClick Business Integration for: eCommerce,
                                Social, Tourism, Real Estate
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                BCL Mobile App V.2.0 Launch
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                Brand Collaborations
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25">
                                BCL Launchpad with Integrated IMO
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                1st BCL Global Meta Congress
                              </p>
                              <p
                                className="b-left font-18 font-100 white-text line-25"
                                style={{ opacity: '50%' }}
                              >
                                Exchange Listing
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="status-top">
                        <div
                          className="status color-change"
                          style={{
                            borderTop: '1px solid rgba(229, 229, 229, 50%)',
                          }}
                        >
                          <div className="box-border bg-bubble bubble-top">
                            <span />
                            <h3 className="font-22 font-300 white-text">
                              Stage 6
                            </h3>
                            <div>
                              <p className="font-18 font-300 white-text mb-md-3 mb-2">
                                Q3 - 2023
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                Unity SDK Release
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25">
                                Map Editor V2
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                OneClick Business Integrations: As per DAO
                                Governance
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                Annual Audit and Compliances
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                BCL Foundation Setup
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                BCL Capital Fund and Incubator Launch
                              </p>
                              <p className="b-left font-18 font-100 white-text line-25" style={{ opacity: '50%' }}>
                                BCL One Year Anniversary Event
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Roadmap End*/}

        {/*Benchmark Start*/}
        <section className="section-about bg-about">
          {/* <div className="about-tab-img bg-cover" style={{ backgroundImage: 'url("assets/image/mobile-view/benchmark-bg-m.png")' }} /> */}
          <div className="container">
            <div className="row margin_160">
              <div className="col-xl-7 text-md-center text-xl-start">
                <p
                  className="btn-point btn-new text-center d-block font-22 font-400 text-blue mb-23 mx-0 mx-md-auto mx-xl-0"
                  style={{
                    backgroundImage:
                      'url("assets/image/small-elements/btn-white-point.svg")',
                  }}
                >
                  What's New
                </p>
                <h2 className="white-text font-400 mb-23 font--h2 text_upercase">
                  Setting new benchmarks in metaverse concepts
                </h2>
                <p className="font-18 font-100 white-text line-25">
                  Blockchain Land is not just another metaverse. It is a
                  comprehensive solution engineered to be a first-of-its-kind
                  B2B2C model. While giving the freedom to build their own
                  spaces, Blockchain Land also utilizes its own tech expertise
                  to create a complete solution for businesses which are looking
                  to grow in the virtual environment.
                </p>
              </div>
            </div>
            <div className="row mt-58 justify-content-center justify-content-xl-start">
              <div className="col-md-5 col-lg-4 col-xl-3 mb-4 mb-lg-0 d-flex flex-row flex-md-column align-items-start">
                <img
                  src="assets/image/small-elements/new_world.svg"
                  alt="world_new"
                  className="icon_width"
                />
                <div className="ms-md-0 ms-3">
                  <h3 className="font-30 font-300 white-text mt-md-3 mt-0 text_upercase">
                    A new world
                  </h3>
                  <p className="font-18 font-100 white-text mt-md-3 mt-1 line-25">
                    We have the latest update with new world for you to try
                    never mind
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-lg-4 col-xl-3 d-flex flex-row flex-md-column align-items-start">
                <img
                  src="assets/image/small-elements/realastic.svg"
                  alt="world_new"
                  className="icon_width"
                />
                <div className="ms-md-0 ms-3">
                  <h3 className="font-30 font-300 white-text mt-md-3 mt-0 text_upercase">
                    More Realistic
                  </h3>
                  <p className="font-18 font-100 white-text mt-md-3 mt-1 line-25">
                    In the latest update, your eyes are narrow, making the world
                    more realistic than ever
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='container-fluid px-0 d-xl-none d-block'>
            <div className='row'>
              <div className='col-12'>
                <div>
                  <img src="assets/image/mobile-view/benchmark-bg-m.png" alt="about-mobile" className='w-100' />
                </div>
              </div>
            </div>
          </div> */}
        </section>
        {/*Benchmark End*/}

        {/* start */}
        <section className="section-partners pt-0">
          <div className="container">
            <h2 className="white-text font-400 text-center mb-45 font_73">
              Featured in
            </h2>
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-3 col-6">
                <a
                  href="https://www.khaleejtimes.com/kt-engage/the-perfect-world"
                  target="_blank"
                >
                  <img
                    src="assets/image/partners/khaleej_times.svg"
                    alt="partners"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <a
                  href="https://finance.yahoo.com/news/soulbound-citizenship-blockchain-land-030000213.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAACIRxmWKi3sLWMOn3EVBwsShdmHoh57WEyNXYFQxxcuEEiYpSwXvozRfKdeRjKIp8sOOR7BJPjaZM_jmfjvCbPNIJSEfxk1-5uOzyLUi01XlPhnSw3ggkddeiucdf57549eSFjZFhp7v6TKCfeGupTMp6yg7ilwoH9SNBCiEj0mk"
                  target="_blank"
                >
                  <img
                    src="assets/image/partners/Yahoo_finance.svg"
                    alt="partners"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <a
                  href="https://www.lelezard.com/en/news-20400912.html"
                  target="_blank"
                >
                  <img
                    src="assets/image/partners/lelezard.svg"
                    alt="partners"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <a
                  href="https://www.mint-lands.com/mint-lands-news/soulbound-citizenship-on-blockchain-land-yahoo-finance/"
                  target="_blank"
                >
                  <img
                    src="assets/image/partners/Mint_Lands.svg"
                    alt="partners"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <a
                  href="https://www.digitaljournal.com/pr/soulbound-citizenship-on-blockchain-land"
                  target="_blank"
                >
                  <img
                    src="assets/image/partners/digitaljournal.svg"
                    alt="partners"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <a
                  href="https://www.accesswire.com/702457/SoulBound-Citizenship-On-Blockchain-Land"
                  target="_blank"
                >
                  <img
                    src="assets/image/partners/accesswire.svg"
                    alt="partners"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <a
                  href="https://ca.sports.yahoo.com/news/soulbound-citizenship-blockchain-land-030000213.html"
                  target="_blank"
                >
                  <img
                    src="assets/image/partners/yahoo_sport.svg"
                    alt="partners"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <a
                  href="https://www.pr-inside.com/soulbound-citizenship-on-blockchain-land-r4879144.htm"
                  target="_blank"
                >
                  <img
                    src="assets/image/partners/pr_inside.svg"
                    alt="partners"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <a
                  href="http://www.baystreet.ca/viewarticle.aspx?id=702457"
                  target="_blank"
                >
                  <img
                    src="assets/image/partners/baystreet.svg"
                    alt="partners"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* end */}

        {/* crypto index start */}
        <section className="section-partners crypto_index pt-0 mt-0">
          <div className="container">
            <h2 className="white-text font-400 text-center mb-45 font_73">
              Crypto Index
            </h2>
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-3 col-6 mt-0">
                <a
                  href="https://coindiscovery.app/coin/blockchain-land/overview"
                  target="_blank"
                >
                  <img
                    src="assets/image/crypto_index/coindiscovery.svg"
                    alt="coindiscovery"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-0">
                <a
                  href="https://coinhunters.cc/tokens/BlockchainLand"
                  target="_blank"
                >
                  <img
                    src="assets/image/crypto_index/coinhunter.svg"
                    alt="coinhunter"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-0">
                <a
                  href="https://coinmoonhunt.com/coin/Blockchain%20Land"
                  target="_blank"
                >
                  <img
                    src="assets/image/crypto_index/coinmoonhunt.svg"
                    alt="coinmoonhunt"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-0">
                <a
                  href="https://cointoplist.net/coin/blockchain-land"
                  target="_blank"
                >
                  <img
                    src="assets/image/crypto_index/cointoplist.svg"
                    alt="cointoplist"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-lg-0 mt-4">
                <a
                  href="https://coinxhigh.com/coin/blockchain-land-bcl"
                  target="_blank"
                >
                  <img
                    src="assets/image/crypto_index/coinxhigh.svg"
                    alt="coinxhigh"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-lg-0 mt-4">
                <a
                  href="https://freshcoins.io/coins/blockchain-land"
                  target="_blank"
                >
                  <img
                    src="assets/image/crypto_index/freshcoin.svg"
                    alt="freshcoin"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-4">
                <a href="https://gemfinder.cc/gem/11363" target="_blank">
                  <img
                    src="assets/image/crypto_index/gemfinder.svg"
                    alt="gemfinder"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* crypto index end */}

        <Community />

        {/*Footer Start*/}
        <Footer />
        {/*Footer End*/}
      </div>
    </>
  );
}

export default Home;
