import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Community from "./component/Community";

import Footer from "./component/Footer";
import Header from "./component/Header";

export default function Marketplace() {
    return (
        <>
            <Helmet>
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/marketplace.png" />

                <title>Metaverse NFT Marketplace | Blockchain Land</title>
                <meta name="title" content="Metaverse NFT Marketplace | Blockchain Land" />
                <meta name="description"
                    content="Blockchain Land marketplace offers exclusive variety to the users to buy and sell land, estate, avatar wearables, NFTs and names too In the Metaverse." />
                <meta name="keywords" content="Real estate in metaverse, NFTs, Sell NFT, Digital asset, Sell NFT art" />
                <link rel="canonical" href="https://blockchain.land/marketplace" title="marketplace" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blockchain.land/marketplace" />
                <meta property="og:title" content="Metaverse Marketplace | Blockchain.land" />
                <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/marketplace.png" />
                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blockchain.land/marketplace" />
                <meta property="twitter:title" content="Metaverse Marketplace | Blockchain.land" />
                <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>

            <Header />
            {/*Virtual Space Start*/}
            <section className="section-trade mt-200">
                <div className="container">
                    <div className="position-relative">
                        <div className="main-trade position-absolute text-center">
                            <p className="btn-point btn-blue text-center d-inline-block font-20 font-200 white-text mb-13 mt-3" style={{ backgroundImage: 'url("assets/image/small-elements/btn-blue-point.svg")' }}>Marketplace</p>
                            <h1 className="white-text font-400 font-62 mb-23 line-83">
                                The virtual destination for digital assets
                            </h1>
                            <p className="font-18 font-100 white-text mb-23 line-25 w-80 mx-auto">
                                Blockchain Land marketplace offers exclusive variety to the users to buy and sell land, estate, avatar wearables, NFTs, and names too. Users can manifest their creativity and art in the parcel of metaverse or can meet the creativity and feel it in real-time. Here, they can earn Digital assets too.
                            </p>
                            <a href="https://market.blockchain.land/" target="_blank" className="btn-head btn-contain text-center text-white d-inline-block font-16 font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>Start Browsing</a>
                        </div>
                        <img src="assets/image/section-bg/virtual-space-bg.webp" alt="Blockchain Land Metaverse NFT Marketplace" className="w-100" type="image/webp" />
                    </div>
                </div>
            </section>
            {/*Virtual Space End*/}

            {/*Explore Land Start*/}
            <section className="section-explore bg-marketper btn-contain mt-60">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 text-center">
                            <h2 className="white-text font-400 mb-22 position-relative text-center font--h2">
                                Choose Type of Contributor
                            </h2>
                            <p className="font-18 font-100 white-text mb-40 line-25">
                                Users can start their journey in two ways at Blockchain Land. Giant enterprises must register as an enterprise, and artists must register themselves as an artist.
                            </p>
                            <div className='d-flex justify-content-center'>
                                <a href="https://market.blockchain.land/signup" target="_blank" className="btn-head btn-contain font-16 text-center text-dark d-inline-block font-200 mb-2 mb-lg-0" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Register Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="markeprot_card">
                    <img src="assets/image/marketplace/new_card.webp" alt="Blockchain Land Metaverse Enterprise" type="image/webp" />
                    <img src="assets/image/marketplace/new_cardone.webp" alt="Blockchain Land Metaverse Artist" type="image/webp" />
                </div>
            </section>
            {/*Explore Land End*/}

            {/*Explore Land Start*/}
            <section className="section-explore btn-contain mt-60">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 text-center">
                            <h2 className="white-text font-400 mb-22 position-relative text-center font--h2">
                                Buy, Sell, Auction &#38; Mint
                            </h2>
                            <p className="font-18 font-100 white-text mb-40 line-25">
                                In the marketplace, users can organize an auction to buy, sell or mint their 3D assets, digital goods, or property according to their convenience and earn desirable value from it. Here, users can get an advantage of the royalty facility with the Blockchain Land marketplace.
                            </p>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="my-80">
                                <div className="row">
                                    <div className="col-lg-3 col-6 px-md-4 d-none d-md-block">
                                        <img src="assets/image/marketplace/Buy.webp" alt="House" type="image/webp" /></div>
                                    <div className="col-lg-3 col-6 px-md-4 d-none d-md-block">
                                        <img src="assets/image/marketplace/Sell.webp" alt="Weapons" type="image/webp" /></div>
                                    <div className="col-lg-3 col-6 px-md-4 d-none d-md-block mt-lg-0 mt-5">
                                        <img src="assets/image/marketplace/Auction.webp" alt="Historical" type="image/webp" /></div>
                                    <div className="col-lg-3 col-6 px-md-4 d-none d-md-block mt-lg-0 mt-5">
                                        <img src="assets/image/marketplace/Mint.webp" alt="Swords" type="image/webp" /></div>
                                    {/* //////mobile img */}
                                    <div className="col-lg-3 col-6 px-md-4 d-block d-md-none">
                                        <img src="assets/image/marketplace/mobile/mob_house.webp" alt="House" type="image/webp" /></div>
                                    <div className="col-lg-3 col-6 px-md-4 d-block d-md-none">
                                        <img src="assets/image/marketplace/mobile/mob_wea.webp" alt="Weapons" type="image/webp" /></div>
                                    <div className="col-lg-3 col-6 px-md-4 d-block d-md-none mt-md-0 mt-4">
                                        <img src="assets/image/marketplace/mobile/mob_his.webp" alt="Historical" type="image/webp" /></div>
                                    <div className="col-lg-3 col-6 px-md-4 d-block d-md-none mt-md-0 mt-4">
                                        <img src="assets/image/marketplace/mobile/mob_swo.webp" alt="Swords" type="image/webp" /></div>
                                </div>
                            </div>
                            {/* <div>
                                <div className='d-flex btn_height'>
                                    <Link to={"/"} className="btn-head btn-contain font-16 text-center white-text mx-auto d-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>Visit</Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {/*Explore Land End*/}

            {/*Explore Land Start*/}
            <section className="section-explore bg-metanft btn-contain margintop_200 position-relative nftmarket mb-mobile">
                {/* bg-metanft */}
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-lg-6 col-12 d-sm-block d-md-none order-2 order-md-1 px-0">
                            <img src="assets/image/mobile-view/nft.webp" alt="Virtual Reality" type="image/webp" />
                        </div>
                        <div className="col-lg-5 col-md-6 order-1 order-md-2 space_mobile">
                            <h2 className="white-text font-400 mb-22 font--h2">
                                Metaverse NFT
                            </h2>
                            <p className="font-18 font-100 white-text line-25">
                                The metaverse is a virtual representation of the world. Metaverse NFT is a part of the parcel. Popular creators or enterprises can mint, buy, sell or auction that without any interference.
                            </p>
                            <div className='nft_bottomimg'>
                                <img src="assets/image/marketplace/nft.webp" alt="3d Virtual Studio" type="image/webp" />
                                {/* <img src="assets/image/marketplace/mobile_nft.png" alt="img" className="d-block d-md-none" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Explore end Start*/}

            {/*Listed Start*/}
            <div className='mb-5 mt-235'>
                <div className='container px-md-3 px-4'>
                    <div className="row">
                        <div className="col-md-12 text-md-center text-xl-start">
                            <h3 className="white-text font-400 font_73 text_upercase">
                                Listed Blockchains
                            </h3>
                            <p className="font-18 font-100 white-text mt-24 line-25">
                                Blockchain Land is a peer-to-peer network supported by very powerful, unshakable, and secure blockchains like ETH, BNB and LYC handled without any intermediation of third parties.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-60">
                        {/*Second Start*/}
                        <div className="col-md-12 col-xl-6">
                            <div className="bg-card-02 bg-content bg-cover mobile-bnb">
                                <div className="listed-content">
                                    <h4 className="font-33 font-400 white-text">BNB</h4>
                                    {/* <p className="font-16 font-300 white-text">Total Assets : 1</p> */}
                                </div>
                                <div className="d-flex align-items-center b-content">
                                    <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/listed-dot.svg" alt="img" />1 Planet</p>
                                    <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/listed-dot.svg" alt="img" />713 Country</p>
                                    <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/listed-dot.svg" alt="img" />4220 Valley</p>
                                </div>
                            </div>
                        </div>
                        {/*Second End*/}
                        {/*First Start*/}
                        <div className="col-md-12 col-xl-6 mt-4 mt-xl-0">
                            <div className="bg-card-01 bg-content bg-cover mobile-eth">
                                <div className="listed-content">
                                    <h4 className="font-33 font-400 white-text">Ethereum</h4>
                                    {/* <p className="font-16 font-300 white-text">Total Assets : 0</p> */}
                                </div>
                                <div className="d-flex align-items-center b-content">
                                    {/* <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/listed-dot.svg" alt="img"  />1 Country</p>
                    <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/listed-dot.svg" alt="img"  />1 Planet</p>
                    <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/listed-dot.svg" alt="img"  />2 Valley</p> */}
                                    <p className="font-16 font-300 text-custom-dark d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/listed-dot.svg" alt="img" />Coming Soon</p>
                                </div>
                            </div>
                        </div>
                        {/*First Start*/}
                        {/*Three Start*/}
                        <div className="col-md-12 col-xl-6 mt-40">
                            <div className="bg-card-03 bg-content bg-cover mobile-lyc">
                                <div className="listed-content">
                                    <h4 className="font-33 font-400 white-text">Lycan</h4>
                                    {/* <p className="font-16 font-300 white-text">Total Assets : 0</p> */}
                                </div>
                                <div className="b-content">
                                    <p className="font-16 font-300 white-text d-flex align-items-center mr-55">
                                        <img className="mr-10" src="assets/image/side-img/listed-card/white-dot.svg" alt="img" title='global' />Coming Soon</p>
                                </div>
                            </div>
                        </div>
                        {/*Three End*/}
                        {/*Fourth Start*/}
                        {/* <div className="col-md-10 col-lg-6 mt-40">
                <div className="bg-card-04 bg-content bg-cover mobile-dot">
                  <div className="listed-content">
                    <h4 className="font-33 font-400 white-text">Fantom</h4>
                    <p className="font-16 font-300 white-text">Total Assets : 0</p>
                  </div>
                  <div className="b-content">
                    <p className="font-16 font-300 white-text d-flex align-items-center mr-55"><img className="mr-10" src="assets/image/side-img/listed-card/white-dot.svg" alt="img" />Coming Soon</p>
                  </div>
                </div>
              </div> */}
                        {/*Fourth End*/}
                    </div>
                </div>
            </div>
            {/*Listed End*/}

            {/* section community start */}
            <Community />
            {/* section community end */}
            <Footer />
        </>
    );
}