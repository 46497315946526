import Footer from "./component/Footer";
import Header from "./component/Header";
import Hero from "./component/Hero";



export default function Demo() {

    return (
        <>

            <Header />
            <Hero />

            {/* <Footer /> */}

        </>
    );
}