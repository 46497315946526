import React from "react";

export default function Community() {
    return (
        <>
            {/* section community start */}
            <section className="section-community">
                <div className="container">
                    <div className="bg-community bg-cover position-relative community-padd">
                        <div className="row">
                            <div className="col-xl-8 text-md-center text-xl-start">
                                <img src="assets/image/blockchain_land/community-bg-m.webp" alt="Blockchain Land Metaverse Community" className="d-block d-xl-none community-mobile" type="image/webp" />
                                <div className="mobile-my">
                                    <h2 className="white-text font-400 mb-20 community_title">Join Our Community
                                    </h2>
                                    <p className="font-18 font-100 white-text mb-md-4 mb-3 line-25 w-80">
                                        In metaverse, imagine something good with endless possibilities of virtual reality and Link with the future to Build an Innovative legacy by joining us
                                    </p>
                                    <a href="/joincommunity" className="btn-head btn-contain font-16 text-center text-white d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>Join Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-xl-8 col-12">
                            <div className="flex-wrap d-flex align-items-center justify-content-xl-between justify-content-md-center mt-0 mt-xl-4 social_margin removespace">
                                <a href="https://www.youtube.com/channel/UCyM4Z0ZDNpcB7Cjr14Q0G3A" target="_blank" className="me-4 me-md-3 me-xl-0">
                                    <img src="assets/image/social/bg-youtube.svg" alt="bg-youtube" className="social_media_width" />
                                </a>
                                <a href="https://twitter.com/land_blockchain" target="_blank" className="me-4 me-md-3 me-xl-0">
                                    <img src="assets/image/social/bg-twitter.svg" alt="bg-twitter" className="social_media_width" />
                                </a>
                                <a href="https://t.me/BlockchainLandOfficial" target="_blank" className="me-4 me-md-3 me-xl-0">
                                    <img src="assets/image/social/bg-telegram.svg" alt="bg-telegram" className="social_media_width" />
                                </a>
                                <a href="https://blockchainland.medium.com/" target="_blank" className="me-4 me-md-3 me-xl-0">
                                    <img src="assets/image/social/bg-medium.svg" alt="bg-medium" className="social_media_width" />
                                </a>
                                <a href="https://www.linkedin.com/company/blockchain-land/" target="_blank" className="mt-4 mt-md-0 me-4 me-md-3 me-xl-0">
                                    <img src="assets/image/social/bg-linkedin.svg" alt="bg-linkedin" className="social_media_width" />
                                </a>
                                <a href="https://www.facebook.com/Blockchain-Land-103061128803396" target="_blank" className="mt-4 mt-md-0 me-4 me-md-3 me-xl-0">
                                    <img src="assets/image/social/bg-fb.svg" alt="bg-fb" className="social_media_width" />
                                </a>
                                <a href="https://www.instagram.com/theblockchainland/" target="_blank" className="mt-4 mt-md-0 me-4 me-md-3 me-xl-0">
                                    <img src="assets/image/social/bg-instagram.svg" alt="bg-instagram" className="social_media_width" />
                                </a>
                                <a href="https://discord.com/invite/8hZVg9ACNz" target="_blank" className="mt-4 mt-md-0 mt-lg-0 me-0" >
                                    <img src="assets/image/social/bg-discord.svg" alt="bg-discord" className="social_media_width" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section community end */}
        </>
    );
}