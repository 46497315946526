import React from "react";
import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

function Airdrop() {
    return (
        <>
            <div>
                <Helmet>
                    <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                    <title>Metaverse NFT Airdrop | Blockchain Land</title>
                    <meta name="title" content="Metaverse NFT Airdrop | Blockchain Land" />
                    <meta name="description" content="Blockchain Land provides a variety of NFTs. Which can be designed, minted, bought, sold, or auctioned in the metaverse by users." />
                    <meta name="keywords" content="Non-Fungible token" />
                    <link rel="canonical" href="https://blockchain.land/airdrop" title="airdrop" />

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://blockchain.land/airdrop" />
                    <meta property="og:title" content="Metaverse NFT | Blockchain.land" />
                    <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                    <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://blockchain.land/airdrop" />
                    <meta property="twitter:title" content="Metaverse NFT | Blockchain.land" />
                    <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                    <meta name="reply-to" content="blockchain.land" />
                    <meta name="robots" content="index, Follow" />
                    <meta name="googlebot" content="index, follow" />
                    <meta name="revisit-after" content="7 day" />
                    <meta name="rating" content="GENERAL" />
                    <meta name="language" content="English" />
                    <meta name="distribution" content="Global" />
                    <meta name="copyright" content="blockchain.land" />
                    <link rel="publisher" href="https://blockchain.land/" />
                    <link rel="author" href="https://blockchain.land/" />
                </Helmet>
                {/* Header Start */}
                <Header />
                {/* Header End */}
                {/* section airdrop start */}
                <section className="section-airdrop bg-airdrop mt--100 pt--400 airdrop-height">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-lg-6">
                                <div className=" text-center text-sm-start">
                                    <h1 className="white-text font-200 mb--33">
                                        Metaverse NFT Airdrop
                                    </h1>
                                    <p className="font-18 font-100 white-text mb--40">
                                        Welcome to the new world. Here we are presenting the exclusive NFT Airdrop for our metaverse community.
                                    </p>
                                    <a className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Airdrop Ended</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section airdrop end */}
                {/* section token start */}
                <section className="section-token">
                    <div className="container">
                        <span className="font-20 font-300 text-blue mb--66 d-block text-center text-md-start">Non-Fungible token</span>
                    </div>
                    <marquee behavior="alternate" direction="left" scrollamount={15} onmouseover="this.stop();" onmouseout="this.start();">
                        <div className="token-left mb--33">
                            <img src="/assets/image/airdrop/cube/cube1.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube2.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube3.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube4.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube5.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube6.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube7.webp" alt="NFT" type="image/webp" />
                        </div>
                    </marquee>
                    <marquee behavior="alternate" direction="right" scrollamount={15} onmouseover="this.stop();" onmouseout="this.start();">
                        <div className="token-left">
                            <img src="/assets/image/airdrop/cube/cube8.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube9.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube10.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube11.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube12.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube13.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube14.webp" alt="NFT" type="image/webp" />
                            <img src="/assets/image/airdrop/cube/cube15.webp" alt="NFT" type="image/webp" />
                        </div>
                    </marquee>
                </section>
                {/* section token end */}
                {/* section worth start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center text-sm-start">
                                <h2 className="font-130 font-200 white-text mb--50"><span className="text-blue d-block mb-2 mb-lg-4">$ 120000
                                </span>Worth of NFT
                                </h2>
                                <p className="font-18 font-100 white-text">
                                    Now, people in our metaverse community are eligible to claim exclusive NFTs and join our community. Airdrop winners will be rewarded with 24 unique NFT cubes, each of them individually has 100 in quantity. So, there is a total giveaway of 2400 NFT of $120000. These cube holders are equally eligible to sell their NFT cubes in our huge marketplace. Blockchain Land will also allow them to buy virtual land in the metaverse. You can buy your land in this unique universe and also can build your world there with your rewarded cubes.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section worth end */}
                {/* section step start */}
                <section className="section-step bg-step">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-6">
                                <span className="font-25 font-300 white-text text-center text-sm-start d-block">It's Live
                                </span>
                                <h2 className="font-200 white-text mt--15 mb--62 text-center text-sm-start">
                                    Steps to Participate in the Airdrop
                                </h2>
                                <a href="https://twitter.com/land_blockchain" target="_blank">
                                    <div className="d-flex justify-content-between align-items-center step-bt step-bb step-padd w-100">
                                        <p className="font-16 font-300 white-text">Step - 1</p>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/image/airdrop/social-step/twitter.svg" alt="twitter" className="airdrop_social" />
                                            <p className="font-16 font-300 white-text ms-lg-3 ms-2">Twitter Follow</p>
                                        </div>
                                        {/* <div className="social_svg">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="29.707" height="11.414" viewBox="0 0 29.707 11.414">
                                                <g id="Group_13098" data-name="Group 13098" transform="translate(-423.5 -2745.793)" opacity="0.2">
                                                    <line id="Line_36" data-name="Line 36" x2={29} transform="translate(423.5 2751.5)" fill="none" stroke="#e5e5e5" strokeWidth={1} />
                                                    <line id="Line_37" data-name="Line 37" y1={5} x2={5} transform="translate(447.5 2751.5)" fill="none" stroke="#e5e5e5" strokeLinecap="square" strokeWidth={1} />
                                                    <line id="Line_38" data-name="Line 38" x2={5} y2={5} transform="translate(447.5 2746.5)" fill="none" stroke="#e5e5e5" strokeLinecap="square" strokeWidth={1} />
                                                </g>
                                            </svg>
                                        </div> */}
                                    </div>
                                </a>
                                <a href="https://twitter.com/land_blockchain" target="_blank">
                                    <div className="d-flex justify-content-between align-items-center step-bb step-padd w-100">
                                        <p className="font-16 font-300 white-text">Step - 2</p>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/image/airdrop/social-step/twitter.svg" alt="twitter" className="airdrop_social" />
                                            <p className="font-16 font-300 white-text ms-lg-3 ms-2">Retweet A Tweet</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://www.instagram.com/theblockchainland/" target="_blank">
                                    <div className="d-flex justify-content-between align-items-center step-bb step-padd w-100">
                                        <p className="font-16 font-300 white-text">Step - 3</p>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/image/airdrop/social-step/instagram.svg" alt="instagram" className="airdrop_social" />
                                            <p className="font-16 font-300 white-text ms-lg-3 ms-2">Instagram Follow</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://t.me/BlockchainLandOfficial" target="_blank">
                                    <div className="d-flex justify-content-between align-items-center step-bb step-padd w-100">
                                        <p className="font-16 font-300 white-text">Step - 4</p>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/image/airdrop/social-step/telegram.svg" alt="telegram" className="airdrop_social" />
                                            <p className="font-16 font-300 white-text ms-lg-3 ms-2">Telegram Join Channel</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://blockchainland.medium.com/" target="_blank">
                                    <div className="d-flex justify-content-between align-items-center step-bb step-padd w-100">
                                        <p className="font-16 font-300 white-text">Step - 5</p>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/image/airdrop/social-step/medium.svg" alt="medium" className="airdrop_social" />
                                            <p className="font-16 font-300 white-text ms-lg-3 ms-2">Leave A Clap On A Medium Article</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://blockchain.land/" target="_blank">
                                    <div className="d-flex justify-content-between align-items-center step-bb step-padd w-100">
                                        <p className="font-16 font-300 white-text">Step - 6</p>
                                        <div className="d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ffffff" className="bi bi-globe airdrop_social" viewBox="0 0 16 16">
                                                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                                            </svg>
                                            <p className="font-16 font-300 white-text ms-lg-3 ms-2">Visit A Page</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://www.youtube.com/channel/UCyM4Z0ZDNpcB7Cjr14Q0G3A" target="_blank">
                                    <div className="d-flex justify-content-between align-items-center step-bb step-padd w-100">
                                        <p className="font-16 font-300 white-text">Step - 7</p>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/image/airdrop/social-step/youtube.svg" alt="medium" className="airdrop_social" />
                                            <p className="font-16 font-300 white-text ms-lg-3 ms-2">YouTube Visit Channel</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="" target="_blank">
                                    <div className="d-flex justify-content-between align-items-center step-bb step-padd w-100">
                                        <p className="font-16 font-300 white-text">Step - 8</p>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/image/airdrop/social-step/bnb.svg" alt="medium" className="airdrop_social" />
                                            <p className="font-16 font-300 white-text ms-lg-3 ms-2">Binance Smart Chain address</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://discord.com/invite/8hZVg9ACNz" target="_blank">
                                    <div className="d-flex justify-content-between align-items-center step-bb step-padd w-100">
                                        <p className="font-16 font-300 white-text">Step - 9</p>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/image/airdrop/social-step/discard.svg" alt="discard" className="airdrop_social" />
                                            <p className="font-16 font-300 white-text ms-lg-3 ms-2">Discord Join Server</p>
                                        </div>
                                    </div>
                                </a>
                                {/* <a href="/" target="_blank" className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Participate Now</a> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* section step end */}
                {/* section lucky winner start */}
                <section className="section-lucky">
                    <div className="container">
                        <h2 className="font-200 white-text mb--70 text-center text-sm-start">Lucky Winner</h2>
                        <div className="row">
                            <div className="col-md-6 col-xl-3">
                                <div className="lucky_main cube_one bg-contain position-relative">
                                    <div className="main_winner">
                                        <h3 className="font-40 font-200 white-text">50</h3>
                                        <p className="font-20 font-300 white-text">Lucky Winner</p>
                                    </div>
                                    <div className="winner_bottom d-flex align-items-center">
                                        <img src="assets/image/airdrop/lucky/cube-white.webp" alt="cube-white" type="image/webp" />
                                        <p className="font-20 font-300 ml--12 text-white">24 Cube</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3">
                                <div className="lucky_main cube_two bg-contain position-relative">
                                    <div className="main_winner">
                                        <h3 className="font-40 font-200 white-text">50</h3>
                                        <p className="font-20 font-300 white-text">Lucky Winner</p>
                                    </div>
                                    <div className="winner_bottom d-flex align-items-center">
                                        <img src="assets/image/airdrop/lucky/cube-black.webp" alt="cube-white" type="image/webp" />
                                        <p className="font-20 font-300 ml--12 text-custom-dark">12 Cube</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3">
                                <div className="lucky_main cube_two bg-contain position-relative">
                                    <div className="main_winner">
                                        <h3 className="font-40 font-200 white-text">100</h3>
                                        <p className="font-20 font-300 white-text">Lucky Winner</p>
                                    </div>
                                    <div className="winner_bottom d-flex align-items-center">
                                        <img src="assets/image/airdrop/lucky/cube-black.webp" alt="cube-white" type="image/webp" />
                                        <p className="font-20 font-300 ml--12 text-custom-dark">5 Cube</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3">
                                <div className="lucky_main cube_three bg-contain position-relative">
                                    <div className="main_winner">
                                        <h3 className="font-30 font-200 white-text">Other <br /> Participate</h3>
                                    </div>
                                    <div className="winner_bottom d-flex align-items-center">
                                        <img src="assets/image/airdrop/lucky/cube-white.webp" alt="cube-white" type="image/webp" />
                                        <p className="font-20 font-300 ml--12 text-white">2 Cube</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section lucky winner end */}

                <Community />

                {/*Footer Start*/}
                <Footer />
                {/*Footer End*/}
            </div>
        </>
    );
}

export default Airdrop;