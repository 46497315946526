import React from "react";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function Blog_Ambassador() {

    return (
        <>
            <Header />

            {/* section blog start */}
            <section className="section-global mt--100 mb-100 pt--100">
                <div className="container">
                    <div className="row mb-md-0 mb-4">
                        <div className="col-lg-7">
                            <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                            <h1 className="font-40 text-white citizenship_h2 mt-4 font-300">Blockchain Land’s Ambassador Program for Web3 Influencers is now live!</h1>
                        </div>
                    </div>
                    <div className="row mt-60">
                        <div className="col-12 mb-md-0 mb-4">
                            <img src="/assets/image/blog/ambassador_blog.png" alt="ambassador_blog" className="card-img-top" />
                        </div>
                        <div className="col-12 mt-60">
                            <p className="font-20 font-100 color_body_text">We’re launching our Metaverse Ambassador Program and looking for Web3 focused individuals who are not only educating but at an influential level to enable masses to join the Web3 revolution. The aim is to not only use a strong word of mouth marketing strategy but allow these ambassadors as an integrated part of our community and become more involved with Blockchain Land and help us to widen our audience.</p>
                        </div>
                        <div className="col-12 mt-40">
                            <h2 className="font-25 font-300 text-white">What is the Blockchain Land Ambassador Program?</h2>
                            <p className="font-20 font-100 color_body_text mt-40">It is a complex referral system to facilitate influencer promotions for mass user adoptions and participation in Blockchain Land. As mentioned earlier, the key goal of the Blockchain Land Ambassador program is to enable these web3 passionate influencers and give them the tools to not only grow their audiences worldwide but also help those users with a simple gateway to the future with Blockchain Land.</p>
                            <p className="font-20 font-100 color_body_text mt-40"><span className="text-white">To join the Ambassador Program, you’d have to be a citizen of Blockchain Land first.</span> To communicate our unique value proposition effectively, we expect them to be already familiar with our comprehensive Meta Solution.</p>
                            <p className="ambassador_bg font-20 mt-40">"Anyone can be an influencer"</p>
                        </div>
                        <div className="col-12 mt-40">
                            <h2 className="font-25 font-300 text-white">What do we mean by Ambassador?</h2>
                            <p className="font-20 font-100 color_body_text mt-40">As a community-driven program, Blockchain Land’s Ambassador Program aims to introduce the brand to everyone in Web3. Blockchain Land is the single most comprehensive solution for Businesses looking to scale up in Virtual Space, whether they are existing Web3 Projects or Web2 projects looking for a Meta Solution.</p>
                            <p className="font-20 font-100 color_body_text mt-40 mb-40">As an Ambassador, one of the most important things is to create excitement among the follower base and secondly amongst the BCL community. For some, this excitement will come from content creation, collectible items. For many more, there will be giveaways, treasure hunts, events and much more.</p>
                        </div>
                        <div className="col-12 mt-40">
                            <h2 className="font-25 font-300 text-white">How to become an Ambassador?</h2>
                            <p className="font-20 font-100 color_body_text mt-40">Firstly make sure you’ve got your Blockchain Land Citizenship and then you have to apply to be an ambassador by filling out a form. After a careful evaluation and due diligence, we will notify you on your presented contact information about the various synergies.</p>
                            <p className="font-20 font-100 color_body_text mt-40 mb-40">Amongst others, The ambassadors will educate their community about Blockchain Land with the given unique URL to them and empower these followers to become a citizen of the Blockchain Land Metaverse. The Ambassador being a social influencer, will also promote Blockchain Land on various communities/channels (Socials like Instagram, Twitter, Discord, Telegram, Reddit, Streamers on YouTube, Twitch, Bloggers, and Podcasters…), and even at times curating content (visual or written) to broaden awareness about Blockchain Land.</p>
                        </div>
                        <div className="col-12 mt-40">
                            <h2 className="font-25 font-300 text-white">Benefits of the Ambassador Program:</h2>
                            <p className="font-20 font-100 color_body_text mt-40">Depending on the contribution of our Ambassadors within our community and the milestones achieved by them as an ambassador, they will be rewarded with exclusive benefits of our ecosystem. Ambassadors can unlock their own valley of a 100 parcels in Blockchain Land Metaverse as a reward when they onboard 100 new citizens and if they want to keep going then they can claim a whole Metaverse Country of 625 parcels when they onboard 600 citizens on Blockchain Land.</p>
                            <p className="font-20 font-100 color_body_text mt-40 mb-40">A leader board will be created based on the ambassador’s performance. The most dedicated ambassador will be given different kinds of rewards like expensive NFTs, NFT launchpad slots to present their own NFT, etc in recognition of outstanding contributions to our community through viral content or excellent engagement!</p>
                            <p className="font-20 font-100 color_body_text mt-40">If you’ve got what it takes, join this meta revolution of Web3 on Blockchain Land. <a href="javascript:void(0);" className="text-white text_underline">Apply Here</a></p>
                            <p className="ambassador_bg font-20 mt-40">Be part of this virtual universe, Get to know more about us:</p>
                        </div>
                        <div className="col-12 mt-40">
                            <a href="javascript:void(0);" target={"_blank"} className="text_underline font-20 font-300 text-white">Instagram</a>
                            <img src="/assets/image/blog/square.svg" alt="square" className="px-lg-3 px-2" />
                            <a href="javascript:void(0);" target={"_blank"} className="text_underline font-20 font-300 text-white">Facebook</a>
                            <img src="/assets/image/blog/square.svg" alt="square" className="px-lg-3 px-2" />
                            <a href="javascript:void(0);" target={"_blank"} className="text_underline font-20 font-300 text-white">Twitter</a>
                            <img src="/assets/image/blog/square.svg" alt="square" className="px-lg-3 px-2" />
                            <a href="javascript:void(0);" target={"_blank"} className="text_underline font-20 font-300 text-white">LinkedIn</a>
                            <img src="/assets/image/blog/square.svg" alt="square" className="px-lg-3 px-2" />
                            <a href="javascript:void(0);" target={"_blank"} className="text_underline font-20 font-300 text-white">Telegram</a>
                            <img src="/assets/image/blog/square.svg" alt="square" className="px-lg-3 px-2" />
                            <a href="javascript:void(0);" target={"_blank"} className="text_underline font-20 font-300 text-white">Youtube</a>
                            <img src="/assets/image/blog/square.svg" alt="square" className="px-lg-3 px-2" />
                            <a href="javascript:void(0);" target={"_blank"} className="text_underline font-20 font-300 text-white">Discord</a>
                        </div>
                        <div className="col-12 mt-40">
                            <p className="font-20 font-100 color_body_text">From</p>
                            <p className="font-20 font-100 color_body_text mt-40">Team Blockchain Land</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* section blog end */}

            <Community />
            <Footer />
        </>
    );
}