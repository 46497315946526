const chainIds = {
    'MAINNET': 4,
    'BSC': 97,
}

const scanUrls = {
    'MAINNET': 'https://etherscan.io/',
    'BSC': 'https://bscscan.com/'
}

const NETWORK_CONFIG = {
    [chainIds.MAINNET]: {
        name: 'Ethereum Mainnet',
        rpc: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        scanURL: scanUrls.MAINNET,
    },
    [chainIds.BSC]: {
        name: 'BNB Smart Chain Testnet',
        rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        scanURL: scanUrls.BSC,
    },
}

const nativeCurrenciesByChainId = {
    [chainIds.MAINNET]: {
        'name': 'ETH',
        'symbol': 'eth',
        'decimals': 18
    },
    [chainIds.BSC]: {
        'name': 'BNB',
        'symbol': 'bnb',
        'decimals': 18
    }
}

export const setupNetwork = async (externalProvider, chainSymbol) => {
    const provider = externalProvider || window.ethereum
    const chainId = parseInt(chainIds[chainSymbol])
    if (provider) {
        try {
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${chainId.toString(16)}` }],
            })
            return true
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: `0x${chainId.toString(16)}`,
                                chainName: NETWORK_CONFIG[chainId].name,
                                nativeCurrency: {
                                    name: nativeCurrenciesByChainId[chainId].name,
                                    symbol: nativeCurrenciesByChainId[chainId].symbol,
                                    decimals: nativeCurrenciesByChainId[chainId].decimals,
                                },
                                rpcUrls: NETWORK_CONFIG[chainId].rpc,
                                blockExplorerUrls: [`${NETWORK_CONFIG[chainId].scanURL}/`],
                            },
                        ],
                    })
                    return true
                } catch (error) {
                    console.error('Failed to setup the network in Metamask:', error)
                    return false
                }
            }
            return false
        }
    } else {
        console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
        return false
    }
}