import { useWeb3React } from "@web3-react/core";
import React from "react";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";
import { injected } from "./connectors";
import useToast from "./hooks/useToast";
import { tokenAdded } from "./setupNetwork";
import { UnsupportedChainIdError } from '@web3-react/core'
import { Helmet } from "react-helmet";

function Token() {

    const {
        connector,
        chainId,
        account,
        activate,
        deactivate,
        active,
        error,
        library,
    } = useWeb3React();
    const { toastError, toastSuccess } = useToast()

    // const addToken = async () => {
    //     if (!active) {
    //         console.log('hrer')
    //         await activate(injected).then(res => toke)
    //     }
    //     await tokenAdded(chainId, toastError, active, activate)
    // }

    // const tokenAdded = async () => {
    //     try {
    //         if(chainId === 56) {
    //             await activate(injected)
    //             await window.ethereum.request({
    //                 method: 'wallet_watchAsset',
    //                 params: {
    //                     type: 'ERC20',
    //                     options: {
    //                         address: '0x471A5e862af35D2148bd8b505b361b1DDf5fFef1',
    //                         symbol: 'BCL',
    //                         decimals: 18,
    //                         image: 'https://blockchain.land/assets/logos/Blockchainland32.png',
    //                     },
    //                 },
    //             })
    //         } else if(chainId !== 56 && active) {
    //             toastError('change chain')
    //         }

    //     } catch (error) {
    //         console.log(error)
    //     }

    // }

    const tokenAdded = async () => {
        if (error instanceof UnsupportedChainIdError) {
            toastError('Please select Binace smart chain')
        } else {
            await activate(injected)

            await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: '0x471A5e862af35D2148bd8b505b361b1DDf5fFef1',
                        symbol: 'BCL',
                        decimals: 18,
                        image: 'https://blockchain.land/assets/logos/Blockchainland32.png',
                    },
                },
            })
        }
    }

    // const addToken = async () => {

    //     if(!active) {
    //         await activate(injected)
    //         if(chainId === 56) {
    //             toastError('please select mainnet')
    //         } else {
    //             toastError('asdk')
    //         }
    //     }

    // if(!active) await activate(injected)
    // if(chainId !== 56)  toastError('please select mainnet')
    // if(chainId === 56)  await addToken()

    // }

    const addNetwork = async () => {
        console.log('first')
        const provider = window.ethereum
        const chainId = 56
        const name = "Binance smart chain"
        const currName = "BNB"
        const symbol = "BNB"
        const decimals = 18
        const rpc = "https://bsc-dataseed1.binance.org/"
        const scanUrl = "https://bscscan.com/"

        if (provider) {
            try {
                await provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: `0x${chainId.toString(16)}` }],
                })
                return true
            } catch (switchError) {
                if (switchError.code === 4001) {

                }
                if (switchError.code === 4902) {
                    try {
                        await provider.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: `0x${chainId.toString(16)}`,
                                    chainName: name,
                                    nativeCurrency: {
                                        name: currName,
                                        symbol: symbol,
                                        decimals: decimals,
                                    },
                                    rpcUrls: [rpc],
                                    blockExplorerUrls: [scanUrl],
                                },
                            ],
                        })
                        return true
                    } catch (error) {
                        console.error('Failed to setup the network in Metamask:', error)
                        return false
                    }
                }
                return false
            }
        } else {
            console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
            return false
        }
    }

    return (
        <>
            <div>
                <Helmet>
                    <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                    <title>BCL Token Details | Blockchain Land</title>
                    <meta name="title" content="BCL Token Details | Blockchain Land" />
                    <meta name="description" content="BCL token used throughout the Blockchain land Metaverse ecosystem as the basis for transactions and interactions, click here for more information about BCL." />
                    <meta name="keywords" content="BCL Token, Token details, crypto, cryptocurrency, BCL, Blockchain land, digital currency, Tokenomics" />
                    <link rel="canonical" href="https://blockchain.land/token" title="token" />

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://blockchain.land/token" />
                    <meta property="og:title" content="BCL Token Details | Blockchain.land" />
                    <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                    <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://blockchain.land/token" />
                    <meta property="twitter:title" content="BCL Token Details | Blockchain.land" />
                    <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                    <script type="application/ld+json" src="https://blockchain.land/assets/js/token.json"></script>

                    <meta name="reply-to" content="blockchain.land" />
                    <meta name="robots" content="index, Follow" />
                    <meta name="googlebot" content="index, follow" />
                    <meta name="revisit-after" content="7 day" />
                    <meta name="rating" content="GENERAL" />
                    <meta name="language" content="English" />
                    <meta name="distribution" content="Global" />
                    <meta name="copyright" content="blockchain.land" />
                    <link rel="publisher" href="https://blockchain.land/" />
                    <link rel="author" href="https://blockchain.land/" />
                </Helmet>
                {/* Header Start */}
                <Header />
                {/* Header End */}
                {/* section token details start */}
                <section className="bg-detail mt--100 pt--400">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="text-center text-sm-start">
                                    <h1 className="white-text font-200 mb--25">Token Details</h1>
                                    <p className="font-18 font-100 white-text mb-xl-3">
                                        BCL is the utility token used throughout the Blockchain Land ecosystem as the basis for transactions and interactions.
                                    </p>
                                    <p className="font-18 font-100 white-text mb--52">
                                        It is a BEP-20 utility token built on the Binance smart chain.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="token_part d-flex justify-content-between step-bt step-bb">
                                    <p className="font-18 font-300 white-text">Token name</p>
                                    <p className="font-18 font-300 white-text">Blockchain Land</p>
                                </div>
                                <div className="token_part d-flex justify-content-between step-bb">
                                    <p className="font-18 font-300 white-text">
                                        Ticker
                                    </p>
                                    <p className="font-18 font-300 white-text">BCL</p>
                                </div>
                                <div className="token_part d-flex justify-content-between step-bb">
                                    <p className="font-18 font-300 white-text">Type</p>
                                    <p className="font-18 font-300 white-text">BEP20</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="token_part d-flex justify-content-between step-bt step-bb">
                                    <p className="font-18 font-300 white-text">Token contract</p>
                                    <a href="https://bscscan.com/address/0x471a5e862af35d2148bd8b505b361b1ddf5ffef1#code" target="_blank" className="font-18 font-300 token_contract text-blue">0x471a5e862af35d2148bd8b505b361b1ddf5ffef1</a>
                                </div>
                                <div className="token_part d-flex justify-content-between step-bb">
                                    <p className="font-18 font-300 white-text">Total supply</p>
                                    <p className="font-18 font-300 white-text">
                                        7,210,000,000 BCL
                                    </p>
                                </div>
                                <div className="token_part d-flex justify-content-between step-bb">
                                    <p className="font-18 font-300 white-text">Decimal</p>
                                    <p className="font-18 font-300 white-text">18</p>
                                </div>
                            </div>
                            {/* <div className="d-flex btn_height justify-content-md-start justify-content-center mt-3">
                                <a onClick={() => addToken()} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("/assets/image/btn-header.svg")', cursor: 'pointer' }}>Add to Metamask</a>
                            </div> */}
                            <div className="d-flex btn_height justify-content-md-start justify-content-center mt-4">
                                <a onClick={() => tokenAdded()} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("/assets/image/btn-header.svg")', cursor: 'pointer' }}>Add to Metamask</a>

                                <a onClick={() => addNetwork()} className="btn-head btn-contain font-16 text-center text-white d-inline-block font-200 ms-3"
                                    style={{ backgroundImage: 'url("assets/image/btn-bg/btn-black.svg")' }}>Add Network</a>
                            </div>
                        </div>

                        <div className="mt-5">
                            <div className="step-bb step-bt d-flex align-items-md-center justify-content-between contract_bg flex-md-row flex-column px-md-3 px-1 py-3">
                                <p className="contract_p d-md-block d-none">Contract Name</p>
                                <p className="contract_p">Contract Address</p>
                            </div>
                            <div className="step-bb d-flex align-items-md-center justify-content-between flex-md-row flex-column px-md-3 px-1 py-3">
                                <p className="text-white font-300">Register Citizenship</p>
                                <a href="https://bscscan.com/address/0x0Cb49b5cfDC79b4511e5A358e6863db6591398F6" target={"_blank"} className="contract_a font-300 text-blue text-decoration-underline">0x0Cb49b5cfDC79b4511e5A358e6863db6591398F6</a>
                            </div>
                            <div className="step-bb d-flex align-items-md-center justify-content-between flex-md-row flex-column px-md-3 px-1 py-3">
                                <p className="text-white font-300">Swap</p>
                                <a href="https://bscscan.com/address/0x425c9c34d3172955a612e8aa15a94d4eb5250b8e" target={"_blank"} className="contract_a font-300 text-blue text-decoration-underline">0x425c9c34d3172955a612e8aa15a94d4eb5250b8e</a>
                            </div>
                            <div className="step-bb d-flex align-items-md-center justify-content-between flex-md-row flex-column px-md-3 px-1 py-3">
                                <p className="text-white font-300">BCL Token</p>
                                <a href="https://bscscan.com/address/0x471a5e862af35d2148bd8b505b361b1ddf5ffef1" target={"_blank"} className="contract_a font-300 text-blue text-decoration-underline">0x471a5e862af35d2148bd8b505b361b1ddf5ffef1</a>
                            </div>
                            <div className="step-bb d-flex align-items-md-center justify-content-between flex-md-row flex-column px-md-3 px-1 py-3">
                                <p className="text-white font-300">LAND Factory Contract</p>
                                <a href="https://bscscan.com/address/0xF7D14B66cAB92566Ae4fe7B6e3EBaA8Be93DA2C2" target={"_blank"} className="contract_a font-300 text-blue text-decoration-underline">0xF7D14B66cAB92566Ae4fe7B6e3EBaA8Be93DA2C2</a>
                            </div>
                            <div className="step-bb d-flex align-items-md-center justify-content-between flex-md-row flex-column px-md-3 px-1 py-3">
                                <p className="text-white font-300">Deployment Contract</p>
                                <a href="https://bscscan.com/address/0x849aBdeE7E903c67F5DA9a9eB8E4a0d8CE70C9EC" target={"_blank"} className="contract_a font-300 text-blue text-decoration-underline">0x849aBdeE7E903c67F5DA9a9eB8E4a0d8CE70C9EC</a>
                            </div>
                            <div className="step-bb d-flex align-items-md-center justify-content-between flex-md-row flex-column px-md-3 px-1 py-3">
                                <p className="text-white font-300">BCL NFT Contract</p>
                                <a href="https://bscscan.com/address/0xfF04e91CC69AD9481E86C887ae0C8Ca93b2aE830" target={"_blank"} className="contract_a font-300 text-blue text-decoration-underline">0xfF04e91CC69AD9481E86C887ae0C8Ca93b2aE830</a>
                            </div>
                            <div className="step-bb d-flex align-items-md-center justify-content-between flex-md-row flex-column px-md-3 px-1 py-3">
                                <p className="text-white font-300">NFT Marketplace</p>
                                <a href="https://bscscan.com/address/0x16B292afDef7d841408A059b1128B1b469D2AB75" target={"_blank"} className="contract_a font-300 text-blue text-decoration-underline">0x16B292afDef7d841408A059b1128B1b469D2AB75</a>
                            </div>
                            <div className="step-bb d-flex align-items-md-center justify-content-between flex-md-row flex-column px-md-3 px-1 py-3">
                                <p className="text-white font-300">IMO Factory Contract</p>
                                <a href="https://bscscan.com/address/0x693Ccf7d68533F44616A70B4258680802AaBfB80" target={"_blank"} className="contract_a font-300 text-blue text-decoration-underline">0x693Ccf7d68533F44616A70B4258680802AaBfB80</a>
                            </div>
                            <div className="step-bb d-flex align-items-md-center justify-content-between flex-md-row flex-column px-md-3 px-1 py-3">
                                <p className="text-white font-300">Airdrop</p>
                                <a href="https://bscscan.com/address/0xFe3Ac584D9BBb0C3fCC82cB5dbc77f22f356FBA8" target={"_blank"} className="contract_a font-300 text-blue text-decoration-underline">0xFe3Ac584D9BBb0C3fCC82cB5dbc77f22f356FBA8</a>
                            </div>
                        </div>

                    </div>
                </section>
                {/* section token details end */}
                {/* section global start */}
                <section className="mb_40_mobile mt-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-12 mt-3 mt-lg-0">
                                <img src="assets/image/token/legal/legal.webp" alt="BCL token Legal Opinion" type="image/webp" />
                            </div>
                            <div className="col-lg-6 col-12 mt-lg-0 mt-md-4">
                                <div className="text-center text-sm-start">
                                    <h2 className="font-200 white-text mb--30">Legal Opinion
                                    </h2>
                                    <p className="font-18 font-100 white-text my-4">
                                        BCL token legal opinion describes assets' legal value and their qualification as securities are subject to laws applicable to financial assets. It is always mandatory to obtain suitable legal advice through an opinion letter on the structure of the asset and the laws relating to it, to avoid any liabilities.
                                    </p>
                                    <div className="btn_height">
                                        <a href="/assets/ppt/LegalOpinionBCLToken.pdf" target="_blank" className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Download File</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section global end */}
                {/* section tokonomics start */}
                <section className="section-tokonomics">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center text-sm-start">
                                <h2 className="font-200 white-text mb---30">Tokenomics</h2>
                                <p className="font-18 font-100 white-text mb---30">
                                    To begin with, the metaverse of Blockchain Land is connected with tokens built on multiple blockchains. This token is built on 4 different blockchains and works on the bridge concept. The token bridge concept allows its user to move their tokens between different networks in a quick and cost-efficient manner.
                                </p>
                                <p className="font-18 font-100 white-text mb---37">
                                    Blockchain Land has its metaverse token with a total supply of 7,210,000,000 BCL tokens, while only about 5,047,000,000 are in circulation. This comes as a consequence of the burn that will happen to BCL tokens.
                                </p>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center mt--114">
                            <div className="col-lg-6 col-md-12 col-xl-8 order-2 order-lg-1 mt_80_mobile">
                                <div className="row justify-content-center">
                                    <div className="col-md-6 col-lg-12 col-xl-6 col-9">
                                        <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#00A896' }} />
                                                <p className="font-18 font-300 white-text ml---16">7%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">Core Team</p>
                                        </div>
                                        <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#FF5964' }} />
                                                <p className="font-18 font-300 white-text ml---16">10%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">Grants</p>
                                        </div>
                                        <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#F7E8A4' }} />
                                                <p className="font-18 font-300 white-text ml---16">10%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">Meta Labs RnD</p>
                                        </div>
                                        <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#3485FF' }} />
                                                <p className="font-18 font-300 white-text ml---16">10%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">Marketing</p>
                                        </div>
                                        <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#EFBCD5' }} />
                                                <p className="font-18 font-300 white-text ml---16">2%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">Creators</p>
                                        </div>
                                        <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#92DCE5' }} />
                                                <p className="font-18 font-300 white-text ml---16">6%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">IEO</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-12 col-xl-6 col-9">
                                        <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#9664CB' }} />
                                                <p className="font-18 font-300 white-text ml---16">10%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">Private Sale</p>
                                        </div>
                                        <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#DBFCFF' }} />
                                                <p className="font-18 font-300 white-text ml---16">30%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">Public Sale</p>
                                        </div>
                                        <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#EBC069' }} />
                                                <p className="font-18 font-300 white-text ml---16">10%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">Bridge Staking</p>
                                        </div>
                                        <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#499F68' }} />
                                                <p className="font-18 font-300 white-text ml---16">5%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">Reserve</p>
                                        </div>
                                        {/* <div className="d-flex align-items-start justify-content-between mb-2">
                                            <div className="d-flex align-items-center to-one">
                                                <span className="mics-color" style={{ background: '#3E78B2' }} />
                                                <p className="font-18 font-300 white-text ml---16">5%</p>
                                            </div>
                                            <p className="font-18 font-300 white-text to-two">Reserve</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 text-center order-1 order-lg-2 mb-lg-0 mb-5">
                                <img src="/assets/image/token/toconomics/to-million.svg" alt="to-million" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section tokonomics end */}
                {/* section burning start */}
                <section className="bg-burning burning-height">
                    <div className="container p-0 p-md-1">
                        <div className="row justify-content-end align-items-center">
                            <div className="col-12 d-block d-md-none">
                                <img src="/assets/image/token/toconomics/buring_mechanism.webp" alt="buring_mechanism" className="w-100" type="image/webp" />
                            </div>
                            <div className="col-md-7 col-lg-6 col-xxl-6 burning_mechanism_bg burning_mechanism_padding pb-5 pb-md-0">
                                <div className="text-center text-sm-start">
                                    <h2 className="font-200 white-text mb---30 mt-3 mt-md-0">
                                        Burning <br className="d-lg-block d-none" /> Mechanism
                                    </h2>
                                    <p className="font--18 font-100 white-text mb---30">
                                        There is a total of 30% burn mechanism. Whenever there's a minting of one planet, 5% token from the total supply will burn automatically. Any new planet will be minted on the demand to create a new planet from our metaverse community.
                                    </p>
                                    <p className="font--18 font-100 white-text">
                                        There will be a total of 6 planets that can be built on this metaverse having the option to create on different blockchains. In this scenario, 5% token will be burned on each minting of the planet, and there will be a total of 30% burning on the minting of 6 planets.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section burning end */}
                {/* <div className="my-5 py-5 my-lg-0 py-md-0"></div> */}
                {/* section community start */}
                <Community />
                {/* section community end */}

                {/*Footer Start*/}
                <Footer />
                {/*Footer End*/}
            </div>

        </>
    );
}

export default Token;