import React, { useState } from 'react';
import Footer from './component/Footer';
import Header from './component/Header';
import Community from './component/Community';
import axios from 'axios';
import { Helmet } from 'react-helmet';

export default function Contact() {
  const [fname, setFname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({
    fnamevalid: false,
    emailvalid: false,
    messagevalid: false,
  });

  const validationfunc = () => {
    if (!fname) {
      setValidation({ ...validation, fnamevalid: true });
      return;
    }
    if (!email) {
      setValidation({ ...validation, emailvalid: true });
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
      setValidation({ ...validation, emailvalid: true });
      return;
    }
    if (!message) {
      setValidation({ ...validation, messagevalid: true });
      return;
    }
    contact();
  };

  const contact = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: 'https://backend.blockchain.land/save_contact_details',
      params: {
        firstname: fname,
        work_email: email,
        message: message,
      },
    })
      .then(res => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <meta
          property="twitter:image"
          itemProp="image"
          content="https://blockchain.land/assets/image/meta_img/twitter_meta.png"
        />

        <title>Contact | Blockchain Land</title>
        <meta name="title" content="Contact | Blockchain Land" />
        <meta
          name="keywords"
          content="Metaverse Contact, Blockchain land contact"
        />
        <link
          rel="canonical"
          href="https://blockchain.land/contact"
          title="contact"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blockchain.land/contact" />
        <meta property="og:title" content="Contact | Blockchain.land" />
        <meta
          property="og:description"
          content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains."
        />
        <meta
          property="og:image"
          itemProp="image"
          content="https://blockchain.land/assets/image/meta_img/facebook_meta.png"
        />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://blockchain.land/contact"
        />
        <meta property="twitter:title" content="Contact | Blockchain.land" />
        <meta
          property="twitter:description"
          content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains."
        />

        <meta name="reply-to" content="blockchain.land" />
        <meta name="robots" content="index, Follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="revisit-after" content="7 day" />
        <meta name="rating" content="GENERAL" />
        <meta name="language" content="English" />
        <meta name="distribution" content="Global" />
        <meta name="copyright" content="blockchain.land" />
        <link rel="publisher" href="https://blockchain.land/" />
        <link rel="author" href="https://blockchain.land/" />
      </Helmet>

      <Header />
      <section className="contact_pad position-reletive">
        <div className="container">
          <div className="card_corner_top position-relative">
            <div className="contact_bg">
              <div className="row">
                <div className="col-12">
                  <div className="contact-title">
                    <h1 className="font---30 text-white font-200 text-uppercase">
                      Contact Us
                    </h1>
                    <p className="font-16 hire_p font-100">
                      We're just one click away to help you to build and
                      experience your own whole new universe. Fill in the{' '}
                      <br className="d-lg-block d-none" /> form to share more
                      details. we’d love to talk.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="mb-4 position-relative">
                    <label className="mb-2 form_label text-uppercase">
                      Your Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Enter your full name"
                      value={fname}
                      onChange={e => {
                        setFname(e.target.value);
                        setValidation({ ...validation, fnamevalid: false });
                      }}
                    />
                    {validation.fnamevalid && (
                      <div className="w-100">
                        <label className="form-label m-0 err_msg_tooltip">
                          Please Enter Full Name
                        </label>
                      </div>
                    )}
                  </div>

                  <div className="mb-4 position-relative">
                    <label className="mb-2 form_label text-uppercase">
                      Your Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id=""
                      placeholder="e.g. example@gmail.com"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                        setValidation({ ...validation, emailvalid: false });
                      }}
                    />
                    {validation.emailvalid && (
                      <div className="w-100">
                        <label className="form-label m-0 err_msg_tooltip">
                          {email ? 'Invalid Email Id' : 'Please Enter Email Id'}
                        </label>
                      </div>
                    )}
                  </div>

                  <div className="mb-4 position-relative">
                    <label className="mb-2 form_label text-uppercase">
                      Subject
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Write discussion topic"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="position-relative">
                    <label className="mb-2 form_label text-uppercase">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Write a message... "
                      aria-label="With textarea"
                      value={message}
                      onChange={e => {
                        setMessage(e.target.value);
                        setValidation({ ...validation, messagevalid: false });
                      }}
                    />
                    {validation.messagevalid && (
                      <div className="w-100">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label m-0 err_msg_tooltip validation_top"
                        >
                          Please Enter Message
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12 mt-lg-0 mt-4">
                  <button
                    className="btn explore_now submit_btn text-uppercase contact_btn"
                    onClick={() => validationfunc()}
                  >
                    {loading ? 'Loading...' : 'Send Message'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Community />

      <Footer />
    </>
  );
}
