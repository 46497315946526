import React from "react";
import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

function MetaLife() {
    return (
        <>
            <div>
                <Helmet>
                    <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                    <title>Meta Life In Metaverse | Blockchain Land</title>
                    <meta name="title" content="Meta Life In Metaverse | Blockchain Land" />
                    {/* <meta name="description" content="people in our metaverse community are eligible to claim exclusive NFTs and join our community. Airdrop winners will be rewarded with unique NFT cubes." /> */}
                    <meta name="keywords" content="Metaverse for the users, Explore Metaverse, Explore Blockchain land" />
                    <link rel="canonical" href="https://blockchain.land/metalife" title="metalife" />

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://blockchain.land/metalife" />
                    <meta property="og:title" content="Meta Life | Blockchain.land" />
                    <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                    <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://blockchain.land/metalife" />
                    <meta property="twitter:title" content="Meta Life | Blockchain.land" />
                    <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                    <meta name="reply-to" content="blockchain.land" />
                    <meta name="robots" content="index, Follow" />
                    <meta name="googlebot" content="index, follow" />
                    <meta name="revisit-after" content="7 day" />
                    <meta name="rating" content="GENERAL" />
                    <meta name="language" content="English" />
                    <meta name="distribution" content="Global" />
                    <meta name="copyright" content="blockchain.land" />
                    <link rel="publisher" href="https://blockchain.land/" />
                    <link rel="author" href="https://blockchain.land/" />
                </Helmet>

                {/* Header Start */}
                <Header />
                {/* Header End */}

                {/* section metalife start */}
                <section className="section-airdrop mt--100 pt--100 metalife_bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <h1 className="meta_h1 white-text font-200">
                                        Meta-L<span className="team_highlight meta_h1 bg_position">i</span>fe
                                    </h1>
                                    <p className="font-18 font-100 metalife_p line_height mt-3">
                                        Meta-Life is the exclusive way of the metaverse for the users of Blockchain Land. They can experience their daily routine with all the needed and necessary materials.
                                    </p>
                                    <a href="https://market.blockchain.land/" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }}>Explore Metalife</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section metalife end */}

                {/* section meta estate start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb_meta text-center text-md-start">
                                <span className="font-20 font-400 white-text metalife_span">Here, What we got in meta-life</span>
                            </div>
                            <div className="col-12">
                                <h2 className="airdrop_h2 giveaway_h2 mb--40">Meta Estate</h2>
                                <p className="font-18 font-100 mb--40 meta_p line-25">
                                    Metaverse estate is a property like real estate property made of pixels you can buy. Here, the user can design and own the Meta estate the same as real estate in the metaverse. Meta estate provides user-generated digital environments, which are probably the safest. It is possible for creators to monetize their content by charging for access or trading their NFTs. The use of virtual properties can be used by brands to advertise services, launch virtual products, and provide unique customer experiences. For Meta estate investors, these parcels of digitized land present a lucrative opportunity. This is similar to having a personal website in the early days of the internet before everyone moved onto social media.
                                </p>
                                <a href="https://market.blockchain.land/metaestate" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }} title='Download SDK'>Download SDK</a>
                            </div >
                            <div className="col-12">
                                <div className="mt-40">
                                    <img src="assets/image/meta_life/meta_astate.webp" className="d-md-block d-none" alt="Meta Estate" type="image/webp" />
                                    <img src="assets/image/meta_life/meta_astate_mob.webp" className="d-md-none d-block" alt="Meta Estate" type="image/webp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section meta estate end */}

                {/* section Meta Vehicles start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-5">
                                <h2 className="airdrop_h2 giveaway_h2 mb--40">Meta Vehicles</h2>
                                <p className="font-18 font-100 mb--40 meta_p line-25">
                                    Metaverse can bridge the gap between users and destinations by providing superior vehicles for the ride-hailing experience. Blockchain Land is allowing users to buy or bring their own 3D vehicle in Meta vehicles.
                                </p>
                                <a href="https://market.blockchain.land/metavehicle" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }} title='Download SDK'>Download SDK</a>
                            </div >
                            <div className="col-xl-7 mt-4 mt-xl-0">
                                <img src="assets/image/meta_life/meta_vehical.webp" className="margin_left w-100 d-md-block d-none" alt="Meta Vehicles" type="image/webp" />
                                <img src="assets/image/meta_life/meta_vehical_mob.webp" className="d-md-none d-block" alt="Meta Vehicles" type="image/webp" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Meta Vehicles end */}

                {/* section Meta Land start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 order-xl-1 order-2 mt-xl-0 mt-4">
                                <img src="assets/image/meta_life/meta_land.webp" className="w-100 d-md-block d-none" alt="Meta Land" type="image/webp" />
                                <img src="assets/image/meta_life/meta_land_mob.webp" className="d-md-none d-block" alt="Meta Land" type="image/webp" />
                            </div>
                            <div className="col-xl-6 order-xl-2 order-1">
                                <h2 className="airdrop_h2 giveaway_h2 mb--40">Meta Land</h2>
                                <p className="font-18 font-100 mb--40 meta_p line-25">
                                    Meta land is a virtual space in the metaverse that can be bought, sold, or designed by users. Users can get the whole Metaverse land from the Blockchain Land. One parcel of the land is called Meta land.
                                </p>
                                <a href="https://market.blockchain.land/metaland" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }} title='Download SDK'>Download SDK</a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Meta Land end */}

                {/* section Meta Commerce start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="airdrop_h2 giveaway_h2 mb--40">Meta Commerce</h2>
                                <p className="font-18 font-100 mb--40 meta_p line-25">
                                    Players' everyday lives revolve around meta-commerce, which is their daily hot spot. Consumers and sellers will be able to list all the branded items for buying and selling respectively.
                                </p>
                                <a href="https://market.blockchain.land/metacommerce" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }} title='Download SDK'>Download SDK</a>
                            </div >
                            <div className="col-12">
                                <div className="mt-40">
                                    <img src="assets/image/meta_life/meta_commerce.webp" className="d-md-block d-none" alt="Meta Commerce" type="image/webp" />
                                    <img src="assets/image/meta_life/meta_commerce_mob.webp" className="d-md-none d-block" alt="Meta Commerce" type="image/webp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Meta Commerce end */}

                {/* section Meta Streem start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 order-xl-1 order-2 mt-xl-0 mt-4">
                                <img src="assets/image/meta_life/meta_streem.webp" className="meta_margin_left w-100 d-md-block d-none" alt="Meta Stream" type="image/webp" />
                                <img src="assets/image/meta_life/meta_streem_mob.webp" className="d-md-none d-block" alt="Meta Stream" type="image/webp" />
                            </div>
                            <div className="col-xl-6 order-xl-2 order-1">
                                <h2 className="airdrop_h2 giveaway_h2 mb--40">Meta Stream</h2>
                                <p className="font-18 font-100 mb--40 meta_p line-25">
                                    Meta streams allow users to stream anywhere in the metaverse without interference. Users can host live shows, music events, parties, and more here.
                                </p>
                                <a href="https://market.blockchain.land/metastream" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }} title='Download SDK'>Download SDK</a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Meta Streem end */}

                {/* section Meta Logistic start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6">
                                <h2 className="airdrop_h2 giveaway_h2 mb--40">Meta Logistic</h2>
                                <p className="font-18 font-100 mb--40 meta_p line-25">
                                    Meta logistics synchronizes the physical world with its digital twin in real time using geo-located signals. In a short time, users can order and receive anything from anywhere in the world.
                                </p>
                                <a href="https://market.blockchain.land/metalogistic" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }} title='Download SDK'>Download SDK</a>
                            </div >
                            <div className="col-xl-6 mt-4 mt-xl-0">
                                <img src="assets/image/meta_life/meta_logistic.webp" className="margin_left img_size w-100 d-md-block d-none" alt="Meta Logistic" type="image/webp" />
                                <img src="assets/image/meta_life/meta_logistic_mob.webp" className="d-md-none d-block" alt="Meta Logistic" type="image/webp" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Meta Logistic end */}

                {/* section Meta Tickets start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 order-xl-1 order-2 mt-xl-0 mt-4">
                                <img src="assets/image/meta_life/meta_tickets.png" className="w-100 d-md-block d-none" alt="Meta Tickets" />
                                <img src="assets/image/meta_life/meta_tickets_mob.png" className="d-md-none d-block" alt="Meta Tickets" />
                            </div>
                            <div className="col-xl-6 order-xl-2 order-1">
                                <h2 className="airdrop_h2 giveaway_h2 mb--40">Meta Tickets</h2>
                                <p className="font-18 font-100 mb--40 meta_p line-25">
                                    Tickets for organized events can be purchased or sold in the metaverse virtual world just like in the real world. Meta ticket buyers and sellers will receive a unique identifier firmly based on blockchain technology.
                                </p>
                                <a href="https://market.blockchain.land/metatickets" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }} title='Download SDK'>Download SDK</a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Meta Tickets end */}

                {/* section Meta Social start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="airdrop_h2 giveaway_h2 mb--40">Meta Social</h2>
                                <p className="font-18 font-100 mb--40 meta_p line-25">
                                    The metaverse is an iteration of the internet that gives us a far more immersive virtual experience of social life, where users can organize a meet, event, or party with their loved ones.
                                </p>
                                <a href="https://market.blockchain.land/metasocial" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" title='Download SDK' style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }}>Download SDK</a>
                            </div>
                            <div className="col-12">
                                <div className="mt-40">
                                    <img src="assets/image/meta_life/meta_social.webp" className="d-md-block d-none" alt="Meta Social" type="image/webp" />
                                    <img src="assets/image/meta_life/meta_social_mob.webp" className="d-md-none d-block" alt="Meta Social" type="image/webp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Meta Social end */}

                <Community />

                {/*Footer Start*/}
                <Footer />
                {/*Footer End*/}
            </div >
        </>
    );
}

export default MetaLife;