import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Header() {

    const [height, setheight] = useState(true);
    const [submenu, setSubmenu] = useState(false)

    return (
        <>
            <div className="header_note">
                <div className="container">
                    <div className="note d-none d-md-flex justify-content-center">
                        <a href="https://market.blockchain.land/citizenship" target={"_blank"} className="header_ticker">For a limited time only: CITIZENSHIP IS FREE! Claim the Airdrop!</a>
                    </div>
                    <div className="note d-flex d-md-none">
                        <marquee behavior direction>
                            <div className='d-flex'>
                                <a href="https://market.blockchain.land/citizenship" target={"_blank"} className="header_ticker">For a limited time only: CITIZENSHIP IS FREE! Claim the Airdrop!</a>
                            </div>
                        </marquee>
                    </div>
                </div>
            </div>

            <header className="header header_bg header-style-one blur-header position-relative">
                <div className="container">
                    <nav className="navbar">
                        <Link to={"/"} className="navbar-brand">
                            <img src="assets/image/logo/logo-white.svg" alt="Blockchain Land" />
                        </Link>
                        <div className="main-menu ms-auto">
                            <ul className={height == true ? "nav_menu" : "nav_menu active"}>
                                <li className="menu-item bottom_line" onClick={() => { setSubmenu(!submenu) }} onMouseEnter={() => {
                                    if (window.innerWidth > 1024) {
                                        setSubmenu(true)
                                    }
                                }}>
                                    <Link to={""}>Marketplace</Link>
                                    <img src="assets/image/small-elements/house/house-bullet.svg" alt="arrow" className="sidebar_arrow active" />
                                    <img src="assets/image/logo/arrow.svg" alt="arrow" className="menu_arrow active" />
                                    {
                                        submenu == true &&
                                        <div className="marketplace_bg">
                                            <div className="container">
                                                <div>
                                                    <div className="d-flex align-items-center back_arrow">
                                                        <img src="assets/image/small-elements/house/house-bullet.svg" alt="left_arrow" className="me-3 ms-2" onClick={() => {
                                                            { setSubmenu(!submenu) }
                                                        }} />
                                                        <p className="marketplace_h5 mb-0">Discover</p>
                                                    </div>
                                                    <p className="menu_border mt-md-4 mt-2"></p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-sm-3 mt-2 marketplace_col">
                                                        <Link to={"/marketplace"} className="d-flex align-items-start">
                                                            <img src="/assets/image/mega_menu/marketplace/marketplace.svg" alt="marketplace" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Marketplace</p>
                                                                <span className="marketplace_span">Explore all the available NFTs on the Blockchain Land Marketplace.</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-sm-3 mt-2 marketplace_col">
                                                        <Link to={"/getcitizenship"} className="d-flex align-items-start">
                                                            <img src="/assets/image/mega_menu/marketplace/citizenship.svg" alt="citizenship" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Citizenship</p>
                                                                <span className="marketplace_span">Get complete access to every service on and from Blockchain Land. </span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-sm-3 mt-2 marketplace_col">
                                                        <Link to={"/typesofnft"} className="d-flex align-items-start">
                                                            <img src="/assets/image/mega_menu/marketplace/typesofnft.svg" alt="typesofnft" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Types of NFT in BCL</p>
                                                                <span className="marketplace_span">Explore all types of Native NFTs on Blockchain Land</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-sm-3 mt-2 marketplace_col">
                                                        <Link to={"/mint"} className="d-flex align-items-start">
                                                            <img src="/assets/image/mega_menu/marketplace/mintparcel.svg" alt="mintparcel" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Mint Land</p>
                                                                <span className="marketplace_span">Build your own Valley or Country by minting them here first. You can keep open parcels from your land for sale once you’ve minted your Land.</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-sm-3 mt-2 marketplace_col">
                                                        <Link to={"/airdropgiveaway"} className="d-flex align-items-start">
                                                            <img src="/assets/image/mega_menu/marketplace/airdrop.svg" alt="airdrop" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Airdrop Giveaway</p>
                                                                <span className="marketplace_span">Participate and get exclusive NFTs of Blockchain Land that can be used on your Parcel of land.</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-sm-3 mt-2 marketplace_col">
                                                        <a href="https://market.blockchain.land/tutorials" target={"_blank"} className="d-flex align-items-start">
                                                            <img src="/assets/image/mega_menu/marketplace/tutorials.svg" alt="tutorials" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Marketplace Tutorials</p>
                                                                <span className="marketplace_span">Discover all the possibilities to be part of the Blockchain Land community.</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 mt-lg-4 mt-sm-3 mt-2">
                                                        <div className="before_bg">
                                                            <div className="note_bg d-flex align-items-center justify-content-between flex-column flex-lg-row text-center">
                                                                <p className="mb-0 note_p">Now, Blockchain Land Metaverse Marketplace Is Live. Reach To Your Virtual Destination.</p>
                                                                <a href="https://market.blockchain.land/" target={"_blank"} className="explore_now">Explore Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </li>
                                <li className="menu-item bottom_line" onClick={() => { setSubmenu(!submenu) }} onMouseEnter={() => {
                                    if (window.innerWidth > 1024) {
                                        setSubmenu(true)
                                    }
                                }}>
                                    <Link to={""}>Metaverse</Link>
                                    <img src="assets/image/small-elements/house/house-bullet.svg" alt="arrow" className="sidebar_arrow active" />
                                    <img src="assets/image/logo/arrow.svg" alt="arrow" className="menu_arrow active" />
                                    {
                                        submenu == true &&
                                        <div className="marketplace_bg metaversebg">
                                            <div className="container">
                                                <div>
                                                    <div className="d-flex align-items-center back_arrow">
                                                        <img src="assets/image/small-elements/house/house-bullet.svg" alt="left_arrow" className="me-3 ms-2" onClick={() => { setSubmenu(false) }} />
                                                        <p className="marketplace_h5 mb-0">Virtual</p>
                                                    </div>
                                                    <p className="menu_border mt-md-4 mt-2"></p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                        <Link to={"/metaverse"} className="d-flex align-items-start">
                                                            <img src="assets/image/mega_menu/metaverse/metaverse.svg" alt="metaverse" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Metaverse</p>
                                                                <span className="marketplace_span">Explore the Virtual world of new possibilities on Blockchain Land.</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                        <Link to={"/metaland"} className="d-flex align-items-start">
                                                            <img src="assets/image/mega_menu/metaverse/metaland.svg" alt="metaland" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Metaland</p>
                                                                <span className="marketplace_span">In the metaverse, meta land is a virtual space where users can buy, sell, and design.</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                        <Link to={"/futuremetaverse"} className="d-flex align-items-start">
                                                            <img src="assets/image/mega_menu/metaverse/futureofmetaverse.svg" alt="futureofmetaverse" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Future of Metaverse</p>
                                                                <span className="marketplace_span">The possibilities of applications are immense and here is a glimpse on what we can help you achieve.</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                        <Link to={"/metaverseevolution"} className="d-flex align-items-start">
                                                            <img src="assets/image/mega_menu/metaverse/metaverseevolution.svg" alt="metaverseevolution"
                                                                className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Metaverse Evolution</p>
                                                                <span className="marketplace_span">The immense evolution of Metaverse.</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                        <Link to={"/metalife"} className="d-flex align-items-start">
                                                            <img src="assets/image/mega_menu/metaverse/metalife.svg" alt="metalife" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">MetaLife</p>
                                                                <span className="marketplace_span">The Meta-Life is the exclusive way of the metaverse for Blockchain Land users with all the necessary materials of daily routines.</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                        <Link to="/ambassadorprogram" className="d-flex align-items-start">
                                                            <img src="assets/image/mega_menu/metaverse/ambassadorprogram.svg" alt="ambassadorprogram" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Ambassador Program</p>
                                                                <span className="marketplace_span">The blockchain Land ambassadors bring The Blockchain Land to the world and engage users to play, create, and collect!</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                        <Link to="/initialmetaverseofferings" className="d-flex align-items-start">
                                                            <img src="assets/image/mega_menu/metaverse/imo.svg" alt="ambassadorprogram" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Initial Metaverse Offering (IMO)</p>
                                                                <span className="marketplace_span">The Initial Metaverse Offering creates a launchpad for raising funds for metaverse projects in the future.</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </li>
                                <li className="menu-item bottom_line" onClick={() => { setSubmenu(!submenu) }} onMouseEnter={() => {
                                    if (window.innerWidth > 1024) {
                                        setSubmenu(true)
                                    }
                                }}>
                                    <Link to={""}>Airdrop</Link>
                                    <img src="assets/image/small-elements/house/house-bullet.svg" alt="arrow" className="sidebar_arrow active" />
                                    <img src="assets/image/logo/arrow.svg" alt="arrow" className="menu_arrow active" />
                                    {
                                        submenu == true &&
                                        <div className="marketplace_bg airdrop_bg">
                                            <div className="container">
                                                <div>
                                                    <div className="d-flex align-items-center back_arrow">
                                                        <img src="assets/image/small-elements/house/house-bullet.svg" alt="left_arrow" className="me-3 ms-2" onClick={() => { setSubmenu(false) }} />
                                                        <p className="marketplace_h5 mb-0">Airdrop</p>
                                                    </div>
                                                    <p className="menu_border mt-md-4 mt-2"></p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-sm-3 mt-2 marketplace_col">
                                                        <Link to={"/airdrop"} className="d-flex align-items-start">
                                                            <img src="/assets/image/mega_menu/airdrop/metaverse_nft.svg" alt="marketplace" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Metaverse NFT Airdrop</p>
                                                                <span className="marketplace_span">Get Ready to own a parcel of land on BlockchainLand with our Metaverse NFT Airdrop.
                                                                    Register yourself for the airdrop event coming soon!
                                                                </span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-sm-3 mt-2 marketplace_col">
                                                        <Link to={"/giveaways"} className="d-flex align-items-start">
                                                            <img src="/assets/image/mega_menu/airdrop/citizenship_giveaway.svg" alt="citizenship" className="mt-1 d-block" />
                                                            <div className="ms-2">
                                                                <p className="mb-lg-2 mb-1 marketplace_p">Citizenship Giveaways</p>
                                                                <span className="marketplace_span">BlockchainLand is doing an exclusive Metaverse Citizenship Giveaway, where members of the community will get free citizenship on BlockchainLand.</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </li>
                                <li className="menu-item bottom_line" onClick={() => { setSubmenu(!submenu) }} onMouseEnter={() => {
                                    if (window.innerWidth > 1024) {
                                        setSubmenu(true)
                                    }
                                }}>
                                    <Link to={""}>About</Link>
                                    <img src="assets/image/small-elements/house/house-bullet.svg" alt="arrow" className="sidebar_arrow active" />
                                    <img src="assets/image/logo/arrow.svg" alt="arrow" className="menu_arrow active" />
                                    {
                                        submenu == true &&
                                        <div className="marketplace_bg about_bg">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-6 col-12">
                                                        <div>
                                                            <div className="d-flex align-items-center back_arrow">
                                                                <img src="assets/image/small-elements/house/house-bullet.svg" alt="left_arrow" className="me-3 ms-2" onClick={() => { setSubmenu(false) }} />
                                                                <p className="marketplace_h5 mb-0">Who we are</p>
                                                            </div>
                                                            <p className="menu_border mt-md-4 mt-2"></p>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                                <Link to={"/joincommunity"} className="d-flex align-items-start">
                                                                    <img src="assets/image/mega_menu/about/community.svg" alt="community" className="mt-1 d-block" />
                                                                    <div className="ms-2">
                                                                        <p className="mb-lg-2 mb-1 marketplace_p">Community</p>
                                                                        <span className="marketplace_span">Join our community to stay ahead on our various launches and updates.</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="col-lg-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                                <Link to={"/team"} className="d-flex align-items-start">
                                                                    <img src="assets/image/mega_menu/about/team.svg" alt="team" className="mt-1 d-block" />
                                                                    <div className="ms-2">
                                                                        <p className="mb-lg-2 mb-1 marketplace_p">Team</p>
                                                                        <span className="marketplace_span">Talent wins games, but teamwork and intelligence wins championships.</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="col-lg-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                                <Link to={"/roadmap"} className="d-flex align-items-start">
                                                                    <img src="assets/image/mega_menu/about/roadmap.svg" alt="roadmap" className="mt-1 d-block" />
                                                                    <div className="ms-2">
                                                                        <p className="mb-lg-2 mb-1 marketplace_p">Roadmap</p>
                                                                        <span className="marketplace_span">Find out more in the metaverse what is coming in your way to explore.</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="col-lg-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                                <Link to={""} className="d-flex align-items-start">
                                                                    <img src="assets/image/mega_menu/about/about.svg" alt="about" className="mt-1 d-block" />
                                                                    <div className="ms-2">
                                                                        <p className="mb-lg-2 mb-1 marketplace_p">About Us</p>
                                                                        <span className="marketplace_span">There is so much more to us than just a Metaverse solution. Find out more.</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12 mt-md-0 mt-4">
                                                        <div>
                                                            <p className="marketplace_h5 mb-0">Documents</p>
                                                            <p className="menu_border mt-md-4 mt-2"></p>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 mt-md-4 mt-1 pe-0">
                                                                <ul>
                                                                    <li className="document_a"><a>Onepager</a></li>
                                                                    <li className="document_a"><a>Whitepaper</a></li>
                                                                    <li className="document_a"><a href="/assets/ppt/BlockchainLandLogo.zip">Brand Kit</a></li>
                                                                    <li className="document_a"><a href="/assets/ppt/BusinessDeck.pdf" target={"_blank"}>Business Deck</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-6 mt-md-4 mt-1 ps-0">
                                                                <ul>
                                                                    <li className="document_a">
                                                                        <a href="/assets/ppt/AuditReport(BlockchainLand).pdf" target={"_blank"}>Audit Report</a></li>
                                                                    <li className="document_a"><a href="/assets/ppt/LegalOpinionBCLToken.pdf" target={"_blank"}>Legal Opinion</a></li>
                                                                    <li className="document_a"><a href="/assets/ppt/BlockchainValleyDeck.pdf"
                                                                        target={"_blank"}>Blockchain Valley Deck</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4">
                                                            <p className="marketplace_h5 mb-0">Social Media</p>
                                                            <p className="menu_border mt-md-4 mt-2"></p>
                                                        </div>
                                                        <div className="d-flex align-items-center my-3">
                                                            <div className="social_media">
                                                                <a href="https://www.youtube.com/channel/UCyM4Z0ZDNpcB7Cjr14Q0G3A" target="_blank" className="d-flex align-items-center"><img src="/assets/image/mega_menu/about/youtube.svg" alt="youtube" className="d-block" /></a>
                                                            </div>
                                                            <div className="social_media ms-2">
                                                                <a href="https://www.linkedin.com/company/blockchain-land/" target="_blank" className="d-flex align-items-center"><img src="/assets/image/mega_menu/about/linkedin.svg" alt="linkedin" className="d-block" /></a>
                                                            </div>
                                                            <div className="social_media ms-2">
                                                                <a href="https://blockchainland.medium.com/" target="_blank" className="d-flex align-items-center"><img src="/assets/image/mega_menu/about/medium.svg" alt="medium" className="d-block" /></a>
                                                            </div>
                                                            <div className="social_media ms-2">
                                                                <a href="https://twitter.com/land_blockchain" target="_blank" className="d-flex align-items-center"><img src="/assets/image/mega_menu/about/twitter.svg" alt="twitter" className="d-block" /></a>
                                                            </div>
                                                            <div className="social_media ms-2">
                                                                <a href="https://t.me/BlockchainLandOfficial" target="_blank" className="d-flex align-items-center"><img src="/assets/image/mega_menu/about/telegram.svg" alt="telegram" className="d-block" /></a>
                                                            </div>
                                                            <div className="social_media ms-2">
                                                                <a href="https://www.instagram.com/theblockchainland/" target="_blank" className="d-flex align-items-center"><img src="/assets/image/mega_menu/about/instagram.svg" alt="instagram" className="d-block" /></a>
                                                            </div>
                                                            <div className="social_media ms-2">
                                                                <a href="https://www.facebook.com/Blockchain-Land-103061128803396" target="_blank" className="d-flex align-items-center"><img src="/assets/image/mega_menu/about/facebook.svg" alt="facebook" className="d-block" /></a>
                                                            </div>
                                                            <div className="social_media ms-2">
                                                                <a href="https://discord.com/invite/8hZVg9ACNz" target="_blank" className="d-flex align-items-center"><img src="/assets/image/mega_menu/about/raddit.svg" alt="discord" className="d-block" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </li>
                                <li className="menu-item bottom_line" onClick={() => { setSubmenu(!submenu) }} onMouseEnter={() => {
                                    if (window.innerWidth > 1024) {
                                        setSubmenu(true)
                                    }
                                }}>
                                    <Link to={""}>Support</Link>
                                    <img src="assets/image/small-elements/house/house-bullet.svg" alt="arrow" className="sidebar_arrow active" />
                                    <img src="assets/image/logo/arrow.svg" alt="arrow" className="menu_arrow active" />
                                    {
                                        submenu == true &&
                                        <div className="marketplace_bg about_bg">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-9 col-12">
                                                        <div>
                                                            <div className="d-flex align-items-center back_arrow">
                                                                <img src="assets/image/small-elements/house/house-bullet.svg" alt="left_arrow" className="me-3 ms-2" onClick={() => { setSubmenu(false) }} />
                                                                <p className="marketplace_h5 mb-0">Know more About us</p>
                                                            </div>
                                                            <p className="menu_border mt-md-4 mt-2"></p>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-sm-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                                <Link to={""} className="d-flex align-items-start">
                                                                    <img src="assets/image/mega_menu/support/talkwithus.svg" alt="talkwithus" className="mt-1 d-block" />
                                                                    <div className="ms-2">
                                                                        <p className="mb-lg-2 mb-1 marketplace_p">Talk With Us</p>
                                                                        <span className="marketplace_span">Reach us for any queries.</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                                <Link to={"/faqs"} className="d-flex align-items-start">
                                                                    <img src="assets/image/mega_menu/support/faqs.svg" alt="faqs" className="mt-1 d-block" />
                                                                    <div className="ms-2">
                                                                        <p className="mb-lg-2 mb-1 marketplace_p">FAQs</p>
                                                                        <span className="marketplace_span">Browse some frequently asked questions.</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                                <Link to={"/contact"} className="d-flex align-items-start">
                                                                    <img src="assets/image/mega_menu/support/contactus.svg" alt="contactus" className="mt-1 d-block" />
                                                                    <div className="ms-2">
                                                                        <p className="mb-lg-2 mb-1 marketplace_p">Contact Us</p>
                                                                        <span className="marketplace_span">We always love hearing from you. Reach us here.</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                                <a href="https://discord.com/invite/8hZVg9ACNz" target="_blank" className="d-flex align-items-start">
                                                                    <img src="assets/image/mega_menu/support/discord.svg" alt="discord" className="mt-1 d-block" />
                                                                    <div className="ms-2">
                                                                        <p className="mb-lg-2 mb-1 marketplace_p">Join Discord</p>
                                                                        <span className="marketplace_span">Our community is always active and ahead on the updates.</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                                <Link to={""} className="d-flex align-items-start">
                                                                    <img src="assets/image/mega_menu/support/helpcenter.svg" alt="helpcenter" className="mt-1 d-block" />
                                                                    <div className="ms-2">
                                                                        <p className="mb-lg-2 mb-1 marketplace_p">Help Centre</p>
                                                                        <span className="marketplace_span">We are available here for any support you need.</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-6 col-12 mt-lg-4 mt-2 marketplace_col">
                                                                <Link to={"/advisoronboarding"} className="d-flex align-items-start">
                                                                    <img src="assets/image/mega_menu/support/hire.svg" alt="hire" className="mt-1 d-block" />
                                                                    <div className="ms-2">
                                                                        <p className="mb-lg-2 mb-1 marketplace_p">Advisor Onboarding</p>
                                                                        <span className="marketplace_span">We handpick the best among the rest. We prioritize growth and success.</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-12 mt-md-0 mt-3">
                                                        <div>
                                                            <p className="marketplace_h5 mb-0">Company</p>
                                                            <p className="menu_border mt-md-4 mt-2"></p>
                                                        </div>
                                                        <div className="mt-md-4 mt-1 mb-md-0 mb-2">
                                                            <ul>
                                                                <li className="document_a"><Link to={"/privacy_policy"}>Privacy Policy</Link></li>
                                                                <li className="document_a"><Link to={"/content_policy"}>Content Policy</Link></li>
                                                                <li className="document_a"><Link to={""}>Terms of Use</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </li>
                                <li className="menu-item">
                                    <Link to={"/token"}>Token</Link>
                                </li>
                            </ul>
                            <a href="https://market.blockchain.land/citizenship" target="_blank" className="btn-head btn-contain font-16 text-center text-white d-inline-block font-200 header_btn" style={{ backgroundImage: 'url("assets/image/btn-bg/get_citizenship.svg")' }}>Get Citizenship</a>
                            <div className="menu_btn ms-3" onClick={() => { setheight(!height) }}>
                                <img src="/assets/image/logo/menu.svg" alt="menuimg" />
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
}