import React from "react";
import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function MetaverseEvolution() {
    return (
        <>
            <Helmet>
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                <title>Metaverse Evolution | Blockchain Land</title>
                <meta name="title" content="Metaverse Evolution | Blockchain Land" />
                {/* <meta name="description" content="Know more about our flexible planning schedule of our key features that Blockchain Land metaverse would like to add in future." /> */}
                <meta name="keywords" content="Metaverse Evolved, Evolution of The Metaverse, Mature Metaverse, Advance Metaverse, Emerging Metaverse" />
                <link rel="canonical" href="https://blockchain.land/metaverseevolution" title="metaverseevolution" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blockchain.land/metaverseevolution" />
                <meta property="og:title" content="Metaverse Evolution | Blockchain.land" />
                <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blockchain.land/metaverseevolution" />
                <meta property="twitter:title" content="Metaverse Evolution | Blockchain.land" />
                <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>

            {/* Header Start */}
            <Header />
            {/* Header End */}

            {/* section Metaverse Evolution start */}
            <section className="metaverse_evolution pt--400 mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div>
                                <h1 className="font-200 white-text cmb_16 text-lg-start text-center">
                                    How The <br className="d-lg-block d-none" /> <span className="team_highlight metaverse_h1">Metaverse</span> Has Evolved
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section Metaverse Evolution end */}

            <section className="mb_40_mobile mt-100 map_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <img src="assets/image/metaverse_evolution/metaverse_map.png" alt="img" className="d-lg-block d-none" />
                                <img src="assets/image/metaverse_evolution/metaverse_map_tab.png" alt="img" className="d-lg-none d-none d-md-block w-100" />
                                <img src="assets/image/metaverse_evolution/metaverse_map_m.png" alt="img" className="d-block d-md-none" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb_40_mobile mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-12 text-lg-start text-center">
                            <p className="btn-point btn-blue text-center d-inline-block font-22 font-200 white-text mb-30" style={{ backgroundImage: 'url("assets/image/small-elements/btn-blue-point.svg")' }}>Evolution</p>
                            <h2 className="white-text font-200 mb--50 metaverse_h1">
                                Evolution Spectrum of The Metaverse
                            </h2>
                        </div>
                    </div>
                    <div className="row mb--90">
                        <div className="col-xl-3 col-md-4 d-flex align-items-center">
                            <img src="assets/image/metaverse_evolution/investor.svg" alt="Spectrum" />
                            <div>
                                <p className="font-18 font-100 white-text line-25 pl-15">Innovators and Early Adopters</p>
                                <p className="font-18 font-100 text-blue line-25 pl-15">(0% to 20%)</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-4 d-flex align-items-center mt-mb-0 mt-3">
                            <img src="assets/image/metaverse_evolution/early.svg" alt="Spectrum" />
                            <div>
                                <p className="font-18 font-200 white-text line-25 pl-15">Early and Late Majority</p>
                                <p className="font-18 font-200 text-blue line-25 pl-15">(20% to 80%)</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-4 d-flex align-items-center mt-mb-0 mt-3">
                            <img src="assets/image/metaverse_evolution/rest.svg" alt="Spectrum" />
                            <div>
                                <p className="font-18 font-200 white-text line-25 pl-15">Rest</p>
                                <p className="font-18 font-200 text-blue line-25 pl-15">(80% to 100%)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="metaverse_spectrum mb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-9">
                            </div>
                            <div className="col-3 col_padding padding_top mb--50 pe-0">
                                <h3 className="metaverse_font text-white mb-2">Mature Metaverse</h3>
                                <p className="font-18 metaverse_p mb-3 d-lg-block d-none font-100">Maturing toward interoperable, immersive experiences in new virtual economies</p>
                                <img src="assets/image/metaverse_evolution/mature_metaverse.svg" alt="Mature Metaverse" className="d-md-block d-none" />
                                <img src="assets/image/metaverse_evolution/mature_metaverse_m.svg" alt="Mature Metaverse" className="d-md-none d-block" />
                            </div>
                            <div className="col-3">
                            </div>
                            <div className="col-9 col_padding2 mb--70 pe-0">
                                <h3 className="metaverse_font text-white mb-2">Advance Metaverse</h3>
                                <p className="font-18 metaverse_p mb-3 d-lg-block d-none w--50 font-100">Progression on emerging metaverse in a combinative approach</p>
                                <img src="assets/image/metaverse_evolution/advance_metaverse.svg" alt="Advance Metaverse" className="d-md-block d-none" />
                                <img src="assets/image/metaverse_evolution/advance_metaverse_m.svg" alt="Advance Metaverse" className="d-md-none d-block" />
                            </div>
                            <div className="col-10 pe-0 padding_top_m">
                                <h3 className="metaverse_font text-white mb-2">Emerging Metaverse</h3>
                                <p className="font-18 metaverse_p mb-3 d-lg-block d-none w--50 font-100">Inspired from Web3.0 app-based market and technologies</p>
                                <img src="assets/image/metaverse_evolution/emerging_metaverse.svg" alt="Emerging Metaverse" className="d-md-block d-none" />
                                <img src="assets/image/metaverse_evolution/emerging_metaverse_m.svg" alt="Emerging Metaverse" className="d-md-none d-block" />
                            </div>
                            <div className="col-2">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container d-lg-none d-block">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center">
                                <img src="assets/image/metaverse_evolution/metaverse_point.svg" alt="img" />
                                <h3 className="ms-2 font-22 text-white">Emerging Metaverse</h3>
                            </div>
                            <p className="font-18 font-100 metaverse_p mt-2 mb-3 ms-md-3 w--50">Inspired from Web3.0 app-based market and technologies</p>
                        </div>
                        <div className="col-12">
                            <div className="d-flex align-items-center">
                                <img src="assets/image/metaverse_evolution/metaverse_point.svg" alt="img" />
                                <h3 className="ms-2 font-22 text-white">Advance Metaverse</h3>
                            </div>
                            <p className="font-18 font-100 metaverse_p mt-2 mb-3 ms-md-3 w--50">Progression on emerging metaverse in a combinative approach</p>
                        </div>
                        <div className="col-12">
                            <div className="d-flex align-items-center">
                                <img src="assets/image/metaverse_evolution/metaverse_point.svg" alt="img" />
                                <h3 className="ms-2 font-22 text-white">Mature Metaverse</h3>
                            </div>
                            <p className="font-18 font-100 metaverse_p mt-2 mb-3 ms-md-3">Maturing toward interoperable, immersive experiences in new virtual economies</p>
                        </div>
                    </div>
                </div>
            </section>

            <Community />

            {/*Footer Start*/}
            <Footer />
            {/*Footer End*/}
        </>
    );
}