import React from "react";
import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

function AmbassadorProgram() {
    return (
        <>
            <div>
                <Helmet>
                    <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/ambassador_twitter.png" />

                    <title>Metaverse Ambassador Program| Blockchain Land</title>
                    <meta name="title" content="Metaverse Ambassador Program| Blockchain Land" />
                    <meta name="description" content="Blockchainland is presenting a unique concept of a mixed reality world to enable influencers to create truly interactive and immersive experiences for their fans." />
                    <meta name="keywords" content="Metaverse ambassador, Metaverse Brand ambassador, Blockchain Land Ambassador, Brand ambassador" />
                    <link rel="canonical" href="https://blockchain.land/ambassadorprogram" title="ambassadorprogram" />

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://blockchain.land/ambassadorprogram" />
                    <meta property="og:title" content="Metaverse Ambassador Program| Blockchain Land" />
                    <meta property="og:description" content="Blockchainland is presenting a unique concept of a mixed reality world to enable influencers to create truly interactive and immersive experiences for their fans." />
                    <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/ambassador_fb.png" />
                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://blockchain.land/ambassadorprogram" />
                    <meta property="twitter:title" content="Metaverse Ambassador Program| Blockchain Land" />
                    <meta property="twitter:description" content="Blockchainland is presenting a unique concept of a mixed reality world to enable influencers to create truly interactive and immersive experiences for their fans." />

                    <meta name="reply-to" content="blockchain.land" />
                    <meta name="robots" content="index, Follow" />
                    <meta name="googlebot" content="index, follow" />
                    <meta name="revisit-after" content="7 day" />
                    <meta name="rating" content="GENERAL" />
                    <meta name="language" content="English" />
                    <meta name="distribution" content="Global" />
                    <meta name="copyright" content="blockchain.land" />
                    <link rel="publisher" href="https://blockchain.land/" />
                    <link rel="author" href="https://blockchain.land/" />
                </Helmet>

                {/* Header Start */}
                <Header />
                {/* Header End */}

                {/* section metalife start */}
                <section className="section-airdrop mt--100 pt--100 garid_line">
                    <div className="container">
                        <div className="row align-items-center text-xl-start text-md-center">
                            <div className="col-xl-6">
                                <div>
                                    <h1 className="ambassador_h1 white-text font-200">
                                        Blockchain Land <span className="ambassador_bg ambassador_h1">Ambassador</span>
                                    </h1>
                                    <p className="font-18 font-100 white-text line_height mt-4 mb--40">
                                        Blockchainland is presenting a unique concept of a mixed reality world to enable influencers to create truly interactive and immersive experiences for their fans
                                    </p>
                                    <div className="d-flex align-items-center justify-content-md-center justify-content-xl-start justify-content-start">
                                        <a href="https://market.blockchain.land/ambassadorprogram" target={"_blank"} className="btn-head btn-contain font-16 text-center text-custom-dark d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/ambassadorprogram/ambassador_btn.png")' }}>Apply Now</a>
                                        <a href="https://market.blockchain.land/tutorials" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200 ms-3" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }}>Tutorial</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 tab_margin">
                                <div>
                                    <img src="assets/image/ambassadorprogram/ambassador.webp" alt="Metaverse Ambassador Program" type="image/webp" className="d-xl-block d-none" />
                                    <img src="assets/image/ambassadorprogram/ambassador_tab.webp" alt="Metaverse Ambassador Program" type="image/webp" className="d-xl-none d-md-block d-none mx-auto" />
                                    <img src="assets/image/ambassadorprogram/ambassador_m.webp" alt="Metaverse Ambassador Program" type="image/webp" className="d-md-none d-block mx-auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section metalife end */}

                {/* section meta estate start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 order-2 order-xl-1">
                                <div>
                                    <img src="assets/image/ambassadorprogram/ambassador1.webp" alt="Blockchain Land Meatverse Ambassador Program" type="image/webp" className="d-xl-block d-none" />
                                    <img src="assets/image/ambassadorprogram/ambassador1_tab.webp" alt="ambassador" type="image/webp" className="d-xl-none d-md-block d-none mx-auto" />
                                    <img src="assets/image/ambassadorprogram/ambassador1_m.webp" alt="ambassador" type="image/webp" className="d-md-none d-block mx-auto" />
                                </div>
                            </div>
                            <div className="col-xl-6 order-1 order-xl-2 text-xl-start text-md-center">
                                <h2 className="font-100 airdrop_h2 giveaway_h2 mb--40">Become an Ambassador with BlockchainLand</h2>
                                <p className="font-18 font-100 mb--20 meta_p line-25">
                                    The Blockchain Land Ambassador Program is a way for members of the community to promote the metaverse and create excitement among fans. Ambassadors can create content, collect collectibles, and engage with the community to drive interest in upcoming events, games, and product launches. In return, they will receive a personal portal and support from Blockchain Land, as well as rewards for their efforts. The overall goal of the Ambassador Program is to increase engagement and participation in the Blockchain Land metaverse.
                                </p>
                                <ul className="list_of">
                                    <li>Collaborate with brands to create fully immersive 3D experiences</li>
                                    <li>New monetization structure</li>
                                    <li>Community building and engagement</li>
                                    <li>Hosting virtual concerts and events</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section meta estate end */}

                {/* section Meta Vehicles start */}
                <section className="section-worth pt-0">
                    <div className="container">
                        <div className="row merch_bg position-relative">
                            <div className="col-xl-6 merch_padding text-xl-start text-md-center text-start">
                                <h3 className="ambassador_h3 text-white mb-3">Merch</h3>
                                <p className="font-100 ambassador_p ambassador_lineheight meta_p">After achieving a certain level, the brand ambassador will receive a Blockchain Land <span className="merchandize_color">merchandise kit.</span></p>
                            </div>
                            <div className="col-xl-6">
                                <div className="merch_jecket">
                                    <img src="assets/image/ambassadorprogram/jacket.webp" alt="jacket" type="image/webp" className="d-xl-block d-none" />
                                    <img src="assets/image/ambassadorprogram/jacket_tab.webp" alt="jacket" type="image/webp" className="d-xl-none d-md-block d-none mx-auto" />
                                    <img src="assets/image/ambassadorprogram/jacket_m.webp" alt="jacket" type="image/webp" className="d-md-none d-block mx-auto" />
                                </div>
                            </div>
                            <p className="btn-head btn-contain font-16 text-center text-custom-dark d-inline-block font-300 benefit" style={{ backgroundImage: 'url("assets/image/ambassadorprogram/benefit.png")' }}>Benefits</p>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-xl-6 metaverse_margin">
                                <div className="position-relative">
                                    <img src="assets/image/ambassadorprogram/metaverse.webp" alt="jacket" type="image/webp" className="d-xl-block d-none" />
                                    <img src="assets/image/ambassadorprogram/metaverse_tab.webp" alt="jacket" type="image/webp" className="d-xl-none d-md-block d-none mx-auto" />
                                    <img src="assets/image/ambassadorprogram/metaverse_m.webp" alt="jacket" type="image/webp" className="d-md-none d-block mx-auto" />
                                    <div className="metaverse_bg">
                                        <h3 className="ambassador_h3 text-white mb-3">Metaverse</h3>
                                        <p className="font-18 font-100 ambassador_lineheight meta_p">Different rewards are set at different levels in the Blockchain Land Ambassador program. After attaining a certain level the brand ambassador will be eligible to mint a valley and then a country too. All the minting costs will be bared by the Blockchain Land. The brand ambassador can create their metaverse. A parcel can be put in a sale or auction and you can design your map.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 revenue_margin">
                                <div className="position-relative">
                                    <img src="assets/image/ambassadorprogram/revenue_bg.webp" alt="jacket" type="image/webp" className="d-xl-block d-none" />
                                    <img src="assets/image/ambassadorprogram/revenue_bg_tab.webp" alt="jacket" type="image/webp" className="d-xl-none d-md-block d-none mx-auto" />
                                    <img src="assets/image/ambassadorprogram/revenue_bg_m.webp" alt="jacket" type="image/webp" className="d-md-none d-block mx-auto" />
                                    <div className="revenue_bg">
                                        <h3 className="ambassador_h3 text-white mb-3">Revenue Sharing</h3>
                                        <p className="font-18 font-100 ambassador_lineheight meta_p">Whichever trading will be done by brand ambassadors in Metaverse parcels of the country, will <span className="revenue_color">get a lifetime revenue sharing on trading fees.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center reward_margin">
                            <div className="col-12">
                                <div className="position-relative">
                                    <img src="assets/image/ambassadorprogram/reward.webp" alt="reward" type="image/webp" className="d-xl-block d-none" />
                                    <img src="assets/image/ambassadorprogram/reward_tab.webp" alt="reward" type="image/webp" className="d-xl-none d-md-block d-none mx-auto" />
                                    <img src="assets/image/ambassadorprogram/reward_m.webp" alt="reward" type="image/webp" className="d-md-none d-block mx-auto" />
                                    <div className="reward_bg">
                                        <h3 className="ambassador_h3 text-white mb-3">Reward</h3>
                                        <p className="font-18 font-100 ambassador_lineheight meta_p">A leaderboard will be created based on the ambassador's performance. The most dedicated ambassador will be given different kinds of rewards like expensive NFTs, NFT launchpad slots to present their own NFT, etc in recognition of their performance. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Meta Vehicles end */}

                {/* section Meta Land start */}
                <section className="section-worth">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <h2 className="airdrop_h2 text-white font-100 mb--40">How to become <br className="d-lg-block d-none" />A brand ambassador?</h2>
                                <p className="font-18 font-100 mb--40 meta_p line-25">
                                    firstly, any person who has interested in the Blockchain Land Ambassador Program must apply for it. After approval from Blockchain Land, a person will receive a special ambassador portal that contains your unique ambassador URL. You will receive referral credit for all users registered from that URL, and all referral user lists will be displayed on the portal. Therefore, you must approve the maximum Community so that you can unlock the maximum rewards.
                                </p>
                                <div className="d-flex align-items-center">
                                    <a href="https://market.blockchain.land/ambassadorprogram" target={"_blank"} className="btn-head btn-contain font-16 text-center text-custom-dark d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/ambassadorprogram/ambassador_btn.png")' }}>Apply Now</a>
                                    <a href="https://market.blockchain.land/tutorials" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200 ms-3" style={{ backgroundImage: 'url("assets/image/meta_life/user_btn.png")' }}>Watch Tutorial</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Meta Land end */}

                <Community />

                {/*Footer Start*/}
                <Footer />
                {/*Footer End*/}
            </div >
        </>
    );
}

export default AmbassadorProgram;