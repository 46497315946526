import React from "react";
import { Helmet } from "react-helmet";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function Whatknow() {
    return (
        <>
            <Helmet>
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                <title>Metaverse Defination | Blockchain Land</title>
                <meta name="title" content="Metaverse Defination | Blockchain Land" />
                <meta name="description" content="Let's know more about Metaverse glossary with Blockchain Land." />
                <meta name="keywords" content="metaverse glossary" />
                <link rel="canonical" href="https://blockchain.land/whatknow" title="Metaverse Defination" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blockchain.land/whatknow" />
                <meta property="og:title" content="Metaverse Defination | Blockchain.land" />
                <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blockchain.land/whatknow" />
                <meta property="twitter:title" content="Metaverse Defination | Blockchain.land" />
                <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>

            <Header />

            {/* section brand start */}
            <section className="bg_brand brand_pt_425 brand_height brand_mt_100 know_bg mb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="text-center text-sm-start">
                                <h1 className="font-200 white-text cmb_16">
                                    What to know
                                </h1>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="know_line white-text font-400 font-18">
                                <div className="row">
                                    <div className="col-lg-4"><p>Interoperability</p></div>
                                    <div className="col-lg-8"><p>The ability for virtual experiences, possessions and identities to travel unchanged across platforms.</p></div>
                                </div>
                            </div>
                            <div className="know_line white-text font-400 font-18">
                                <div className="row">
                                    <div className="col-lg-4"><p>Persistence</p></div>
                                    <div className="col-lg-8"><p>A continuity of existence; the continuation of virtual life regardless of whether people are online or offline.</p></div>
                                </div>
                            </div>
                            <div className="know_line white-text font-400 font-18">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <p>Decentralization</p>
                                    </div>
                                    <div className="col-lg-8">
                                        <p>Distribution of ownership—the idea that the metaverse will not be regulated or run by a single corporation or individual.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="know_line white-text font-400 font-18">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <p>NFT</p>
                                    </div>
                                    <div className="col-lg-8">
                                        <p>Non-fungible tokens (NFTs) are digital certificates of authenticity that will form the basis of digital ownership.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="know_line white-text font-400 font-18">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <p>Digital twins</p>
                                    </div>
                                    <div className="col-lg-8">
                                        <p>Physical spaces that have been cloned in the virtual world to promote familiarity and efficiency.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="know_line white-text font-400 font-18">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <p>Liminal spaces</p>
                                    </div>
                                    <div className="col-lg-8">
                                        <p>A new generation of gathering spaces and event locales that incorporate both physical and virtual elements.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="know_line white-text font-400 font-18">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <p>Extended reality</p>
                                    </div>
                                    <div className="col-lg-8">
                                        <p>An umbrella term encompassing augmented, virtual and mixed realities.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="know_line white-text font-400 font-18">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <p>Web3</p>
                                    </div>
                                    <div className="col-lg-8">
                                        <p>A decentralized, blockchain-based internet that will enable a seamless fusion of virtual and physical life.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="know_line white-text font-400 font-18">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <p>DAOs</p>
                                    </div>
                                    <div className="col-lg-8">
                                        <p>Decentralized autonomous organizations (DAOs) are community-led digital organizations that run on blockchain technology.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="know_line white-text font-400 font-18">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <p>Virtual teleportation</p>
                                    </div>
                                    <div className="col-lg-8">
                                        <p>A new technologically enabled form of travel or collaboration that uses multi-sensory and photorealistic renderings to make you feel as if you’re in the same room as someone who could be halfway around the (physical) world.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section brand end */}

            <div className="communityspace">
                {/* section community start */}
                <Community />
                {/* section community end */}
            </div>

            <Footer />
        </>
    );
}