import React from "react";
import { Helmet } from "react-helmet";
import Header from "./component/Header";

function NotFound() {
    return (
        <>
            <Helmet>
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                <title>Not Found - Blockchain Land</title>
                <link rel="canonical" href="https://blockchain.land" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blockchain.land" />
                <meta property="og:title" content="Not Found | Blockchain.land" />
                <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blockchain.land" />
                <meta property="twitter:title" content="Not Found | Blockchain.land" />
                <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>

            <Header />

            <section className="bg_notfound brand_mt_100 brand_pt_425">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-lg-7 text-end">
                            <h1 className="notfound_h1 mb-3">404</h1>
                            <h2 className="font---30 font-200 text-white mb-3">Oops! Page not found</h2>
                            <p className="font-100 text-white mb-3 text-end">The page you are looking for does not exist or an other error occurred.</p>
                            <a href="/" className="btn-head btn-contain font-16 text-center text-dark d-inline-block font-200 header_btn w-auto px-2" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Return to Homepage</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NotFound;