import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function JoinCommunity() {
    return (
        <>
            <Helmet>
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                <title>Join MultiChain Metaverse community | Blockchain Land</title>
                <meta name="title" content="Join MultiChain Metaverse community | Blockchain Land" />
                <meta name="description" content="In metaverse, imagine something good with endless possibilities of virtual reality and Link with the future to Build an Innovative legacy by joining us" />
                <meta name="keywords" content="Virtual community, Metaverse community" />
                <link rel="canonical" href="https://blockchain.land/joincommunity" title="joincommunity" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blockchain.land/joincommunity" />
                <meta property="og:title" content="Join our Metaverse community | Blockchain.land" />
                <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blockchain.land/joincommunity" />
                <meta property="twitter:title" content="Join our Metaverse community | Blockchain.land" />
                <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>
            {/* Header Start */}
            <Header />
            {/* Header End */}

            <div>
                {/* section brand start */}
                <section className="bg_brand brand_pt_425 brand_height brand_mt_100 jionspace">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="text-center text-sm-start">
                                    <h1 className="font-200 white-text cmb_16">
                                        Use our brand  <br />
                                        with care
                                    </h1>
                                    <p className="font-18 font-100 white-text">
                                        Everyone's joining the metaverse. However, the genuine business opportunity is building on your own. You'll find us everywhere in the guidelines given on this page and through our social channels.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section brand end */}
                {/* sectuin brand bottom start */}
                <section className="section_brand_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <a href="/assets/ppt/BlockchainLandLogo.zip" download>
                                    <div className="brand_bottom_logo position-relative main_version">
                                        <img className="brand_logo d-none d-sm-block" src="/assets/image/community/brand-bottom/brand-logo.svg" alt="Blockchain Land logo files and symbols" />
                                        <img className="brand_logo d-block d-sm-none" src="/assets/image/community/brand-bottom/brand-logo-mobile.svg" alt="brand-logo" />
                                        {/* <a href="javascript:void(0);" className="brand_logo_one">
                          <img src="/assets/image/community/brand-bottom/brand_logo_01.svg" alt="brand_logo_01" title='logo'>
                      </a> */}
                                        <a href="/assets/ppt/BlockchainLandLogo.zip" className="version_box" download>
                                            <img src="/assets/image/community/brand-bottom/brand_logo_01.svg" alt="onepager_01" />
                                        </a>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                                <div className="d-flex align-items-md-center align-items-start justify-content-between flex-column flex-md-row brand_bottom_right bb_top bb_bottom position-relative">
                                    <div className="d-flex align-items-center">
                                        <div className="community_icon">
                                            <img src="/assets/image/community/brand-bottom/business_deck.svg" alt="business_deck" />
                                        </div>
                                        <div className="cml_22">
                                            <p className="font-18 font-300 white-text">Business Deck</p>
                                            <span className="font-14 font-300 brand_light_30">Version 1.0, last up. 12/Jan/2022</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <a href="/assets/ppt/BusinessDeck.pdf" target="_blank" className="mt-3 mt-md-0">
                                            <span className="font-18 font-300 white-text deck_download cmr_10">Download</span>
                                            <img src="/assets/image/community/brand-bottom/deck_right_arrow.svg" alt="deck_right_arrow" />
                                        </a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-md-center align-items-start justify-content-between flex-column flex-md-row brand_bottom_right bb_bottom position-relative">
                                    <div className="d-flex align-items-center">
                                        <div className="community_icon">
                                            <img src="/assets/image/community/brand-bottom/valley_deck.svg" alt="business_deck" />
                                        </div>
                                        <div className="cml_22">
                                            <p className="font-18 font-300 white-text">Blockchain Valley Deck</p>
                                            <span className="font-14 font-300 brand_light_30">Version 2.0, last up. 03/Apr/2022</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <a href="/assets/ppt/BlockchainValleyDeck.pdf" target="_blank" className="mt-3 mt-md-0">
                                            <span className="font-18 font-300 white-text deck_download cmr_10">Download</span>
                                            <img src="/assets/image/community/brand-bottom/deck_right_arrow.svg" alt="deck_right_arrow" />
                                        </a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-md-center align-items-start justify-content-between flex-column flex-md-row brand_bottom_right bb_bottom position-relative">
                                    <div className="d-flex align-items-center">
                                        <div className="community_icon">
                                            <img src="/assets/image/community/brand-bottom/audit_report.svg" alt="business_deck" />
                                        </div>
                                        <div className="cml_22">
                                            <p className="font-18 font-300 white-text d-flex align-items-center">Audit Report <img className="cml_10" src="/assets/image/community/brand-bottom/audit_star.svg" alt="audit_star" /></p>
                                            <span className="font-14 font-300 brand_light_30">Smart contract Audit by <a href="https://hacksafe.io/blockchain-land/" target="_blank" className="text-blue text-decoration-underline">Hacksafe</a> </span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <a href="/assets/ppt/AuditReport(BlockchainLand).pdf" target="_blank" className="mt-3 mt-md-0">
                                            <span className="font-18 font-300 white-text deck_download cmr_10">Download</span>
                                            <img src="/assets/image/community/brand-bottom/deck_right_arrow.svg" alt="deck_right_arrow" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* onepager and whitepaper section start */}
                        <div className="row cmt_60">
                            <div className="col-lg-6 col-12">
                                <div className="main_version position-relative">
                                    <img className="d-none d-sm-block" src="/assets/image/community/brand-bottom/bg-onepager.svg" alt="bg-onepager" />
                                    <img className="d-block d-sm-none" src="/assets/image/community/brand-bottom/bg-onepager-mobile.svg" alt="bg-onepager" />

                                    {/* <div className="d-flex align-items-center justify-content-between version_content w-80"> */}
                                    {/* <p className=" font-14 font-300 text-custom-dark">Version 1.0, last up. 12/Jan/2022</p> */}
                                    <p className="version_content font-14 font-300 text-custom-dark">Coming Soon!</p>
                                    {/* </div> */}
                                    <a href="" className="version_box">
                                        <img src="/assets/image/community/brand-bottom/onepager_01.svg" alt="onepager_01" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 mt-lg-0 mt-5">
                                <div className="main_version position-relative">
                                    <img className="d-none d-sm-block" src="/assets/image/community/brand-bottom/bg-whitepaper.svg" alt="bg-whitepaper" />
                                    <img className="d-block d-sm-none" src="/assets/image/community/brand-bottom/bg-whitepaper-mobile.svg" alt="bg-whitepaper" />
                                    <p className="version_content font-14 font-300 text-custom-dark">Whitepaper</p>
                                    <a href="" className="version_box">
                                        <img src="/assets/image/community/brand-bottom/whitepaper_01.svg" alt="whitepaper_01" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 mt-5">
                                <div className="main_version position-relative">
                                    <img className="d-none d-sm-block" src="/assets/image/community/brand-bottom/legal_opinion.png" alt="bg-whitepaper" />
                                    <img className="d-block d-sm-none" src="/assets/image/community/brand-bottom/legal_opinion_mobile.png" alt="bg-whitepaper" />
                                    <p className="version_content font-14 font-300 text-custom-dark">Legal Opinion</p>
                                    <a href="/assets/ppt/LegalOpinionBCLToken.pdf" target={'_blank'} className="version_box">
                                        <img src="/assets/image/community/brand-bottom/brand_logo_01.svg" alt="Legal Opinion" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* sectuin brand bottom end */}
                {/* section social media start */}
                <section>
                    <div className="container">
                        <h2 className="font--70 font-200 white-text cmb_60 text-center text-sm-start">Social Media</h2>
                        <div className="row">
                            <div className="col-xl-8 col-12">
                                <div className="flex-wrap d-flex align-items-center justify-content-md-between justify-content-center social_margin">
                                    <a href="https://blockchainland.medium.com/" target="_blank">
                                        <img src="/assets/image/social/bg-medium.svg" alt="medium" className="social_media_width" />
                                    </a>
                                    <a href="https://twitter.com/land_blockchain" target="_blank">
                                        <img src="/assets/image/social/bg-twitter.svg" alt="twitter" className="social_media_width" />
                                    </a>
                                    <a href="https://t.me/BlockchainLandOfficial" target="_blank">
                                        <img src="/assets/image/social/bg-telegram.svg" alt="telegram" className="social_media_width" />
                                    </a>
                                    <a href="https://www.instagram.com/theblockchainland/" target="_blank">
                                        <img src="/assets/image/social/bg-instagram.svg" alt="instagram" className="social_media_width" />
                                    </a>
                                    <a href="https://discord.com/invite/8hZVg9ACNz" target="_blank">
                                        <img src="/assets/image/social/bg-discord.svg" alt="discord" className="social_media_width" />
                                    </a>
                                    <a href="https://www.facebook.com/Blockchain-Land-103061128803396" target="_blank">
                                        <img src="/assets/image/social/bg-fb.svg" alt="facebook" className="social_media_width" />
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCyM4Z0ZDNpcB7Cjr14Q0G3A" target="_blank">
                                        <img src="/assets/image/social/bg-youtube.svg" alt="youtube" className="social_media_width" />
                                    </a>
                                    <a href="https://www.linkedin.com/company/blockchain-land/" target="_blank">
                                        <img src="/assets/image/social/bg-linkedin.svg" alt="linkedin" className="social_media_width" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section social media end */}
            </div>

            {/*Footer Start*/}
            < Footer />
            {/*Footer End*/}
        </>
    );
}