import React from "react";
import { Helmet } from "react-helmet";
import Footer from "./component/Footer";
import Header from "./component/Header";

function Mint() {
    return (
        <>
            <div>
                <Helmet>
                    <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                    <title>Mint a Parcel in Metaverse | Blockchain Land</title>
                    <meta name="title" content="Mint a Parcel in Metaverse | Blockchain Land" />
                    <meta name="description" content="People who mint the country or valley can sell their parcel or put it in the auction or sale. Users can mint or buy a parcel from the auction,owner, or metaverse marketplace." />
                    <meta name="keywords" content="Mint parcel, minting valley, minting country" />
                    <link rel="canonical" href="https://blockchain.land/mint" title="mint" />

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://blockchain.land/mint" />
                    <meta property="og:title" content="Mint a Parcel in Metaverse | Blockchain.land" />
                    <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                    <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://blockchain.land/mint" />
                    <meta property="twitter:title" content="Mint a Parcel in Metaverse | Blockchain.land" />
                    <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                    <meta name="reply-to" content="blockchain.land" />
                    <meta name="robots" content="index, Follow" />
                    <meta name="googlebot" content="index, follow" />
                    <meta name="revisit-after" content="7 day" />
                    <meta name="rating" content="GENERAL" />
                    <meta name="language" content="English" />
                    <meta name="distribution" content="Global" />
                    <meta name="copyright" content="blockchain.land" />
                    <link rel="publisher" href="https://blockchain.land/" />
                    <link rel="author" href="https://blockchain.land/" />
                </Helmet>

                {/* Header Start */}
                <Header />
                {/* Header End */}
                <section className="section-citizenship section-mintparcel">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-8 col-xl-6">
                                <div className="text-center text-lg-start">
                                    <h1 className="font--40 font-400 white-text line--47 mb---20 mt-5 mt-md-0">
                                        Mint parcel
                                    </h1>
                                    <p className="font--18 white--light--80 font-100">
                                        Users can buy land here but first, they have to mint a parcel for it to get ownership. In metaverse marketplace, 1 Valley = 100 Parcel (10*10), 1 Country = 625 Parcel (25*25), and 1 Planet = 10,00,000 (1000*1000). There are no limits on purchasing and minting of valley and country.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--55 border--bottom pb---55 justify-content-center">
                            <div className="col-lg-4 col-md-6 mt-md-0 mt-5">
                                <img src="/assets/image/mint-parcel/mint-parcel-01.png" alt="Meatverse Valley in Blockchain Land" className="w-100" />
                                <div className="d-flex justify-content-between align-items-center mt---40">
                                    <div>
                                        <h2 className="font-20 font-400 white-text mb-0">Valley</h2>
                                        <span className="font-18 font-200 white--light--80">Parcel : 10 X 10</span>
                                    </div>
                                    <a href="https://market.blockchain.land/mint" target="_blank" className="btn-mint-head btn-contain text-center text-custom-dark d-block d-sm-inline-block font-16 font-400" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Mint Now</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-md-0 mt-5">
                                <img src="/assets/image/mint-parcel/mint-parcel-02.png" alt="Meatverse Country in Blockchain Land" className="w-100" />
                                <div className="d-flex justify-content-between align-items-center mt---40">
                                    <div>
                                        <h2 className="font-20 font-400 white-text mb-0">Country</h2>
                                        <span className="font-18 font-200 white--light--80">Parcel : 25 X 25</span>
                                    </div>
                                    <a href="https://market.blockchain.land/mint" target="_blank" className="btn-mint-head btn-contain text-center text-custom-dark d-block d-sm-inline-block font-16 font-400" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Mint Now</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-lg-0 mt-5">
                                <img src="/assets/image/mint-parcel/mint-parcel-03.png" alt="Meatverse Planet in Blockchain Land" className="w-100" />
                                <div className="d-flex justify-content-between align-items-center mt---40">
                                    <div>
                                        <h2 className="font-20 font-400 white-text mb-0">Planet</h2>
                                        <span className="font-18 font-200 white--light--80">Parcel : 1000 X 1000</span>
                                    </div>
                                    <a href="https://market.blockchain.land/planets" target="_blank" className="btn-mint-head btn-contain text-center text-custom-dark d-block d-sm-inline-block font-16 font-400" style={{ backgroundImage: 'url("assets/image/btn-bg/btn-blue.svg")' }}>Learn More</a>
                                </div>
                            </div>
                        </div>
                        {/* <p className="text-blue font--18 font-300 mt---55">Note : <span className="white-text">But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.</span></p> */}
                    </div>
                </section>
                {/*Footer Start*/}
                <Footer />
                {/*Footer End*/}
            </div>

            {/* <div className="modal fade model-mintparcel" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog my-0">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="model-body-content">
                                <h2 className="font---30 font-400 text-center white-text mb---35">Select Blockchain</h2>
                                <div className="d-flex flex-column flex-sm-row justify-content-center">
                                    <div className="model-chain position-relative mr---32">
                                        <img src="assets/image/select-blockchain/selec-chain-01.png" alt="selec-chain-01" />
                                        <p className="font---20 font-400 white-text">Ethereum</p>
                                    </div>
                                    <div className="model-chain position-relative">
                                        <img src="assets/image/select-blockchain/selec-chain-02.png" alt="selec-chain-02" />
                                        <p className="font---20 font-400 white-text">Binance</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-sm-row justify-content-center mt---33">
                                    <div className="model-chain position-relative mr---32">
                                        <img src="assets/image/select-blockchain/selec-chain-03.png" alt="selec-chain-03" />
                                        <p className="font---20 font-400 white-text">Lycan</p>
                                    </div>
                                    <div className="model-chain position-relative">
                                        <img src="assets/image/select-blockchain/selec-chain-04.png" alt="selec-chain-04" />
                                        <p className="font---20 font-400 white-text">Fantom</p>
                                    </div>
                                </div>
                                <a href="#" className="text-light-blue font---20 font-400 d-block text-center mt---35 btn-cancel" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade model-mintparcel" id="exampleModaltwo" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog my-0">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="model-body-content">
                                <h2 className="font---30 font-400 text-center white-text mb---35">Select Blockchain</h2>
                                <div className="d-flex flex-column flex-sm-row justify-content-center">
                                    <div className="model-chain-two position-relative mr---32">
                                        <img src="assets/image/select-blockchain/select-metamask.png" alt="select-metamask" />
                                        <p className="font---20 font-400 white-text">Metamask</p>
                                    </div>
                                    <div className="model-chain-two position-relative">
                                        <img src="assets/image/select-blockchain/select-wallet.png" alt="select-wallet" />
                                        <p className="font---20 font-400 white-text">Wallet Connect</p>
                                    </div>
                                </div>
                                <a href="#" className="text-light-blue font---20 font-400 d-block text-center mt---35 btn-cancel" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Mint;