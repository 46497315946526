import { Helmet } from "react-helmet";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function Campaign() {
    return (
        <>
            <Helmet>
                <title>Move Your Business On Metaverse | Blockchain Land</title>
                <meta name="title" content="Move Your Business On Metaverse | Blockchain Land" />
                <meta name="description"
                    content="Blockchain Land marketplace offers exclusive variety to the users to buy and sell land, estate, avatar wearables, NFTs and names too In the Metaverse." />
                <meta name="keywords" content="Initial Metaverse Offering, Metaverse, IMO, Launchpad, Launch Your Metaverse" />
                <link rel="canonical" href="https://blockchain.land/campaign" title="Campaign" />

                <meta name="reply-to" content="blockchain.land" />
                <meta name="robots" content="index, Follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="revisit-after" content="7 day" />
                <meta name="rating" content="GENERAL" />
                <meta name="language" content="English" />
                <meta name="distribution" content="Global" />
                <meta name="copyright" content="blockchain.land" />
                <link rel="publisher" href="https://blockchain.land/" />
                <link rel="author" href="https://blockchain.land/" />
            </Helmet>

            <Header />

            {/* Google Ad Targeting Enterprise Start */}
            <section className="camping_bg shadow-blue">
                <div className="container-fluid">
                    <div className="row justify-content-center h-100">
                        <div className="col-xl-12">
                            <div className="camping_titale">
                                <h1 className="campaign_title white-text font-400 text-uppercase">Move your business on <span className="ambassador_bg campaign_title">Metaverse</span></h1>
                                <p className="font-18 font-100 meta_land_p">Blockchain Land is the one-stop solution for all businesses trying to grow their existing businesses or set up their new ventures in the Virtual space.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="camping_city">
                                <img src="assets/image/camping/city.webp" alt="city" className="d-md-block d-none" type="image/webp" />
                                <img src="assets/image/camping/city_m.png" alt="city" className="d-md-none d-block" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {  /* Google Ad Targeting Enterprise end */}

            {/* launch section start */}
            <section className="launch_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-12">
                            <div className="card_bottom_gra h-100">
                                <img src="assets/image/camping/arrow.png" alt="arrow" className="launch_arrow" />
                                <div className="meta_exist_heading pb-0 text-start">
                                    <h2 className="pb-4">Launch your initial <br />metaverse offering (IMO)</h2>
                                    <p>The Initial Metaverse Offering creates a launchpad for raising funds for your metaverse project.</p>
                                    <h3 className="launch_h3 mb-3 mt-5">Benefits</h3>
                                    <p className="mb-4"><img src="assets/image/camping/launch_point.png" alt="launch_point" className="me-2" />Zero fee to launch your IMO.</p>
                                    <p className="mb-4"><img src="assets/image/camping/launch_point.png" alt="launch_point" className="me-2" />Initial land offering with your NFTs and Tokens</p>
                                    <p className="mb-4"><img src="assets/image/camping/launch_point.png" alt="launch_point" className="me-2" />Mint your valley or country after successful fundraising</p>
                                    <a href="https://market.blockchain.land/metaverseoffering" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta-land/meta-user.png")' }}>Know More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 mt-xl-0 mt-4">
                            <div className="card_bottom_gra ms-auto card_bottom_blue h-100">
                                <img src="assets/image/camping/arrow.png" alt="arrow" className="launch_arrow" />
                                <div className="meta_exist_heading pb-0 text-start">
                                    <h2 className="pb-4">Launch your <br />metaverse now</h2>
                                    <p className="mb-4">Mint your valley or country to launch your metaverse immediately for fraction of the cost.</p>
                                    <h3 className="launch_h3 mb-3 mt-5">Benefits</h3>
                                    <p className="mb-4"><img src="assets/image/camping/launch_point.png" alt="launch_point" className="me-2" />Launch in 7 mins</p>
                                    <p className="mb-4"><img src="assets/image/camping/launch_point.png" alt="launch_point" className="me-2" />Packages starting at USD 3000</p>
                                    <p className="mb-4"><img src="assets/image/camping/launch_point.png" alt="launch_point" className="me-2" />Only 3% platform fee</p>
                                    <a href="https://market.blockchain.land/c-mint" target={"_blank"} className="btn-head btn-contain font-16 text-center white-text d-inline-block font-200" style={{ backgroundImage: 'url("assets/image/meta-land/meta-user.png")' }}>Know More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* launch section end */}

            {/* country valley section start */}
            <section className="ask_padding">
                <div className="container">
                    <div className="country_section">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-12 pe-0">
                                        <div className="launch_country mb-lg-0 mb-3">
                                            <img src="assets/image/meta-land/meta-country.png" alt="meta" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 ps-lg-0 ps-3">
                                        <span className="country_font font-300 white-text text-uppercase">Country</span>
                                        <p className="mt-3">A country consist of 25*25 land parcels, with each land parcel measuring 50*50 meters</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-4">
                                <div className="row">
                                    <div className="col-lg-6 col-12 pe-0">
                                        <div className="launch_country mb-lg-0 mb-3">
                                            <img src="assets/image/meta-land/meta-valley.png" alt="meta" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 ps-lg-0 ps-3">
                                        <span className="country_font font-300 white-text text-uppercase">Valley</span>
                                        <p className="mt-3">A valley consist of 10*10 land parcels, with each land parcel measuring 50*50 meters</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* country valley section end */}

            {/* just ask section start */}
            <section className="ask_padding">
                <div className="container">
                    <div className="just_ask">
                        <div className="row">
                            <div className="col-lg-7 col-12 meta_exist_heading pb-0 text-start">
                                <h2>If you have questions, <br /><span className="ask_h2 text-uppercase">Just Ask It.</span></h2>
                                <p>We're just one click away to help you to build and experience your own whole new universe.</p>
                                <div className="d-flex align-item-center mt-4">
                                    <a href="https://t.me/BlockchainLandOfficial" target={"_blank"} className="tele_btn"><img src="assets/image/camping/tele_btn.svg" alt="tele_btn" /></a>
                                    <a href="https://discord.com/invite/8hZVg9ACNz" target={"_blank"} className="ms-3 tele_btn"><img src="assets/image/camping/discord_btn.svg" alt="discord_btn" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* just ask section end */}

            <Footer />
        </>
    );
}