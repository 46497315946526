import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

function Team() {
    return (
        <>
            <div>
                <Helmet>
                    <meta property="twitter:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/twitter_meta.png" />

                    <title>Team behind the Multichain Metaverse concept | Blockchain Land</title>
                    <meta name="title" content="Team behind the Multichain Metaverse concept | Blockchain Land" />
                    {/* <meta name="description" content="people in our metaverse community are eligible to claim exclusive NFTs and join our community. Airdrop winners will be rewarded with unique NFT cubes." /> */}
                    <meta name="keywords" content="Team behind the Multichain Metaverse, Blockchain land Developer Team, Developer of Blockchain land" />
                    <link rel="canonical" href="https://blockchain.land/team" title="Team" />

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://blockchain.land/team" />
                    <meta property="og:title" content="Team | Blockchain.land" />
                    <meta property="og:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />
                    <meta property="og:image" itemProp="image" content="https://blockchain.land/assets/image/meta_img/facebook_meta.png" />
                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://blockchain.land/team" />
                    <meta property="twitter:title" content="Team | Blockchain.land" />
                    <meta property="twitter:description" content="Blockchain Land is giving a spacious metaverse to build your own parcel to enhance your virtual experience globally and across multiple blockchains." />

                    <meta name="reply-to" content="blockchain.land" />
                    <meta name="robots" content="index, Follow" />
                    <meta name="googlebot" content="index, follow" />
                    <meta name="revisit-after" content="7 day" />
                    <meta name="rating" content="GENERAL" />
                    <meta name="language" content="English" />
                    <meta name="distribution" content="Global" />
                    <meta name="copyright" content="blockchain.land" />
                    <link rel="publisher" href="https://blockchain.land/" />
                    <link rel="author" href="https://blockchain.land/" />
                </Helmet>

                {/* Header Start */}
                <Header />
                {/* Header End */}

                {/* section founder start */}
                <section className="team_bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <h1 className="white-text font-200 mb--33">
                                        We Made It Possible
                                    </h1>
                                    <p className="font-18 font-100 white-text mb--40">
                                        Come meet the folks behind the Multichain Metaverse concept.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12 team_main_bg">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-12">
                                        <div className="team_img_padding">
                                            <img src="/assets/image/team/founder.png" alt="Blockchain Land Metaverse Citizenship Card - Founder & CEO of Blockchain Land" className="founder_img mx-auto" />
                                            <img src="/assets/image/team/founder_hover.webp" alt="founder" className="founder_hover mx-auto" type="image/webp" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 pl_80 mt-4 mt-lg-0 text-center text-lg-start">
                                        <div className="mb-3">
                                            <h2 className="team_highlight founder_title">Founder & CEO</h2>
                                        </div>
                                        <span>"</span>
                                        <p className="font-16 font-100 white-text line-22 mb-lg-4 mb-3">
                                            Ganesh is the brains behind the entire tech lobby under Chainsense. With an aim to build a Web3 enabled world, Ganesh is not only the Founder of Chainsense but also of Werewolf Exchange, Blockchain Land and the Lycan Ecosystem!
                                        </p>
                                        <a href="https://www.linkedin.com/in/ganeshlore/" target={"_blank"} className="linkedin_img margin_m">
                                            <img src="/assets/image/team/team_linkedin.svg" alt="linkedin" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section founder end */}

                {/* section Chief Team start */}
                <section className="section-step">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--70 text-center text-lg-start">
                                <h2 className="airdrop_h2 giveaway_h2 mb-lg-4 mb-3">
                                    Chief Team
                                </h2>
                                <p className="font-18 font-100 white-text line-25 team_content">
                                    Our leadership team is guiding us toward a more globalized future in business and
                                    citizenship. By creating the first multichain metaverse in history, they are ushering in a new
                                    era with their experience and expertise gained from leading numerous successful projects in
                                    the web2 and web3 space.
                                </p>
                            </div>
                            <div className="col-lg-12 cheif_team_bg mb--50">
                                <div className="row">
                                    {/* <div className="col-lg-4 col-md-6 col-12 card_padding">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card1.webp" alt="Blockchain Land Metaverse Citizenship Card - Chief Marketing Officer at Blockchain Land" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team1.webp" alt="team" type="image/webp" />
                                            </div>
                                            <div className="team_id">
                                                <p>#000003</p>
                                            </div>
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Ariane Cardoso</h3>
                                                <p className="team_p">Join Date : September - 01 - 2022</p>
                                                <span className="team_span">0x0705....B0900</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Chief Marketing Officer</p>
                                                <a href="https://www.linkedin.com/in/arianercardoso/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-4 col-md-6 col-12 card_padding">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card2.webp" alt="Blockchain Land Metaverse Citizenship Card - Chief Operating Officer at Blockchain Land" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team2.webp" alt="team" type="image/webp" />
                                            </div>
                                            <div className="team_id">
                                                <p>#000004</p>
                                            </div>
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Nipoon Agarwal</h3>
                                                <p className="team_p">Join Date : September - 01 - 2022</p>
                                                <span className="team_span">0x4A7B....00008</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Chief Operating Officer</p>
                                                <a href="https://www.linkedin.com/in/nipoonagarwal/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 card_padding">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card3.webp" alt="Blockchain Land Metaverse Citizenship Card - Chief Metaverse Architecture at Blockchain Land" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team3.png" alt="team" />
                                            </div>
                                            <div className="team_id">
                                                <p>#000002</p>
                                            </div>
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Raj Ladumor</h3>
                                                <p className="team_p">Join Date : September - 01 - 2022</p>
                                                <span className="team_span">0x1006....39283</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Chief Metaverse Architecture</p>
                                                <a href="https://www.linkedin.com/in/rajladumor/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 card_padding">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card6.webp" alt="card" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team13.webp" alt="team" type="image/webp" />
                                            </div>
                                            <div className="team_id">
                                                <p>#000064</p>
                                            </div>
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Ashton Addison</h3>
                                                <p className="team_p">Join Date : September - 19 - 2022</p>
                                                <span className="team_span">0x9416....13Fd6</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Advisor</p>
                                                <a href="https://www.linkedin.com/in/ashtonaddison/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 card_padding d-lg-none d-block">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card4.webp" alt="Blockchain Land Metaverse Citizenship Card - Chief Technology Officer at Blockchain Land" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team4.webp" alt="team" type="image/webp" />
                                            </div>
                                            <div className="team_id">
                                                <p>#000005</p>
                                            </div>
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Sunny Sheladiya</h3>
                                                <p className="team_p">Join Date : September - 02 - 2022</p>
                                                <span className="team_span">0xBC05....63F70</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Chief Technology Officer</p>
                                                <a href="https://www.linkedin.com/in/sunny-sheladiya-7365624b/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 card_padding d-lg-none d-block">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card5.webp" alt="Blockchain Land Metaverse Citizenship Card - Chief Legal Officer at Blockchain Land" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team5.webp" alt="team" type="image/webp" />
                                            </div>
                                            {/* <div className="team_id">
                                                <p>#0000005</p>
                                            </div> */}
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Remus Consmin Cartoiu</h3>
                                                <p className="team_p">Join Date : September - 01 - 2022</p>
                                                <span className="team_span">0x1006....39283</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Chief Legal Officer</p>
                                                <a href="https://www.linkedin.com/in/remus-cosmin-carstoiu-gdps7715/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 card_padding d-lg-none d-block">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card8.webp" alt="Blockchain Land Metaverse Citizenship Card - Growth Advisor at Blockchain Land" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team15.webp" alt="team" type="image/webp" />
                                            </div>
                                            <div className="team_id">
                                                <p>#000583</p>
                                            </div>
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Tina J</h3>
                                                <p className="team_p">Join Date : February - 13 - 2023</p>
                                                <span className="team_span">0xa32A....98FF</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Growth Advisor</p>
                                                <a href="https://www.linkedin.com/in/tina-j-095084183/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 card_padding d-lg-none d-block">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card9.webp" alt="Blockchain Land Metaverse Citizenship Card - Growth Advisor at Blockchain Land" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team16.webp" alt="team" type="image/webp" />
                                            </div>
                                            <div className="team_id">
                                                <p>#000074</p>
                                            </div>
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Viktor Villand</h3>
                                                <p className="team_p">Join Date : September - 26 - 2022</p>
                                                <span className="team_span">0x33eF....f4a8</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Growth Advisor</p>
                                                <a href="https://www.linkedin.com/in/viktor-villand-061140139/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 cheif_team_bg mb--50 cheif_team_bg_m2 d-lg-block d-none">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-12 card_padding">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card4.webp" alt="card" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team4.webp" alt="team" type="image/webp" />
                                            </div>
                                            <div className="team_id">
                                                <p>#000005</p>
                                            </div>
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Sunny Sheladiya</h3>
                                                <p className="team_p">Join Date : September - 02 - 2022</p>
                                                <span className="team_span">0xBC05....63F70</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Chief Technology Officer</p>
                                                <a href="https://www.linkedin.com/in/sunny-sheladiya-7365624b/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 card_padding">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card5.webp" alt="card" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team5.webp" alt="team" type="image/webp" />
                                            </div>
                                            {/* <div className="team_id">
                                                <p>#0000005</p>
                                            </div> */}
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Remus Consmin Cartoiu</h3>
                                                <p className="team_p">Join Date : September - 01 - 2022</p>
                                                <span className="team_span">0x1006....39283</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Chief Legal Officer</p>
                                                <a href="https://www.linkedin.com/in/remus-cosmin-carstoiu-gdps7715/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 card_padding">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card8.webp" alt="card" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team15.webp" alt="team" type="image/webp" />
                                            </div>
                                            <div className="team_id">
                                                <p>#000583</p>
                                            </div>
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Tina J</h3>
                                                <p className="team_p">Join Date : February - 13 - 2023</p>
                                                <span className="team_span">0xa32A....98FF</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Growth Advisor</p>
                                                <a href="https://www.linkedin.com/in/tina-j-095084183/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 cheif_team_bg cheif_team_bg_m2 d-lg-block d-none">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-12 card_padding">
                                        <div className="position-relative">
                                            <img src="/assets/image/team/card.svg" alt="card" className="w-100 founder_img margin_top_m" />
                                            <img src="/assets/image/team/card9.webp" alt="card" className="w-100 founder_hover" type="image/webp" />
                                            <div className="team_img">
                                                <img src="/assets/image/team/team16.webp" alt="team" type="image/webp" />
                                            </div>
                                            <div className="team_id">
                                                <p>#000074</p>
                                            </div>
                                            <div className="team_detail">
                                                <h3 className="team_highlight">Viktor Villand</h3>
                                                <p className="team_p">Join Date : September - 26 - 2022</p>
                                                <span className="team_span">0x33eF....f4a8</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between team_name">
                                                <p className="team_profession">Growth Advisor</p>
                                                <a href="https://www.linkedin.com/in/viktor-villand-061140139/" target={"_blank"} className="linkedin_img">
                                                    <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Chief Team end */}

                {/* section Developer Team start */}
                <section className="section-step">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--70 text-center text-lg-start">
                                <h2 className="airdrop_h2 giveaway_h2 mb-lg-4 mb-3">
                                    Developer Team
                                </h2>
                                <p className="font-18 font-100 white-text line-25 team_content">
                                    Our dedicated development team laid the building blocks for the BlockchainLand universe
                                    and is striving diligently to realize our vision for the new digital frontier. Gaming, education,
                                    virtual offices, anything you can think of, this team will bring to life!
                                </p>
                            </div>
                            <div className="col-lg-12 developer_team_bg mb--50">
                                <div className="row">
                                    <div className="col-md-3 col-12 px-lg-2 px-0 team_mobile_bg">
                                        <div className="developer_team_img mb-lg-3 mb-2">
                                            <img src="/assets/image/team/team6.webp" alt="Blockchain Land Developer Team - Contract Architect at Blockchain Land" className="founder_img developer_margin_top" type="image/webp" />
                                            <img src="/assets/image/team/team6_hover.webp" alt="team" className="founder_hover developer_margin_top" type="image/webp" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between px-4 team_detail_m">
                                            <div>
                                                <h6 className="team_highlight mb-1">Prashant Malaviya</h6>
                                                <p className="developer_team_p">Contract Architect</p>
                                            </div>
                                            <a href="https://www.linkedin.com/in/prashantkumar-malaviya-6a4a17137/" target={"_blank"} className="linkedin_img">
                                                <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12 px-lg-2 px-0 team_mobile_bg mt-3 mt-md-0">
                                        <div className="developer_team_img mb-lg-3 mb-2">
                                            <img src="/assets/image/team/team7.webp" alt="Blockchain Land Developer Team - Creative Expert at Blockchain Land" className="founder_img developer_margin_top" type="image/webp" />
                                            <img src="/assets/image/team/team7_hover.webp" alt="team" className="founder_hover developer_margin_top" type="image/webp" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between px-4 team_detail_m">
                                            <div>
                                                <h6 className="team_highlight mb-1">Karan Kakadiya</h6>
                                                <p className="developer_team_p">Creative Expert</p>
                                            </div>
                                            <a href="https://www.linkedin.com/in/karan-kakadiya/" target={"_blank"} className="linkedin_img">
                                                <img src="/assets/image/team/team_linkedin.svg" alt="team linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12 px-lg-2 px-0 team_mobile_bg mt-3 mt-md-0">
                                        <div className="developer_team_img mb-lg-3 mb-2">
                                            <img src="/assets/image/team/team8.webp" alt="Blockchain Land Developer Team - Tech Engineer at Blockchain Land" className="founder_img developer_margin_top" type="image/webp" />
                                            <img src="/assets/image/team/team8_hover.webp" alt="team" className="founder_hover developer_margin_top" type="image/webp" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between px-4 team_detail_m">
                                            <div>
                                                <h6 className="team_highlight mb-1">Prince Patel</h6>
                                                <p className="developer_team_p">Tech Engineer</p>
                                            </div>
                                            <a href="https://www.linkedin.com/in/prince-patel-007/" target={"_blank"} className="linkedin_img">
                                                <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12 px-lg-2 px-0 team_mobile_bg mt-3 mt-md-0">
                                        <div className="developer_team_img mb-lg-3 mb-2">
                                            <img src="/assets/image/team/team9.webp" alt="Blockchain Land Developer Team - Tech Engineer at Blockchain Land" className="founder_img developer_margin_top" type="image/webp" />
                                            <img src="/assets/image/team/team9_hover.webp" alt="team" className="founder_hover developer_margin_top" type="image/webp" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between px-4 team_detail_m">
                                            <div>
                                                <h6 className="team_highlight mb-1">Nayan Ahir</h6>
                                                <p className="developer_team_p">Tech Engineer</p>
                                            </div>
                                            <a href="https://www.linkedin.com/in/nayan-ahir-4aaa07165/" target={"_blank"} className="linkedin_img">
                                                <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 developer_team_bg">
                                <div className="row">
                                    <div className="col-md-3 col-12 px-lg-2 px-0 team_mobile_bg">
                                        <div className="developer_team_img mb-lg-3 mb-2">
                                            <img src="/assets/image/team/team12.webp" alt="Blockchain Land Developer Team - Frontend Engineer at Blockchain Land" className="founder_img developer_margin_top" type="image/webp" />
                                            <img src="/assets/image/team/team12_hover.webp" alt="team" className="founder_hover developer_margin_top" type="image/webp" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between px-4 team_detail_m">
                                            <div>
                                                <h6 className="team_highlight mb-1">Mitul Kalsariya</h6>
                                                <p className="developer_team_p">Frontend Engineer</p>
                                            </div>
                                            <a href="https://www.linkedin.com/in/mitul-kalsariya-ab63b817b/" target={"_blank"} className="linkedin_img">
                                                <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12 px-lg-2 px-0 team_mobile_bg mt-3 mt-md-0">
                                        <div className="developer_team_img mb-lg-3 mb-2">
                                            <img src="/assets/image/team/team10.webp" alt="Blockchain Land Developer Team - Web 3.0 Developer at Blockchain Land" className="founder_img developer_margin_top" type="image/webp" />
                                            <img src="/assets/image/team/team10_hover.webp" alt="team" className="founder_hover developer_margin_top" type="image/webp" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between px-4 team_detail_m">
                                            <div>
                                                <h6 className="team_highlight mb-1">Mayur More</h6>
                                                <p className="developer_team_p">Web 3.0 Developer</p>
                                            </div>
                                            <a href="https://www.linkedin.com/in/mahesh-more-mayur-195025205/" target={"_blank"} className="linkedin_img">
                                                <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12 px-lg-2 px-0 team_mobile_bg mt-3 mt-md-0">
                                        <div className="developer_team_img mb-lg-3 mb-2">
                                            <img src="/assets/image/team/team11.webp" alt="Blockchain Land Developer Team - Chain integrator at Blockchain Land" className="founder_img developer_margin_top" type="image/webp" />
                                            <img src="/assets/image/team/team11.webp" alt="team" className="founder_hover" type developer_margin_top="image/webp" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between px-4 team_detail_m">
                                            <div>
                                                <h6 className="team_highlight mb-1">Riyaz Shaikh</h6>
                                                <p className="developer_team_p">Chain integrator</p>
                                            </div>
                                            <a href="https://www.linkedin.com/in/riyazuddin-shaikh-654846145/" target={"_blank"} className="linkedin_img">
                                                <img src="/assets/image/team/team_linkedin.svg" alt="team_linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section Developer Team end */}

                <Community />

                {/*Footer Start*/}
                <Footer />
                {/*Footer End*/}
            </div>
        </>
    );
}

export default Team;