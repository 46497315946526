import React from "react";
import Community from "./component/Community";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function BlogList() {

    return (
        <>
            <Header />

            {/* section blog start */}
            <section className="section-airdrop mt--100 pt--100">
                <div className="container">
                    <div className="row mb--70">
                        <div className="col-lg-7">
                            <h1 className="text-white">The Blogs</h1>
                            <p className="font-18 font-100 mb-0 color_body_text">Metaverse is an emerging technology and has the potential to impact everything from employee engagement to the customer experience, omnichannel sales and marketing, product innovation, and community building.</p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="card blog_card">
                                <img src="/assets/image/blog/ambassador_blog.png" alt="ambassador_blog" className="card-img-top" />
                                <div className="card-body px-0">
                                    <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                    <h2 className="font-25 font-300 text-white py-3">Blockchain Land’s Ambassador Program for Web3 Influencers is now live!</h2>
                                    <p className="font-14 font-100 color_body_text">We’re launching our Metaverse Ambassador Program and looking for Web3 focused individuals who are not only educating but at an...</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <img src="/assets/image/blog/citizenship_blog.png" alt="citizenship_blog" className="card-img-top" />
                                </div>
                                <div className="col-md-6 mt-md-0 mt-4">
                                    <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                    <h2 className="font-18 font-300 text-white mt-3">Global Digital Citizenship in the Blockchain Land Metaverse: Why Does it Matter?</h2>
                                </div>
                            </div>
                            <div className="row my-4">
                                <div className="col-md-6">
                                    <img src="/assets/image/blog/token_blog.png" alt="token_blog" className="card-img-top" />
                                </div>
                                <div className="col-md-6 mt-md-0 mt-4">
                                    <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                    <h2 className="font-18 font-300 text-white mt-3">How to buy Blockchain Land Token (BCL) using BNB?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src="/assets/image/blog/metaverse_blog.png" alt="metaverse_blog" className="card-img-top" />
                                </div>
                                <div className="col-md-6 mt-md-0 mt-4">
                                    <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                    <h2 className="font-18 font-300 text-white mt-3">Immersive Experiences in Social Media & Entertainment Industry with Metaverse</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section blog end */}

            {/* section highlight start */}
            <section className="section-global pt--100 mb-100 spacemobile">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h2 className="font-18 font-200 color_body_text text-uppercase">
                                Highlights
                            </h2>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-3 col-md-4 col-12">
                            <img src="assets/image/blog/burning_mechanism.png" alt="burning_mechanism" className="w-100" />
                            <div className="mt-3">
                                <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                <h3 className="font-18 font-300 text-white my-3">BCL Token Burning Mechanism</h3>
                                <p className="font-14 font-100 line-25 color_body_text">
                                    Blockchain Land has its own metaverse token known as Blockchain Land Token (BCL). Well, we are going to launch our very first planet very soon and how its burning mechanism process works. BCL Burning...
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-md-0 mt-5">
                            <img src="assets/image/blog/metaverse.png" alt="metaverse" className="w-100" />
                            <div className="mt-3">
                                <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                <h3 className="font-18 font-300 text-white my-3">Why does AI play a vital role in the Metaverse?</h3>
                                <p className="font-14 font-100 line-25 color_body_text">
                                    Artificial Intelligence (AI) applications are frequently used in general and at the same time Metaverse is “THE HOT TOPIC” In this Blockchain Technological era. Well users, who do not know, Artificial Intelligence...
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-md-0 mt-5">
                            <img src="assets/image/blog/business.png" alt="business" className="w-100" />
                            <div className="mt-3">
                                <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                <h3 className="font-18 font-300 text-white my-3">Scale Up your Business on Blockchain Land</h3>
                                <p className="font-14 font-100 line-25 color_body_text">
                                    The metaverse refers to online experiences that are highly immersive, such as virtual reality (VR), augmented reality (AR), and interactive video. It’s essentially taking the online experience to the next level,...
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-lg-0 mt-5">
                            <img src="assets/image/blog/roadmap.png" alt="roadmap" className="w-100" />
                            <div className="mt-3">
                                <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                <h3 className="font-18 font-300 text-white my-3">SoulBound Citizenship On Blockchain Land</h3>
                                <p className="font-14 font-100 line-25 color_body_text">
                                    Introducing Soulbound Tokens (SBTs) Soulbound tokens could be the next thing that’s in store. To gain some more insight into it, we should dive further into NFTs, which are non-transferable and are called...
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-5">
                            <img src="assets/image/blog/highlight.png" alt="highlight" className="w-100" />
                            <div className="mt-3">
                                <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                <h3 className="font-18 font-300 text-white my-3">Interoperability and the Future of the Metaverse</h3>
                                <p className="font-14 font-100 line-25 color_body_text">
                                    Basically, Interoperability refers to the basic ability of different computerized products or systems to readily connect and exchange information with one another, in either implementation or access,...
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-5">
                            <img src="assets/image/blog/highlight.png" alt="highlight" className="w-100" />
                            <div className="mt-3">
                                <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                <h3 className="font-18 font-300 text-white my-3">Immersive Experiences in Social Media & Entertainment Industry with Metaverse</h3>
                                <p className="font-14 font-100 line-25 color_body_text">
                                    As we all know, The metaverse can be depicted as a brought-together space that coordinates numerous virtual universes into a solitary,...
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-5">
                            <img src="assets/image/blog/highlight.png" alt="highlight" className="w-100" />
                            <div className="mt-3">
                                <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                <h3 className="font-18 font-300 text-white my-3">Metaverse: Shaped the infinite possibilities & opportunities for your Business</h3>
                                <p className="font-14 font-100 line-25 color_body_text">
                                    The world is well aware of the fact that the Metaverse is a vision of future digital worlds where people can gather to work, play, and socialize; it’s...
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-5">
                            <img src="assets/image/blog/highlight.png" alt="highlight" className="w-100" />
                            <div className="mt-3">
                                <span className="font-14 font-100 color_body_text">Nov - 9 - 2022</span>
                                <h3 className="font-18 font-300 text-white my-3">Steps To Your Own Virtual World</h3>
                                <p className="font-14 font-100 line-25 color_body_text">
                                    Blockchain Land is the first ever Multichain Metaverse Platform which allows your businesses to grow globally and across blockchains. Well, In this digital era people should be aware of its advantageous advancement....
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section highlight end */}

            <Community />
            <Footer />
        </>
    );
}